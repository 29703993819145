import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  InputBase,
  Paper,
  Stack,
  DialogContent,
  DialogActions,
  Chip,
  Typography,
} from "@mui/material";

import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import DataGridTable from "../../dataGrid";
import moment from "moment";

import SearchIcon from "@mui/icons-material/Search";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../../assets/icons/pencil.svg";

import DeleteIcon from "../../../../assets/icons/deleteRed.svg";

import SwapVertIcon from "@mui/icons-material/SwapVert";

import CustomizedDialog from "../../Dialog";
import { setSnackbar } from "../../../../redux/slices/common.slice";

import { deleteFormInstanceById } from "../../../../redux/slices/forms.slice";
import CreateMessages from "./createMessage";
import {
  deleteProjectMessage,
  getMessages,
} from "../../../../redux/slices/tenant.slice";
import FilterDrawer from "../../../layout/filterDrawer";
import Select from "react-select";

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};
const typeOptions = [
  {
    value: "All",
    label: "All",
  },
  { value: "on_demand", label: "On Demand Messages" },
  { value: "scheduled", label: "Schedule Messages" },
];
const statusOptions = [
  {
    value: "All",
    label: "All",
  },
  { value: true, label: "Completed" },
  { value: false, label: "Ongoing" },
];
export default function Messages() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Messages, setMessages] = useState([]);

  const [totalRow, setTotalRow] = useState(0);
  const { projectId } = useParams();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");
  const [loading, setLoading] = useState(false);

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [statusFilter, setStatusFilter] = useState({
    value: "All",
    label: "All",
  });
  const [typeFilter, setTypeFilter] = useState({
    value: "All",
    label: "All",
  });

  const getAllMessages = async () => {
    setLoading(true);

    let response = await dispatch(
      getMessages({
        params: {
          page_number: paginationModel.page + 1,
          page_size: paginationModel.pageSize,
          sort: sortKey,
          is_completed:
            statusFilter?.value === "All"
              ? undefined
              : statusFilter?.value
              ? "True"
              : "False",

          type: typeFilter?.value === "All" ? undefined : typeFilter?.value,
        },
        id: projectId,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setMessages(payload?.data);

      setTotalRow(payload?.total_object);
    }
    setLoading(false);
  };

  const deleteForm = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        deleteProjectMessage({ id: selectedFormId?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Message Deleted succesfully",
          })
        );
        getAllMessages();
        setOpenDeleteModal(false);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    getAllMessages();
  }, [paginationModel, sortKey, statusFilter, typeFilter]);

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                setDrawerState("view");
                setIsOpenDrawer(true);
                setSelectedFormId(params.row);
              }}
            >
              <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
            </IconButton>
          </Tooltip>
          {!params?.row?.is_completed && (
            <Tooltip title="Edit">
              <IconButton
                sx={{ color: "#000000", padding: "0px" }}
                onClick={() => {
                  setDrawerState("edit");
                  setIsOpenDrawer(true);
                  setSelectedFormId(params.row);
                }}
              >
                <img
                  alt="Edit Details"
                  src={Pencil}
                  width={24}
                  height={24}
                  className="actionIcon"
                  cursor={"pointer"}
                />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000", padding: "0" }}
              onClick={() => {
                setOpenDeleteModal(true);
                setSelectedFormId(params?.row);
              }}
            >
              <img
                alt="Delete Details"
                src={DeleteIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 200,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "title")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "type",
      headerName: "Message Type",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 200,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "type")}
        >
          Message Type
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: (params) => {
        let Type = "-";
        if (params.row?.type === "on_demand") {
          Type = "On Demand";
        } else if (params.row?.type === "scheduled") {
          Type = "Scheduled";
        }
        return <div>{Type}</div>;
      },
    },
    {
      field: "is_completed",
      headerName: "Status",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 200,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "is_completed")}
        >
          Status
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: ({ row }) => {
        return (
          <Chip
            label={row?.is_completed ? "COMPLETED" : "ONGOING"}
            style={{
              backgroundColor: row?.is_completed ? "green" : "orange", // Text color
              fontWeight: "bold",
              cursor: "pointer",
              color: "white",
            }}
          />
        );
      },
    },

    {
      field: "created_at",
      headerName: "Created At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      minWidth: 140, // Adjust minWidth as needed
      flex: 1,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      minWidth: 140, // Adjust minWidth as needed
      flex: 1,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      minWidth: 130,
      renderCell: ActionCell,
    },
  ];
  return (
    <>
      <div className="history">
        <Box
          className="content-header kb-list-header mb-4"
          sx={{ justifyContent: "flex-end !important" }}
        >
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>

            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => {
                setIsOpenDrawer(true);
                setDrawerState("create");
              }}
            >
              Create Message
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={() => setOpenFilterDrawer(true)}
              style={{ height: "44px" }}
            >
              Filters
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? Messages.filter((item) =>
                    item.title
                      ?.toLowerCase()
                      .includes(searchTerm?.toLowerCase())
                  )
                : Messages.slice(
                    paginationModel.page * paginationModel.pageSize,
                    (paginationModel.page + 1) * paginationModel.pageSize
                  )
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={loading}
            paginationModel={paginationModel}
            totalRow={totalRow}
            height="calc(100vh - 345px)"
          />
        </Box>
      </div>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete <b>{selectedFormId?.title} </b>?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={deleteForm}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      {isOpenDrawer && (
        <CreateMessages
          drawerState={drawerState}
          setIsOpenDrawer={setIsOpenDrawer}
          isOpenDrawer={isOpenDrawer}
          values={selectedFormId}
          fetchAlltasks={getAllMessages}
        />
      )}

      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box>
          <Typography variant="body1" component="label">
            Message Type
          </Typography>

          <Select
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={typeOptions}
            value={typeFilter}
            onChange={(e) => {
              setTypeFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Type..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Select>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Task Status
          </Typography>

          <Select
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={statusOptions}
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Status..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Select>
        </Box>
      </FilterDrawer>
    </>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "100px", textAlign: "center" }}>
      <h1>404</h1>
      <div>
        <h2>The page you requested was not found.</h2>
        <Button
          style={{ marginTop: "10px" }}
          variant="contained"
          color="info"
          onClick={() => navigate("/dashboard", { replace: true })}
        >
          go to home
        </Button>
      </div>
    </div>
  );
}

export default PageNotFound;

import React, { useEffect, useState } from "react";
import "../../styles/newladingpage.scss";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import HeroBanner from "../../assets/landing_page/Computer.png";
import Vetcor1 from "../../assets/landing_page/Vector-1.svg";
import KeySvg from "../../assets/landing_page/key.svg";
import ProcessSvg from "../../assets/landing_page/process.svg";
import RobotSvg from "../../assets/landing_page/robot.svg";
import ApplicationLayer from "../../assets/landing_page/applicationLayer.png";
import SelfServeSvg from "../../assets/landing_page/SelfServe.svg";
import RFPServe from "../../assets/landing_page/RFP.svg";
import EmpowerSaleSvg from "../../assets/landing_page/EmpowerSaleSvg.svg";
import ContentSvg from "../../assets/landing_page/ContentSvg.svg";
import OptimiseSvg from "../../assets/landing_page/OptimizeSvg.svg";
import AutomateIndustry from "../../assets/landing_page/AutomateIndustry.svg";
import ApplicationIndustries from "../../assets/landing_page/applicable-industries.png";
import InternalAndExternalSvg from "../../assets/landing_page/InternalAndExternal.svg";
import DocumentAnalysisSvg from "../../assets/landing_page/DocumentAnalysis.svg";
// import ApplicationIndustries from "../../assets/landing_page/ApplicationIndustries.svg";
import BookDemoIllstration from "../../assets/landing_page/bookDemoIllstration.png";
import CheckSvg from "../../assets/landing_page/check.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../layout/footer";
import RequestDemoForm from "./bookDemo";

const NewLandingPage = () => {
  const isMobile = useMediaQuery("(min-width: 401px) and (max-width: 600px)");
  const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 960px)");
  const isLaptop = useMediaQuery("(min-width: 961px) and (max-width: 1280px)");
  const isDesktop = useMediaQuery("(min-width: 1281px)");
  const [openBookDemo, setOpenBookDemo] = useState();

  const interactData = [
    { Icon: Vetcor1, Description: "Integrate with your tools and documents" },
    {
      Icon: KeySvg,
      Description: "Safety manual and Compliance, Audit, Permit",
    },
    {
      Icon: ProcessSvg,
      Description:
        "Create custom workflows to simplify safety management and administration",
    },
    {
      Icon: RobotSvg,
      Description:
        "Create Safety Buddy / Copilot and deploy them in your own applications",
    },
  ];

  const ApplicationData = [
    {
      Icon: SelfServeSvg,
      Description: "On Demand and target training",
    },
    {
      Icon: RFPServe,
      Description: "AI Safety Buddy",
    },
    {
      Icon: EmpowerSaleSvg,
      Description: "Empower EHS, HR and Field Teams",
    },
    {
      Icon: ContentSvg,
      Description:
        "For Data Driven content for Safety and compliance training based field needs and existing incident database analysis review and recommendation ",
    },
  ];

  const ApplicationAreasData = [
    {
      Icon: OptimiseSvg,
      Description: "Optimize Safety management",
    },
    {
      Icon: AutomateIndustry,
      Description: "Automate Industry And Legal Compliance Checks",
    },
    {
      Icon: InternalAndExternalSvg,
      Description: "Intuitive Incident Reporting Buddy app",
    },
    {
      Icon: DocumentAnalysisSvg,
      Description: "Compliance and Audit",
    },
  ];

  const MaxWidthValue = isDesktop
    ? "lg"
    : isLaptop
    ? "lg"
    : isTablet
    ? "md"
    : isMobile
    ? "sm"
    : "xs";

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="landing-page-wrapper">
      {/* HERO BANNER AREA */}
      <Box className="hero-section-wrapper">
        <Container maxWidth={MaxWidthValue}>
          <Grid container spacing={isLaptop ? 4 : 5} alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              order={{ xs: 2, md: 1 }}
              // textAlign={{ xs: "center", md: "end" }}
            >
              <Typography
                variant="h1"
                data-aos="fade-right"
                data-aos-duration="1000"
                className="main-heading"
              >
                SaaS Solution for EHS - Fostering Safety First Culture
              </Typography>
              <Typography
                variant="body1"
                data-aos="fade-right"
                data-aos-duration="1000"
                className="sub-heading"
              >
                {/* PrismView integrates with your tools and documents to drive AI
                powered business process and Automation */}
                {/* SaaS Solution for EHS - Fostering Safety First Culture */}
                AI powered business workflow and information assistance
              </Typography>
              <Button
                className="book-demo-btn"
                data-aos="fade-right"
                data-aos-duration="1200"
                onClick={() => setOpenBookDemo(true)}
              >
                Book A Demo
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, md: 2 }}>
              <Box
                sx={{ textAlign: " center", paddingTop: "30px" }}
                data-aos="fade-up"
                data-aos-duration="1000"
                className="HeroBanner"
              >
                <img src={HeroBanner} alt="HeroBanner" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="how-it-works-section">
        <Container maxWidth={MaxWidthValue}>
          <Typography
            variant="h2"
            data-aos="fade-up"
            data-aos-duration="1000"
            className="how-it-works-title"
          >
            Interact with PrismView
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {interactData.map((item, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  className="how-it-works-section-grid"
                  key={i}
                >
                  <Card className="work-card">
                    <img
                      src={item.Icon}
                      className="work-card-img"
                      alt="Input Processing"
                      data-aos="zoom-in"
                      data-aos-duration="600"
                    />
                    <Typography
                      variant="subtitle1"
                      className="work-card-title"
                      data-aos="fade-up"
                      data-aos-duration="600"
                    >
                      {item.Description}
                    </Typography>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>

      <Box className="application-areas-section application-areas-section-background">
        <Box className="application-areas-layers">
          <img
            src={ApplicationLayer}
            alt="ApplicationLayer"
            data-aos="zoom-in"
            data-aos-duration="600"
          />{" "}
        </Box>
        <Container maxWidth={MaxWidthValue}>
          <Typography
            variant="h2"
            data-aos="fade-up"
            data-aos-duration="1000"
            className="application-areas-title"
          >
            Application areas
          </Typography>

          <Box className="application-section">
            <Box className="application-card-wrapper">
              {ApplicationData.map((data, i) => {
                return (
                  <Card className="application-work-card" key={i}>
                    <img
                      src={data.Icon}
                      className="application-card-img"
                      alt="SelfServe"
                      data-aos="zoom-in"
                      data-aos-duration="600"
                    />
                    <Box>
                      <Typography
                        variant="subtitle1"
                        className="application-card-title"
                        data-aos="fade-up"
                        data-aos-duration="600"
                      >
                        {data.Description}
                      </Typography>
                    </Box>
                  </Card>
                );
              })}
            </Box>
            <Box className="application-card-wrapper application-card-wrapper-2">
              {ApplicationAreasData.map((data, i) => {
                return (
                  <Card className="application-work-card" key={i}>
                    <img
                      src={data.Icon}
                      className="application-card-img"
                      alt="OptimiseSvg"
                      data-aos="zoom-in"
                      data-aos-duration="600"
                    />
                    <Box>
                      <Typography
                        variant="subtitle1"
                        className="application-card-title"
                        data-aos="fade-up"
                        data-aos-duration="600"
                      >
                        {data.Description}
                      </Typography>
                    </Box>
                  </Card>
                );
              })}
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className="application-areas-section-applicable">
        <Container maxWidth={MaxWidthValue}>
          <Box className="application-industries-img">
            <img src={ApplicationIndustries} alt="ApplicationIndustries" />
          </Box>
        </Container>
      </Box>

      <Box className="book-demo-section">
        <Container maxWidth={MaxWidthValue}>
          <Box className="book-demo-data">
            <Grid container rowSpacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} padding={0}>
                <Box className="book-demo-text">
                  <Typography className="book-demo-title">
                    Book A Demo
                  </Typography>
                  <Box className="book-demo-subtitle-wrapper">
                    <Typography className="book-demo-subtitle">
                      <img src={CheckSvg} alt="layers" />{" "}
                      <span>free trial</span>
                    </Typography>
                    <Typography className="book-demo-subtitle">
                      <img src={CheckSvg} alt="layers" />{" "}
                      <span>instant onboarding</span>
                    </Typography>
                    <Typography className="book-demo-subtitle">
                      <img src={CheckSvg} alt="layers" />{" "}
                      <span>no credit card required</span>
                    </Typography>
                  </Box>
                  <Button className="book-demo-btn"> Schedule Time</Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} padding={0}>
                <Box className="book-demo-img">
                  <img
                    src={BookDemoIllstration}
                    alt="BookDemoIllstration"
                    data-aos="zoom-in"
                    data-aos-duration="600"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Footer></Footer>
      {openBookDemo && (
        <RequestDemoForm open={openBookDemo} setOpen={setOpenBookDemo} />
      )}
    </div>
  );
};

export default NewLandingPage;

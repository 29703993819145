import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  IconButton,
  Tooltip,
  InputBase,
  Paper,
  Stack,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";

import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import DataGridTable from "../dataGrid";
import moment from "moment";

import FilterDrawer from "../../layout/filterDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../assets/icons/pencil.svg";

import DeleteIcon from "../../../assets/icons/deleteRed.svg";

import SwapVertIcon from "@mui/icons-material/SwapVert";

import CustomizedDialog from "../Dialog";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Selectt from "react-select";

import {
  deleteServiceUnitSchedule,
  getAllServiceUnitSchedule,
  updateServiceUnitTask,
} from "../../../redux/slices/serviceUnit.slice";

import CreateInspectionSchedule from "./createInspectionSchedule";

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};

const frequencyOptions = [
  { label: "All", value: "All" },
  { label: "None", value: "NONE" },
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];
const dayOfWeekOptions = [
  { value: 0, label: "Monday" },
  { value: 1, label: "Tuesday" },
  { value: 2, label: "Wednesday" },
  { value: 3, label: "Thursday" },
  { value: 4, label: "Friday" },
  { value: 5, label: "Saturday" },
  { value: 6, label: "Sunday" },
];
const dayOptions = Array.from({ length: 31 }, (_, i) => ({
  value: i + 1,
  label: (i + 1).toString(),
}));
export default function InspectionScheduleList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [taskData, setTaskData] = useState([]);

  const [totalRow, setTotalRow] = useState(0);
  const { serviceUnitId } = useParams();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [selectedFormId, setSelectedFormId] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");
  const [reviewCompleteOpen, setReviewCompleteOpen] = useState(false);

  const [frequencyFilter, setfrequencyFilter] = useState({
    value: "All",
    label: "All",
  });
  const [monthDayFilter, setmonthDayFilter] = useState([]);
  const [weekDayFilter, setWeekDayFilter] = useState([]);
  const getAllTasks = async () => {
    setLoading(true);
    let frequency_filter = {};

    if (monthDayFilter.length > 0) {
      frequency_filter.month_day__in = monthDayFilter?.map(
        (item) => item.value
      );
    }
    if (weekDayFilter.length > 0) {
      frequency_filter.weekday = weekDayFilter?.map((item) => item.value);
    }
    let response = await dispatch(
      getAllServiceUnitSchedule({
        page_number: paginationModel?.page + 1,
        page_size: paginationModel?.pageSize,
        sort: sortKey,
        service_unit: serviceUnitId,
        frequency:
          frequencyFilter?.value === "All" ? undefined : frequencyFilter?.value,
        frequency_filter:
          Object.keys(frequency_filter).length !== 0
            ? JSON.stringify(frequency_filter)
            : undefined,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setTaskData(payload?.data);

      setTotalRow(payload?.total_object);
    }
    setLoading(false);
  };

  const deleteForm = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        deleteServiceUnitSchedule({ id: selectedFormId?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Inspection Schedule deleted succesfully",
          })
        );
        getAllTasks();
        setOpenDeleteModal(false);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    getAllTasks();
  }, [
    paginationModel,
    sortKey,
    frequencyFilter,
    monthDayFilter,
    weekDayFilter,
  ]);

  const handleUpdateStatus = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        updateServiceUnitTask({
          data: { status: "completed" },
          id: selectedFormId?.id,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Task Status Updated Successfully.",
          })
        );
        setReviewCompleteOpen(false);
        getAllTasks();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                setDrawerState("view");
                setIsOpenDrawer(true);
                setSelectedFormId(params?.row);
              }}
            >
              <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton
              sx={{ color: "#000000", padding: "0px" }}
              onClick={() => {
                setDrawerState("edit");
                setIsOpenDrawer(true);
                setSelectedFormId(params?.row);
              }}
            >
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000", padding: "0" }}
              onClick={() => {
                setOpenDeleteModal(true);
                setSelectedFormId(params?.row);
              }}
            >
              <img
                alt="Delete Details"
                src={DeleteIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 200,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "title")}
          className="cursor-pointer"
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "frequency",
      headerName: "Frequency",
      flex: 1, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 100,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "frequency")}
          className="cursor-pointer"
        >
          Frequency
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "start_date",
      headerName: "Start Date",
      flex: 1, // Flex value of 2 (20% of the space if the total flex is 10)
      renderCell: DateCell,
      minWidth: 130,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "start_date")}
          className="cursor-pointer"
        >
          Start Date
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "end_date",
      headerName: "End Date",
      flex: 1, // Flex value of 2 (20% of the space if the total flex is 10)
      renderCell: DateCell,
      minWidth: 130,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "end_date")}
          className="cursor-pointer"
        >
          End Date
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   // Flex value of 1 (10% of the space if the total flex is 10)
    //   minWidth: 150,
    //   renderHeader: () => (
    //     <div
    //       style={{ display: "flex", alignItems: "center" }}
    //       onClick={(e) => handleSorting(e, "created_at")}
    //     >
    //       Status
    //       <SwapVertIcon style={{ marginLeft: 4 }} />
    //     </div>
    //   ),
    //   renderCell: ({ row }) => {
    //     if (row.status !== "completed" && isDueDatePast(row.due_date)) {
    //       return (
    //         <Chip
    //           label={Status["PENDING"]?.label}
    //           style={{
    //             backgroundColor: Status["PENDING"]?.color,
    //             fontWeight: "bold",
    //             cursor: "pointer",
    //           }}
    //         />
    //       );
    //     } else if (row.status) {
    //       return (
    //         <Chip
    //           label={Status[row?.status]?.label}
    //           style={{
    //             backgroundColor: Status[row?.status]?.color,
    //             fontWeight: "bold",
    //             cursor: "pointer",
    //           }}
    //         />
    //       );
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    {
      field: "created_at",
      headerName: "Created At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      width: 130,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "created_at")}
          className="cursor-pointer"
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      minWidth: 150,
      renderCell: ActionCell,
    },
  ];
  return (
    <>
      <div className="history">
        <Box
          className="content-header kb-list-header mb-4"
          sx={{ justifyContent: "flex-end !important" }}
        >
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => {
                setDrawerState("create");
                setIsOpenDrawer(true);
              }}
            >
              Add Inspection Schedule
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={() => setOpenFilterDrawer(true)}
              style={{ height: "44px" }}
            >
              Filters
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? taskData.filter((item) =>
                    item.title
                      ?.toLowerCase()
                      .includes(searchTerm?.toLowerCase())
                  )
                : taskData
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={loading}
            paginationModel={paginationModel}
            totalRow={totalRow}
            height="calc(100vh - 345px)"
          />
        </Box>
      </div>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete {selectedFormId?.title} ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={deleteForm}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Update Status"}
        open={reviewCompleteOpen}
        handleClose={() => {
          setReviewCompleteOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are You Sure You want to mark the <b>{selectedFormId?.title}</b>{" "}
              as completed?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={handleUpdateStatus}
            >
              {loading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setReviewCompleteOpen(false)}
            >
              {loading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      {isOpenDrawer && (
        <CreateInspectionSchedule
          drawerState={drawerState}
          setIsOpenDrawer={setIsOpenDrawer}
          isOpenDrawer={isOpenDrawer}
          values={selectedFormId}
          fetchAlltasks={getAllTasks}
        />
      )}

      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box>
          <Typography variant="body1" component="label">
            Frequency
          </Typography>

          <Selectt
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={frequencyOptions}
            value={frequencyFilter}
            onChange={(e) => {
              setfrequencyFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Severity..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Select Month Day
          </Typography>

          <Selectt
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            isMulti
            className="basic-multi-select"
            options={dayOptions}
            value={monthDayFilter}
            onChange={(e) => {
              setmonthDayFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Severity..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Select Week Day
          </Typography>

          <Selectt
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            isMulti
            className="basic-multi-select"
            options={dayOfWeekOptions}
            value={weekDayFilter}
            onChange={(e) => {
              setWeekDayFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Severity..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
      </FilterDrawer>
    </>
  );
}

import {
  Select,
  MenuItem,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";

import DateRangePicker from "../dateRangePicker";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/slices/common.slice";
import {
  getAuditCountThunk,
  getDashboardReportCountThunk,
  getDashboardSafetyBuddyCountThunk,
  getProjectSelectionThunk,
  getServiceTaskCountThunk,
} from "../../../redux/slices/tenant.slice";
import { getDashboardCountThunk } from "../../../redux/slices/tenant.slice";
import { useLocation, useNavigate } from "react-router-dom";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import OfflineExporting from "highcharts/modules/offline-exporting";
import { getAllServiceUnit } from "../../../redux/slices/serviceUnit.slice";
import Modal from "../insightDatamodal";
import { useSelector } from "react-redux";

Exporting(Highcharts);
OfflineExporting(Highcharts);
ExportData(Highcharts);

const Insights = () => {
  let currentDate = new Date();
  let firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  firstDayOfMonth = moment(firstDayOfMonth).format("MM-DD-YYYY");
  currentDate = moment(currentDate).format("MM-DD-YYYY");

  // Report related date range
  const [dateRange, setDateRange] = useState([firstDayOfMonth, currentDate]);
  const [userTypeFilter, setUserTypeFilter] = useState("all");
  const [projectFilter, setProjectFilter] = useState("all");

  // report inside date range
  const [reportInsideDateRange, setReportInsideDateRange] = useState([
    firstDayOfMonth,
    currentDate,
  ]);
  const [reportInsideUserFilter, setReportInsideUserFilter] = useState("all");
  const [reportInsideProjectFilter, setReportInsideProjectFilter] =
    useState("all");
  const [reportInsideData, setReportInsideData] = useState([]);
  const [reportXLabel, setReportXLabel] = useState([]);
  const [reportSafetyBuddyCount, setReportSafetyBudddyCount] = useState([]);
  const [reportCount, setReportCount] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [serviceUnits, setServiceUnits] = useState([]);
  const [dashboardCount, setDashboardCount] = useState({});

  const [serviceUnitData, setServiceUnitData] = useState([]);
  const [serviceUserTypeFilter, setServiceUserTypeFilter] = useState("all");
  const [serviceProjectFilter, setServiceProjectFilter] = useState("all");
  const [auditData, setAuditData] = useState([]);
  const [auditUserTypeFilter, setAuditUserTypeFilter] = useState("all");
  const [auditProjectFilter, setAuditProjectFilter] = useState("all");

  const [auditDateRange, setAuditDateRange] = useState([
    firstDayOfMonth,
    currentDate,
  ]);
  const [serviceDateRange, setServiceDateRange] = useState([
    firstDayOfMonth,
    currentDate,
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [graphTitle, setGraphTitle] = useState("");

  const [countLoading, setCountLoading] = useState(true);
  const [safetyBuddyLoading, setSafetyBuddyLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(true);
  const [serviceUnitLoading, setServiceUnitLoading] = useState(true);
  const [auditLoading, setAuditLoading] = useState(true);
  const users = useSelector((state) => state.common.users);
  let userData = [{ label: "All User", value: "all" }, ...users];
  const showTablePopup = (tableHTML) => {
    setModalContent(tableHTML);
    setIsModalOpen(true);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleReportInsideDateRange = (newDateRange) => {
    setReportInsideDateRange(newDateRange);
  };
  const handleServiceDateRange = (newDateRange) => {
    setServiceDateRange(newDateRange);
  };
  const handleAuditDateRange = (newDateRange) => {
    setAuditDateRange(newDateRange);
  };

  const highlightScope = {
    highlighted: "series",
    faded: "global",
  };

  const dispatch = useDispatch();

  const fetchAllServiceUnits = async () => {
    let response = await dispatch(
      getAllServiceUnit({
        page_number: 1,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let temp = [{ label: "All Service Unit", value: "all" }];

      payload?.data?.map((element) => {
        temp.push({
          label: `${element?.name}`,
          value: element?.id,
        });
        setServiceUnits(temp);
      });
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  // Fetch all porojet selection related information
  const fetchAllProject = async () => {
    let response = await dispatch(getProjectSelectionThunk());

    const { payload } = response;

    if (payload?.status) {
      let temp = [{ label: "All Site", value: "all" }];
      payload?.data?.project?.map((element) => {
        temp.push({
          label: element?.name,
          value: element?.id,
        });
      });
      setProjectData(temp);
    }
  };

  const fetchDashboardCount = async () => {
    setCountLoading(true);
    let response = await dispatch(getDashboardCountThunk({}));
    const { payload } = response;

    if (payload?.status) {
      setDashboardCount(payload?.data);
      setCountLoading(false);
    } else {
      setCountLoading(false);
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const fetchDashboardReportCount = async () => {
    setReportLoading(true);
    let send_payload = {};
    if (reportInsideDateRange?.length > 0) {
      send_payload["start"] =
        reportInsideDateRange[0] == ""
          ? undefined
          : moment(reportInsideDateRange[0]).format("YYYY-MM-DD");
      send_payload["to"] =
        reportInsideDateRange[1] == ""
          ? undefined
          : moment(reportInsideDateRange[1]).format("YYYY-MM-DD");
    }

    if (reportInsideUserFilter !== "all") {
      send_payload["user_id"] = reportInsideUserFilter;
    }
    if (reportInsideProjectFilter !== "all") {
      send_payload["project_id"] = reportInsideProjectFilter;
    }

    let response = await dispatch(getDashboardReportCountThunk(send_payload));
    const { payload } = response;

    if (payload?.status) {
      setReportLoading(false);
      let tempData = [
        {
          id: 0,
          value: payload?.data?.approved_report,
          label: "Completed Report",
          color: "green",
        },
        {
          id: 1,
          value: payload?.data?.pending_report,
          label: "Pending Report",
          color: "red",
        },
        {
          id: 2,
          value: payload?.data?.in_review || 0,
          label: "In Review Report",
          color: "orange",
        },
      ];

      setReportInsideData(tempData);
    } else {
      setReportLoading(false);
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };
  const fetchServiceUnitData = async () => {
    setServiceUnitLoading(true);
    let send_payload = {};
    if (serviceDateRange?.length > 0) {
      send_payload["start"] =
        serviceDateRange[0] == ""
          ? undefined
          : moment(serviceDateRange[0]).format("YYYY-MM-DD");
      send_payload["to"] =
        serviceDateRange[1] == ""
          ? undefined
          : moment(serviceDateRange[1]).format("YYYY-MM-DD");
    }

    if (serviceUserTypeFilter !== "all") {
      send_payload["users"] = serviceUserTypeFilter;
    }
    if (serviceProjectFilter !== "all") {
      send_payload["service_unit_id"] = serviceProjectFilter;
    }

    let response = await dispatch(getServiceTaskCountThunk(send_payload));
    const { payload } = response;

    if (payload?.status) {
      setServiceUnitLoading(false);
      let tempData = [
        {
          id: 0,
          value: payload?.data?.inspection?.completed,
          label: "Completed Task",
          color: "green",
        },
        {
          id: 1,
          value: payload?.data?.inspection?.pending,
          label: "Pending Task",
          color: "red",
        },
        {
          id: 2,
          value: payload?.data?.inspection?.up_coming,
          label: "Upcoming Task",
          color: "orange",
        },
      ];
      setServiceUnitData(tempData);
    } else {
      setServiceUnitLoading(false);
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };
  const fetchAuditData = async () => {
    setAuditLoading(true);
    let send_payload = {};
    if (auditDateRange?.length > 0) {
      send_payload["start"] =
        auditDateRange[0] == ""
          ? undefined
          : moment(auditDateRange[0]).format("YYYY-MM-DD");
      send_payload["to"] =
        auditDateRange[1] == ""
          ? undefined
          : moment(auditDateRange[1]).format("YYYY-MM-DD");
    }

    if (auditUserTypeFilter !== "all") {
      send_payload["users"] = auditUserTypeFilter;
    }
    if (auditProjectFilter !== "all") {
      send_payload["project_id"] = auditProjectFilter;
    }

    let response = await dispatch(getAuditCountThunk(send_payload));
    const { payload } = response;

    if (payload?.status) {
      setAuditLoading(false);
      let tempData = [
        {
          id: 0,
          value: payload?.data?.audit?.completed,
          label: "Completed Task",
          color: "green",
        },
        {
          id: 1,
          value: payload?.data?.audit?.pending,
          label: "Pending Task",
          color: "red",
        },
        {
          id: 2,
          value: payload?.data?.audit?.up_coming,
          label: "Upcoming Task",
          color: "orange",
        },
      ];
      setAuditData(tempData);
    } else {
      setAuditLoading(false);
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const fetchSafetyBuddyReportCount = async () => {
    let send_payload = {};
    setSafetyBuddyLoading(true);
    if (dateRange?.length > 0) {
      send_payload["start"] =
        dateRange[0] == ""
          ? undefined
          : moment(dateRange[0]).format("YYYY-MM-DD");
      send_payload["end"] =
        dateRange[1] == ""
          ? undefined
          : moment(dateRange[1]).format("YYYY-MM-DD");
    }

    if (userTypeFilter !== "all") {
      send_payload["user_id"] = userTypeFilter;
    }

    if (projectFilter !== "all") {
      send_payload["project_id"] = projectFilter;
    }

    let response = await dispatch(
      getDashboardSafetyBuddyCountThunk(send_payload)
    );
    const { payload } = response;

    if (payload?.status) {
      let dates = [];
      setSafetyBuddyLoading(false);
      let currentDate = new Date(dateRange[0]);
      const finalDate = new Date(dateRange[1]);

      if (currentDate > finalDate) {
        throw new Error("startDate must be before endDate");
      }

      while (currentDate <= finalDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      let x_label = [];
      let safety_count = [];
      let report_count = [];

      dates?.map((element) => {
        let temp_date = moment(element).format("YYYY-MM-DD");
        let safety_buddy_push = false;
        let report_push = false;

        payload?.data?.safety_buddy?.map((dateCount) => {
          if (temp_date == dateCount?.date) {
            x_label.push(temp_date);
            safety_count.push(dateCount?.count);
            safety_buddy_push = true;
          }
        });

        payload?.data?.report?.map((dateCount) => {
          if (temp_date == dateCount?.date) {
            if (!x_label.includes(temp_date)) {
              x_label.push(temp_date);
            }
            report_count.push(dateCount?.count);
            report_push = true;
          }
        });

        if (safety_buddy_push == false && report_push == true) {
          safety_count.push(0);
        }

        if (report_push == false && safety_buddy_push == true) {
          report_count.push(0);
        }
      });

      setReportXLabel(x_label);
      setReportSafetyBudddyCount(safety_count);
      setReportCount(report_count);
    } else {
      setSafetyBuddyLoading(false);
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchDashboardCount();
    fetchAllProject();
    fetchAllServiceUnits();
  }, []);

  useEffect(() => {
    fetchDashboardReportCount();
  }, [
    reportInsideUserFilter,
    reportInsideDateRange,
    reportInsideProjectFilter,
  ]);

  useEffect(() => {
    fetchSafetyBuddyReportCount();
  }, [userTypeFilter, dateRange, projectFilter]);

  useEffect(() => {
    fetchServiceUnitData();
  }, [serviceDateRange, serviceUserTypeFilter, serviceProjectFilter]);
  useEffect(() => {
    fetchAuditData();
  }, [auditDateRange, auditUserTypeFilter, auditProjectFilter]);

  const barChartOptions = (data, title) => {
    return {
      chart: {
        type: "bar", // Bar chart (Horizontal)
      },
      title: {
        text: null,
      },

      xAxis: {
        categories: reportXLabel,
        title: {
          text: "Category",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Count",
          // align: "high",
        },
      },
      series: [
        {
          name: "Safety Buddy",
          data: data?.safetyBuddy,
          color: "#036185",
        },
        {
          name: "Report",
          data: data?.report,
        },
      ],
      tooltip: {
        shared: true,
        valueSuffix: " counts",
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
              "separator",
              "downloadCSV",
              "downloadXLS",
              // "viewData",
              {
                text: "View Data Table",
                onclick: function () {
                  const tableHTML = this.getTable();
                  setGraphTitle(title);
                  showTablePopup(tableHTML); // Custom function to handle popup
                },
              },
            ],
          },
        },
      },
    };
  };

  // Pie Chart Options
  const pieChartOptions = (data, title) => {
    return {
      chart: {
        type: "pie",
      },
      title: {
        text: null,
      },

      series: [
        {
          name: "Reports",
          colorByPoint: true,
          data: data.map((item) => ({
            name: item.label,
            y: item.value,
            color: item.color || "#7cb5ec",
          })),
        },
      ],
      tooltip: {
        pointFormat: "{point.name}: <b>{point.percentage:.1f}%</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "{point.name}: {point.percentage:.1f}%",
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
              "separator",
              "downloadCSV",
              "downloadXLS",
              // "viewData",
              {
                text: "View Data Table",
                onclick: function () {
                  const tableHTML = this.getTable();
                  setGraphTitle(title);
                  showTablePopup(tableHTML); // Custom function to handle popup
                },
              },
            ],
          },
        },
      },
    };
  };

  // Line Chart Options

  // Function to switch chart types
  console.log(reportSafetyBuddyCount, reportCount);
  return (
    <div style={{ height: "calc(100vh - 170px)", overflowY: "auto" }}>
      <Grid container spacing={3}>
        {/* Total Users Card */}
        <Grid item xs={12} md={4}>
          <Card
            className="dashboard-card"
            onClick={() => navigate("/employees")}
          >
            <CardContent>
              <Typography variant="h5" className="dashboard-card-title">
                Total Users
              </Typography>
              {countLoading ? (
                <CircularProgress size={24} sx={{ marginTop: 2 }} />
              ) : (
                <Typography className="dashboard-card-count">
                  {dashboardCount?.user}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Total Reports Card */}
        <Grid item xs={12} md={4}>
          <Card className="dashboard-card" onClick={() => navigate("/reports")}>
            <CardContent>
              <Typography variant="h5" className="dashboard-card-title">
                Total Reports
              </Typography>
              {countLoading ? (
                <CircularProgress size={24} sx={{ marginTop: 2 }} />
              ) : (
                <Typography className="dashboard-card-count">
                  {dashboardCount?.report}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Total Safety Buddy Requests Card */}
        <Grid item xs={12} md={4}>
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h5" className="dashboard-card-title">
                Total Safety Buddy Requests
              </Typography>
              {countLoading ? (
                <CircularProgress size={24} sx={{ marginTop: 2 }} />
              ) : (
                <Typography className="dashboard-card-count">
                  {dashboardCount?.safety_check}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Bar Chart for Safety Buddy / Report */}
        <Grid item xs={12} md={6}>
          <Card sx={{ minHeight: 400 }}>
            <CardContent>
              <Typography
                variant="h6"
                className="dashboard-card-title"
                sx={{ fontSize: "1.2rem !important", marginBottom: "8px" }}
              >
                Total Safety Buddy requests and reports
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateRangePicker
                    dateRange={dateRange}
                    onDateRangeChange={setDateRange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={userTypeFilter}
                    onChange={(e) => setUserTypeFilter(e.target.value)}
                    name="status"
                    className="dashboard-selection"
                    size="small"
                    displayEmpty
                  >
                    {userData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={projectFilter}
                    onChange={(e) => setProjectFilter(e.target.value)}
                    displayEmpty
                    name="status"
                    className="dashboard-selection"
                    size="small"
                  >
                    {projectData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  {safetyBuddyLoading ? (
                    <Box className="center" sx={{ minHeight: 400 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {reportSafetyBuddyCount?.length === 0 &&
                      reportCount?.length === 0 ? (
                        <Box minHeight={400} className="center">
                          No Comparison Found
                        </Box>
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={barChartOptions(
                            {
                              safetyBuddy: reportSafetyBuddyCount,
                              report: reportCount,
                            },
                            "Safety Buddy / Report"
                          )}
                        />
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Pie Chart for Reports */}
        <Grid item xs={12} md={6}>
          <Card sx={{ minHeight: 400 }}>
            <CardContent>
              <Typography
                variant="h6"
                className="dashboard-card-title"
                sx={{ fontSize: "1.2rem !important", marginBottom: "8px" }}
              >
                Reports by status
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateRangePicker
                    dateRange={reportInsideDateRange}
                    onDateRangeChange={handleReportInsideDateRange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={reportInsideUserFilter}
                    onChange={(e) => setReportInsideUserFilter(e.target.value)}
                    className="dashboard-selection"
                    size="small"
                  >
                    {userData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={reportInsideProjectFilter}
                    onChange={(e) =>
                      setReportInsideProjectFilter(e.target.value)
                    }
                    displayEmpty
                    name="status"
                    className="dashboard-selection"
                    size="small"
                  >
                    {projectData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  {reportLoading ? (
                    <Box className="center" sx={{ minHeight: 400 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {reportInsideData?.reduce(
                        (total, item) => total + item.value,
                        0
                      ) > 0 ? (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={pieChartOptions(reportInsideData, "Reports")}
                        />
                      ) : (
                        <Box minHeight={400} className="center">
                          No Reports Found
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Pie Chart for Service Unit Tasks */}
        <Grid item xs={12} md={6}>
          <Card sx={{ minHeight: 400 }}>
            <CardContent>
              <Typography
                variant="h6"
                className="dashboard-card-title"
                sx={{ fontSize: "1.2rem !important", marginBottom: "8px" }}
              >
                Service unit inspections by status
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateRangePicker
                    dateRange={serviceDateRange}
                    onDateRangeChange={handleServiceDateRange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={serviceUserTypeFilter}
                    onChange={(e) => setServiceUserTypeFilter(e.target.value)}
                    className="dashboard-selection"
                    size="small"
                  >
                    {userData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={serviceProjectFilter}
                    onChange={(e) => setServiceProjectFilter(e.target.value)}
                    displayEmpty
                    name="status"
                    className="dashboard-selection"
                    size="small"
                  >
                    {serviceUnits.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  {serviceUnitLoading ? (
                    <Box className="center" sx={{ minHeight: 400 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {serviceUnitData?.reduce(
                        (total, item) => total + item.value,
                        0
                      ) > 0 ? (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={pieChartOptions(
                            serviceUnitData,
                            "Service Unit Tasks"
                          )}
                        />
                      ) : (
                        <Box minHeight={400} className="center">
                          No Service Unit Tasks Found
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Incident Audits Pie Chart */}
        <Grid item xs={12} md={6}>
          <Card sx={{ minHeight: 400 }}>
            <CardContent>
              <Typography
                variant="h6"
                className="dashboard-card-title"
                sx={{ fontSize: "1.2rem !important", marginBottom: "8px" }}
              >
                Incident Audits by status
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateRangePicker
                    dateRange={auditDateRange}
                    onDateRangeChange={handleAuditDateRange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={auditUserTypeFilter}
                    onChange={(e) => setAuditUserTypeFilter(e.target.value)}
                    className="dashboard-selection"
                    size="small"
                  >
                    {userData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={auditProjectFilter}
                    onChange={(e) => setAuditProjectFilter(e.target.value)}
                    displayEmpty
                    name="status"
                    className="dashboard-selection"
                    size="small"
                  >
                    {projectData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  {auditLoading ? (
                    <Box className="center" sx={{ minHeight: 400 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {auditData?.reduce(
                        (total, item) => total + item.value,
                        0
                      ) > 0 ? (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={pieChartOptions(
                            auditData,
                            "Incident Audits"
                          )}
                        />
                      ) : (
                        <Box minHeight={400} className="center">
                          No Audits Found
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={modalContent}
        title={graphTitle}
      />
    </div>
  );
};

export default Insights;

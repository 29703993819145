import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Paper,
  InputBase,
  Typography,
  Grid,
  Select,
  MenuItem,
  Stack,
  DialogContent,
  DialogActions,
} from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IoDocumentText } from "react-icons/io5";
import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import {
  deleteForm301,
  getAllForm300,
  getAllForm301,
} from "../../../redux/slices/form300.slice";
import DataGridTable from "../dataGrid";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import FilterDrawer from "../../layout/filterDrawer";
import DateRangePicker from "../dateRangePicker";
import { setSnackbar } from "../../../redux/slices/common.slice";
import { getAllTenantReport } from "../../../redux/slices/tenant.slice";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../assets/icons/pencil.svg";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import axios from "axios";
import dayjs from "dayjs";
import DeleteIcon from "../../../assets/icons/deleteRed.svg";
import CustomizedDialog from "../Dialog";
import { useSelector } from "react-redux";
import logAnalyticsEvent from "../../../firebase/analyticsLogger";
export default function Form301Logs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form300HistoryData, setForm300HistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [tokenPDF, setTokenPDF] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [dateRange_create, setDateRange_create] = useState(["", ""]);
  const [dateRange_update, setDateRange_update] = useState(["", ""]);
  const [sortKey, setSortKey] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const location = useLocation();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    logAnalyticsEvent("screen_view", {
      page_path: location.pathname + location.search,
      user_id: user?.data?.user?.id,
      page_title: "Osha - Form301",
    });
  }, [location, user]);

  const downloadDocumentService = async (fileUrl, options) => {
    return axios.get(fileUrl, options);
  };
  const handleDownloadPDF = async (pdfLink, fileName) => {
    const link = document.createElement("a");
    link.href = pdfLink + "?" + tokenPDF;
    link.target = "_blank";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // let finalUrl = pdfLink + "?" + tokenPDF;
    // console.log(finalUrl);
    // // try {
    // //   const response = await downloadDocumentService(finalUrl, {
    // //     responseType: "blob",
    // //   });
    // //   // Get content type and infer extension
    // //   const contentType = response.headers["content-type"];

    // //   const extension = ".pdf";

    // //   // Append extension and remove the existing extension if any
    // //   fileName = fileName.split(".")[0] + extension;

    // //   const url = URL.createObjectURL(new Blob([response.data]));

    // //   const anchor = document.createElement("a");
    // //   // anchor.href = url;
    // //   anchor.href = finalUrl;
    // //   anchor.download = fileName;
    // //   anchor.target = "_blank";
    // //   document.body.appendChild(anchor);
    // //   anchor.click();
    // //   document.body.removeChild(anchor);
    // //   URL.revokeObjectURL(url);
    // // } catch (error) {
    // //   console.log(error);
    // // }
    // fetch(finalUrl, { mode: "no-cors" }).then((response) => {
    //   response.blob().then((blob) => {
    //     // Creating new object of PDF file
    //     const fileURL = window.URL.createObjectURL(blob);

    //     // Setting various property values
    //     let alink = document.createElement("a");
    //     alink.href = fileURL;
    //     alink.download = "SamplePDF.pdf";
    //     alink.click();
    //   });
    // });
  };
  const handleDateRangeChange = (newDateRange) => {
    dateRange_create(newDateRange);
  };
  const getAllForms300 = async () => {
    let custom_filter = {};

    if (dateRange_create?.length > 0) {
      custom_filter.created_at__gte =
        dateRange_create[0] == ""
          ? undefined
          : dayjs(dateRange_create[0]).format("YYYY-MM-DD");
      custom_filter.created_at__lte =
        dateRange_create[1] == ""
          ? undefined
          : dayjs(dateRange_create[1]).add(1, "day").format("YYYY-MM-DD");
    }
    if (dateRange_update?.length > 0) {
      custom_filter.updated_at__gte =
        dateRange_update[0] == ""
          ? undefined
          : dayjs(dateRange_update[0]).format("YYYY-MM-DD");
      custom_filter.updated_at__lte =
        dateRange_update[1] == ""
          ? undefined
          : dayjs(dateRange_update[1]).add(1, "day").format("YYYY-MM-DD");
    }
    let response = await dispatch(
      getAllForm301({
        page_number: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        custom_filter: JSON.stringify(custom_filter),
        sort: sortKey,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setForm300HistoryData(payload?.data);

      setTotalRow(payload?.total_object);
      setTokenPDF(payload?.token);
    }
  };
  useEffect(() => {
    getAllForms300();
  }, [paginationModel, dateRange_update, dateRange_create, sortKey]);

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }
  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "title")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "description")}
        >
          Description
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 130,
      flex: 1,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      minWidth: 130,
      flex: 1,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const { form_link, id, title } = params.row;
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip title="View">
              <IconButton
                sx={{ padding: "0px" }}
                onClick={() => navigate(`/reports/form301/${id}/view`)}
              >
                <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                sx={{ color: "#000000", padding: "0px" }}
                onClick={() => navigate(`/reports/form301/${id}/edit`)}
              >
                <img
                  alt="Edit Details"
                  src={Pencil}
                  width={24}
                  height={24}
                  className="actionIcon"
                  cursor={"pointer"}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download PDF">
              <IconButton
                sx={{ color: "#000000", padding: "0" }}
                onClick={() => handleDownloadPDF(form_link, title)}
              >
                <IoDocumentText size={24} cursor={"pointer"} color="#036185" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                sx={{ color: "#000000", padding: 0 }}
                onClick={() => {
                  setOpenDeleteModal(true);
                  setSelectedReport(params.row);
                }}
              >
                <img
                  alt="Delete Details"
                  src={DeleteIcon}
                  width={24}
                  height={24}
                  className="actionIcon"
                  cursor={"pointer"}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        deleteForm301({ id: selectedReport?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        setOpenDeleteModal(false);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Form Deleted succesfully",
          })
        );
        logAnalyticsEvent("delete_osha-form301", {
          user_id: user?.data?.user?.id,
        });
        getAllForms300();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="history">
        <Box className="content-header kb-list-header mb-4">
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/reports");
            }}
            startIcon={<ArrowBackIcon />}
            className="primary-button back-button"
          >
            Back
          </Button>
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => navigate("/reports/form301/select")}
            >
              Form 301
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={() => setOpenFilterDrawer(true)}
            >
              Filters
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? form300HistoryData.filter(
                    (item) =>
                      item.title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      item.description
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
                : form300HistoryData
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>

      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Created At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange_create}
                onDateRangeChange={(e) => setDateRange_create(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Updated At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange_update}
                onDateRangeChange={(e) => setDateRange_update(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
      </FilterDrawer>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete {selectedReport?.title} ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
}

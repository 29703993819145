import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ isOpen, onClose, content, title }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: { borderRadius: 12, padding: 16 }, // Rounded corners and spacing
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            {title || "Modal Title"}
          </Typography>
        </DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers className="highcharts-data-table">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent>
    </Dialog>
  );
};

export default Modal;

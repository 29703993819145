// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const environment = process.env.REACT_APP_FIREBASE_ENV;

let firebaseConfig;

if (environment === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyAKsyXCJjJL0CKYK4TIIlj_JiSLpKtBR3s",
    authDomain: "orionmobileapp-ed0f7.firebaseapp.com",
    projectId: "orionmobileapp-ed0f7",
    storageBucket: "orionmobileapp-ed0f7.firebasestorage.app",
    messagingSenderId: "769700893738",
    appId: "1:769700893738:web:b1af68c34add4e50175f53",
    measurementId: "G-DEHK185N19",
  };
} else if (environment === "staging") {
  firebaseConfig = {
    apiKey: "AIzaSyDypyB2vKjhvHbtjNvJWKqybK2CcWd9yvw",
    authDomain: "orionmobileappstaging.firebaseapp.com",
    projectId: "orionmobileappstaging",
    storageBucket: "orionmobileappstaging.firebasestorage.app",
    messagingSenderId: "608200509683",
    appId: "1:608200509683:web:4b7c91488de37e9c968f86",
    measurementId: "G-CF37YW3ZVX",
  };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const performance = getPerformance(app);

export { analytics, performance };

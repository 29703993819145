import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  IconButton,
  Tooltip,
  InputBase,
  Paper,
  Stack,
  Chip,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";

import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import DataGridTable from "../dataGrid";
import moment from "moment";

import FilterDrawer from "../../layout/filterDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { MdOutlineWatchLater, MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../assets/icons/pencil.svg";

import DeleteIcon from "../../../assets/icons/deleteRed.svg";

import SwapVertIcon from "@mui/icons-material/SwapVert";

import { FormTypeMapping, Status } from "../../../utils/icon/CommonFunctions";
import CustomizedDialog from "../Dialog";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Selectt from "react-select";

import {
  deleteServiceUnitTask,
  getAllServiceUnitTask,
  updateServiceUnitTask,
} from "../../../redux/slices/serviceUnit.slice";

import { IoIosCheckmarkCircle } from "react-icons/io";
import {
  deleteFormInstanceById,
  fetchAllFormsInstances,
  getAllForm,
} from "../../../redux/slices/forms.slice";
import CreateFormModal from "../forms/createFormModal";
import EditFormModal from "../forms/editFormModal";

const customStyles = {
  control: (base) => ({
    ...base,
    border: "1px solid #036185",
    borderRadius: 8,
    padding: 4,
    boxShadow: "none",

    "&:hover": {
      borderColor: "#036185",
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 180,
    overflowY: "auto",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};

export default function WorkPermitList({ isActive }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [forms, setForms] = useState([]);
  const [workPermiTemplates, setWorkPermiTemplates] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const { empId } = useParams();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState();
  const [mode, setMode] = useState("edit");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedForm, setSelectedForm] = useState();
  const [isOpenCreateDrawer, setIsOpenCreateDrawer] = useState(false);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState(false);

  const handleTypeChange = (selectedOption) => {
    setSelectedForm(selectedOption);
    setError();
  };

  const getAllForms = async () => {
    setLoading(true);

    let custom_filter = {};

    custom_filter.form__type = "work_permit";

    let response = await dispatch(
      fetchAllFormsInstances({
        page_number: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        sort: sortKey,
        custom_filter: JSON.stringify(custom_filter),
        user: empId,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setForms(payload?.data);

      setTotalRow(payload?.total_object);
    }
    setLoading(false);
  };
  const getWorkPermitsForm = async () => {
    setLoading(true);

    let response = await dispatch(
      getAllForm({
        custom_filter: JSON.stringify({ type__in: ["work_permit"] }),

        list: "all",
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setWorkPermiTemplates(
        payload?.data?.map((item) => ({ label: item.title, value: item.id }))
      );

      setTotalRow(payload?.total_object);
    }
    setLoading(false);
  };

  const deleteForm = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        deleteFormInstanceById({ formId: selectedFormId?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "form deleted succesfully",
          })
        );
        getAllForms();
        setOpenDeleteModal(false);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };
  useEffect(() => {
    getWorkPermitsForm();
  }, []);
  useEffect(() => {
    getAllForms();
  }, [paginationModel, sortKey]);

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                setMode("view");
                setIsOpenEditDrawer(true);
                setSelectedFormId(params.row);
              }}
            >
              <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton
              sx={{ color: "#000000", padding: "0px" }}
              onClick={() => {
                setMode("edit");
                setIsOpenEditDrawer(true);
                setSelectedFormId(params.row);
              }}
            >
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000", padding: "0" }}
              onClick={() => {
                setOpenDeleteModal(true);
                setSelectedFormId(params?.row);
              }}
            >
              <img
                alt="Delete Details"
                src={DeleteIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 200,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "title")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "formType",
      headerName: "Form Type",
      flex: 2,
      minWidth: 200,
      valueGetter: (params) => FormTypeMapping[params.row.form?.type] || "-",

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "form__type")}
        >
          Form Type
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "created_at",
      headerName: "Created At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      minWidth: 140, // Adjust minWidth as needed
      flex: 1,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      minWidth: 140, // Adjust minWidth as needed
      flex: 1,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      minWidth: 130,
      renderCell: ActionCell,
    },
  ];
  return (
    <>
      <div className="history">
        <Box
          className="content-header kb-list-header mb-4"
          sx={{ justifyContent: "flex-end !important" }}
        >
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>

            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Create Work Permit
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? forms.filter((item) =>
                    item.title
                      ?.toLowerCase()
                      .includes(searchTerm?.toLowerCase())
                  )
                : forms.slice(
                    paginationModel.page * paginationModel.pageSize,
                    (paginationModel.page + 1) * paginationModel.pageSize
                  )
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={loading}
            paginationModel={paginationModel}
            totalRow={totalRow}
            height="calc(100vh - 345px)"
          />
        </Box>
      </div>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete <b>{selectedFormId?.title} </b>?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={deleteForm}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Select Template"}
        height="300px"
        width="600px"
        open={openModal}
        handleClose={() => setOpenModal(false)}
      >
        <DialogContent>
          <Box p={2}>
            {workPermiTemplates.length !== 0 && (
              <>
                {error && <div className="form-error-message">{error}</div>}
                <Selectt
                  value={selectedForm}
                  onChange={(e) => handleTypeChange(e)}
                  options={workPermiTemplates}
                  styles={customStyles}
                  placeholder="Select Work Permit Template"
                  defaultMenuIsOpen={true}
                />
              </>
            )}

            {!workPermiTemplates.length && (
              <Box
                sx={{
                  textAlign: "center",
                  padding: 3,

                  marginTop: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, color: "#333" }}
                >
                  No Work Permit Templates Found
                </Typography>
                <Typography
                  variant="label"
                  sx={{ marginTop: 1, color: "#555" }}
                >
                  Please create a new Work Permit Template to proceed.
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {!workPermiTemplates.length ? (
              <Button
                sx={{ marginRight: "15px" }}
                variant="contained"
                className="primary-button"
                disabled={loading}
                onClick={() => navigate(`/settings/formbuilder/select`)}
              >
                Create Template
              </Button>
            ) : (
              <Button
                sx={{ marginRight: "15px" }}
                variant="contained"
                className="primary-button"
                disabled={loading}
                onClick={() => {
                  if (!selectedForm) {
                    setError("Select Template First");
                    return;
                  }

                  setIsOpenCreateDrawer(true);
                  setOpenModal(false);
                }}
              >
                Create Work Permit
              </Button>
            )}
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      {isOpenCreateDrawer && (
        <CreateFormModal
          isOpenDrawer={isOpenCreateDrawer}
          setIsOpenDrawer={setIsOpenCreateDrawer}
          taskId={undefined}
          templateId={selectedForm?.value}
          fetchAllTasks={getAllForms}
          empId={empId}
          title="Create Work Permit"
        />
      )}

      {isOpenEditDrawer && (
        <EditFormModal
          mode={mode}
          isOpenDrawer={isOpenEditDrawer}
          setIsOpenDrawer={setIsOpenEditDrawer}
          templateId={selectedFormId?.id}
          fetchAllTasks={getAllForms}
          title={mode === "edit" ? "Edit Work Permit" : "Work Permit Details"}
          empId={empId}
        />
      )}
    </>
  );
}

import React, { useEffect, useState, useRef } from "react"; // Import useRef
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import {
  createFormInstance,
  documentAnalyse,
  getFormById,
} from "../../../redux/slices/forms.slice";
import Loader from "../Loader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography,
  List,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  checkEmailDuplicate,
  ProjectLocationAutoCompleteSuggesstionThunk,
  updateUserThunk,
} from "../../../redux/slices/tenant.slice";

import { setSnackbar } from "../../../redux/slices/common.slice";

import "../../../styles/forms.css";
import { getAllServiceUnit } from "../../../redux/slices/serviceUnit.slice";
import Select from "react-select";

import { PAGE_URL } from "../../../constants/pageUrl.constant";
import DeleteIcon from "@mui/icons-material/Delete";
import { PhotoCamera } from "@mui/icons-material";
import { useSelector } from "react-redux";
import CustomizedDialog from "../Dialog";
import logAnalyticsEvent from "../../../firebase/analyticsLogger";
import ReactPlayer from "react-player";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";

const mediaData = [
  {
    id: 1,
    title: "Introduction to React",
    thumbnail: "https://via.placeholder.com/100",
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
    transcription: [
      {
        text: "So why do we need such a code sandbox environment or such a local project created with extra tools like Wheat?",
        timeframes: [
          { start: 0, end: 10 },
          { start: 30, end: 40 },
        ],
      },
      {
        text: "If we simply want to write some React code, why can't we just create an HTML file and a JavaScript file and reference the script file from inside the HTML file and then put our React code into that script file?",
        timeframes: [
          { start: 5, end: 15 },
          { start: 120, end: 125 },
        ],
      },
      {
        text: "Why is that not really an option?",
        timeframes: [
          { start: 20, end: 30 },
          { start: 40, end: 50 },
        ],
      },
      {
        text: "This syntax is called JSX and you will learn more about it throughout this course.",
        timeframes: [
          { start: 60, end: 75 },
          { start: 90, end: 100 },
        ],
      },
    ],
    keywords: [
      { keyword: "React", timeframes: [{ start: 0, end: 10 }] },
      { keyword: "Advantages", timeframes: [{ start: 11, end: 20 }] },
      { keyword: "Setup", timeframes: [{ start: 21, end: 30 }] },
    ],
    topics: [
      {
        topic: "React Basics",
        timeframes: [
          { start: 0, end: 10 },
          { start: 45, end: 55 },
        ],
      },
      {
        topic: "JavaScript Fundamentals",
        timeframes: [{ start: 11, end: 30 }],
      },
      { topic: "React Components", timeframes: [{ start: 31, end: 60 }] },
    ],
    branches: [
      {
        branch: "Reactjs",
        timeframes: [
          { start: 0, end: 10 },
          { start: 20, end: 60 },
        ],
      },
      { branch: "Udemy", timeframes: [{ start: 5, end: 20 }] },
      {
        branch: "Env",
        timeframes: [{ start: 20, end: 50 }],
      },
    ],
  },
  {
    id: 2,
    title: "Understanding JavaScript",
    thumbnail: "https://via.placeholder.com/100",
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
    transcription: [
      {
        text: "Introduction to JavaScript",
        timeframes: [
          { start: 0, end: 10 },
          { start: 30, end: 40 },
        ],
      },
      {
        text: "Variables and Data Types",
        timeframes: [
          { start: 5, end: 15 },
          { start: 50, end: 60 },
        ],
      },
      {
        text: "Functions in JavaScript",
        timeframes: [
          { start: 20, end: 30 },
          { start: 40, end: 50 },
        ],
      },
      {
        text: "JavaScript in the Browser",
        timeframes: [
          { start: 60, end: 75 },
          { start: 90, end: 100 },
        ],
      },
    ],
    keywords: [
      {
        keyword: "JavaScript",
        timeframes: [
          { start: 0, end: 15 },
          { start: 19, end: 21 },
          { start: 120, end: 123 },
        ],
      },
      { keyword: "Functions", timeframes: [{ start: 16, end: 30 }] },
      { keyword: "Browser", timeframes: [{ start: 31, end: 45 }] },
    ],
    topics: [
      {
        topic: "Introduction to JavaScript",
        timeframes: [{ start: 0, end: 10 }],
      },
      { topic: "Variables & Data Types", timeframes: [{ start: 5, end: 20 }] },
      {
        topic: "Functions in JavaScript",
        timeframes: [{ start: 20, end: 50 }],
      },
      {
        topic: "JavaScript in the Browser",
        timeframes: [{ start: 60, end: 100 }],
      },
    ],
    branches: [
      {
        branch: "Nodejs",
        timeframes: [
          { start: 0, end: 10 },
          { start: 20, end: 60 },
        ],
      },
      { branch: "Udemy", timeframes: [{ start: 5, end: 20 }] },
      {
        branch: "Env",
        timeframes: [{ start: 20, end: 50 }],
      },
    ],
  },
];

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && (
      <Box
        sx={{
          padding: "4px 12px",
          height: "calc(100vh - 300px)",
          overflowY: "auto",
        }}
      >
        {children}
      </Box>
    )}
  </div>
);
const TabPanel2 = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && (
      <Box
        sx={{
          padding: "12px 0",
        }}
      >
        {children}
      </Box>
    )}
  </div>
);
const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};
export default function AuditDetailPage() {
  const [selectedMedia, setSelectedMedia] = useState(mediaData[0]);
  const [player, setPlayer] = useState(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(true);
  const [selectedKeyword, setSelectedKeyword] = useState();
  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedBranch, setSelectedBranch] = useState();

  const [videoDuration, setVideoDuration] = useState(0);
  const [sliderValue, setSliderValue] = useState(0); // for current slider value
  const [sliderWidth, setSliderWidth] = useState(0); // to get width of the slider for calculating strip width
  const [activeTab, setActiveTab] = useState(0);
  const [mainTab, setMainTab] = useState(0);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [formSections, setFormSections] = useState([]);

  const [locationOption, setLocationOption] = useState([]);

  const [errors, setErrors] = useState({}); // State to manage validation errors
  const [serviceUnitOptions, setServiceUnitOptions] = useState();
  const [serviceUnit, setServiceUnit] = useState();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const [selectedUser, setSelectedUser] = useState();

  const [imageData, setImageData] = useState({});
  const id = 120;
  const [formType, setFormType] = useState();

  const formRef = useRef(null);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleMainTabChange = (event, newValue) => {
    setMainTab(newValue);
  };
  const handleMediaSelect = (media) => {
    setSelectedMedia(media);
  };

  const handleSeek = (time) => {
    if (player) player.seekTo(time, "seconds");
  };

  const toggleGallery = () => {
    setIsGalleryOpen(!isGalleryOpen);
  };

  const handleKeywordClick = (keyword) => {
    setSelectedKeyword(keyword);
  };
  const handleEntityClick = (keyword) => {
    setSelectedBranch(keyword);
  };

  const handleDuration = (duration) => {
    setVideoDuration(duration);
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    handleSeek(newValue);
  };

  // Convert time in seconds to mm:ss format
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSliderRef = (slider) => {
    if (slider) {
      const width = slider.offsetWidth;

      setSliderWidth(width);
    }
  };
  const handleSkipTotranscription = (transcription) => {
    console.log(transcription);
    if (transcription?.timeframes && transcription?.timeframes?.length > 0) {
      let startTime = transcription?.timeframes[0]?.start;
      console.log(startTime);
      handleSeek(startTime);
    }
  };

  const calculateTrackColorPosition = (timeframe) => {
    if (!sliderWidth || !videoDuration) {
      console.error("Slider width or video duration not set");
      return { startPos: 0, endPos: 0 };
    }

    const startPos = (timeframe.start / videoDuration) * sliderWidth;
    const endPos = (timeframe.end / videoDuration) * sliderWidth;

    // Ensure positions are within slider bounds
    return {
      startPos: Math.min(Math.max(startPos, 0), sliderWidth),
      endPos: Math.min(Math.max(endPos, 0), sliderWidth),
    };
  };

  const [currentTime, setCurrentTime] = useState(0); // State to store current time
  // This function will be triggered on every progress update of the video
  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds); // Current time in seconds
  };

  const TimeLine = (item) => {
    return (
      <Box sx={{ position: "relative" }} mt={2}>
        {/* Slider */}
        <Slider
          value={sliderValue}
          min={0}
          max={videoDuration}
          step={0.1}
          onChange={handleSliderChange}
          sx={{
            width: "100%",
            color: "#ddd",
            height: 15,
            padding: "0 !important",

            "& .MuiSlider-thumb": {
              display: "block", // Make the thumb visible

              zIndex: 3, // Ensure it's above the highlighted section
            },

            "& .MuiSlider-track": {
              zIndex: 1, // Below the highlighted section
            },

            "& .MuiSlider-rail": {
              zIndex: 1, // Below the highlighted section
            },
          }}
          ref={handleSliderRef}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => formatTime(value)}
        />
        {/* Mark start and end times, and color in-between */}
        {item?.timeframes.map((timeframe, index) => {
          const { startPos, endPos } = calculateTrackColorPosition(timeframe);

          const handleBoxClick = (e) => {
            const offsetX = e.nativeEvent.offsetX; // Get the clicked position relative to the box
            const boxStartPos = e.target.offsetLeft; // Get the position of the box relative to the entire slider

            // Calculate the new value of the slider based on the click position
            const newValue =
              ((offsetX + boxStartPos) / sliderWidth) * videoDuration;

            handleSliderChange(null, newValue);
          };

          return (
            <Box
              key={index}
              sx={{
                position: "absolute",
                left: startPos,
                width: endPos - startPos,
                height: 16,
                top: 0,
                backgroundColor: "#036185", // Color for the highlighted section
                zIndex: 2, // Above the slider track but below the thumb
                pointerEvents: "auto",
              }}
              onClick={handleBoxClick} // Trigger onClick event when clicked
            />
          );
        })}
      </Box>
    );
  };

  const isInTimeframe = (timeframes, currentTime) => {
    console.log(timeframes, currentTime);
    return timeframes?.some(
      (frame) => currentTime >= frame.start && currentTime <= frame.end
    );
  };
  const user = useSelector((state) => state.user);

  const fetchAllserviceUnit = async () => {
    setLoading(true);

    try {
      let response = await dispatch(
        getAllServiceUnit({
          page_number: 1,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setServiceUnitOptions(
          payload?.data?.map((item, idx) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setLoading(false);
  };
  useEffect(() => {
    fetchAllserviceUnit();
  }, []);
  // Handle file input change for multiple images

  const fetchFormById = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormById({ formId: id }));
      const { payload } = response;
      if (payload?.status) {
        setFormSections(payload?.data?.form_struct_json);
        setFormType(payload?.data?.type);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFormById();
  }, []);
  let timeOut = null;
  const handleAutocompleteChange = (event, values) => {
    if (values !== "") {
      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: values,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
        } catch (error) {}
      }, 500);
    }
  };

  const validateForm = (formData) => {
    const newErrors = {};
    if (!selectedUser && formType === "work_permit")
      newErrors["user"] = "Required";
    formSections.forEach((section) => {
      section.questions.forEach((question) => {
        const value = formData[question.id];

        if (question.label === "Email") {
          // Check here for email validation
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Simple email regex
          if (!emailRegex.test(value)) {
            newErrors[question.id] = `Invalid Email Format`;
          }
        }

        // Only validate required questions
        if (question.required) {
          if (question.type === "CheckBox") {
            // Checkbox can be unchecked, handle accor dingly
            if (!value) {
              newErrors[question.id] = `Required`;
            }
          } else if (question.type === "Media") {
            if (
              !imageData[question.label] || // Check if it's null, undefined, or an empty object
              (Array.isArray(imageData[question.label]) &&
                imageData[question.label].length === 0) // Check if it's an empty array
            ) {
              newErrors[question.id] = `Required`; // Add an error if validation fails
            }
          } else if (question.type === "Video") {
            if (
              !imageData[question.label] || // Check if it's null, undefined, or an empty object
              (Array.isArray(imageData[question.label]) &&
                imageData[question.label].length === 0) // Check if it's an empty array
            ) {
              newErrors[question.id] = `Required`; // Add an error if validation fails
            }
          } else if (!value) {
            newErrors[question.id] = `Required`;
          }
        }
      });
    });
    setErrors(newErrors);
    scrollToError(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formElement = formRef.current;
    let data = new FormData(formElement);
    const formData = Object.fromEntries(data);

    if (!validateForm(formData)) return; // Prevent submission if validation fails

    setLoading(true);
    const finalData = {
      form: +id,
      service_unit: serviceUnit?.value,
      user: selectedUser ? selectedUser.value : undefined,
      submission_json: formSections.map((section) => {
        return {
          section: section.id,
          section_name: section.name,
          questions: section.questions.map((question) => {
            let isSelected = null;
            let groupValue = null;
            let images = null;
            let videos = null;
            let answer = "";

            if (question.type === "CheckBox") {
              isSelected = formData[question.id] === "true";
            } else if (question.type === "Radio Buttons") {
              groupValue = formData[`${question.id}`] || null;
            } else if (question.type === "Media") {
              images =
                imageData[question.label]?.map((item) => {
                  return {
                    base: removeUntilFirstComma(item.base64) || "", // Fallback if undefined
                    extension: item.extension || "", // Fallback if undefined
                  };
                }) || [];
            } else if (question.type === "Video") {
              videos =
                imageData[question.label]?.map((item) => {
                  return {
                    base: removeUntilFirstComma(item.base64) || "", // Fallback if undefined
                    extension: item.extension || "", // Fallback if undefined
                  };
                }) || [];
            } else {
              answer = formData[question.id] || null;
            }

            return {
              question_id: question.id,
              answer: answer,
              answer_type: question.type,
              group_value: groupValue,
              is_selected: isSelected,
              required: question.required,
              images: images,
              videos: videos,
              label: question.label,
              width: question.width,
              options: question.options,
            };
          }),
        };
      }),
    };

    try {
      let response = await dispatch(
        createFormInstance({
          formData: finalData,
        })
      );
      const { payload } = response;
      if (payload && payload.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Form submitted Succesfully.",
          })
        );
        logAnalyticsEvent("create_form_submission", {
          user_id: user?.data?.user?.id,
        });
        navigate("/forms");
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  function removeUntilFirstComma(str) {
    const commaIndex = str.indexOf(",");
    if (commaIndex !== -1) {
      return str.substring(commaIndex + 1); // +1 to skip the comma itself
    }
    return str; // return original string if no comma is found
  }

  const scrollToError = (errors) => {
    const errorField = Object.keys(errors)[0]; // Get the first error field

    const errorElement = document.getElementById(errorField); // Find the DOM element of that field

    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to the error field
    }
  };

  return (
    <>
      <Tabs
        value={mainTab}
        onChange={handleMainTabChange}
        aria-label="TODO tabs"
        className="configure-action-tab"
      >
        <Tab label={"Video Analaysis"} />
        <Tab label={"Form"} />
      </Tabs>
      <TabPanel2 value={mainTab} index={0}>
        <Box className="box-container">
          <Grid container spacing={2}>
            {/* Left Side: Media List */}
            {isGalleryOpen && (
              <Grid item xs={12} md={4} sx={{ transition: "width 0.3s ease" }}>
                <Typography
                  variant="h3"
                  sx={{ fontSize: "18px", marginBottom: "12px" }}
                >
                  <FaCircleChevronLeft
                    onClick={toggleGallery}
                    style={{ marginRight: "8px" }}
                  />
                  Media Library
                </Typography>
                <List>
                  {mediaData.map((media) => (
                    <ListItem
                      key={media.id}
                      button
                      onClick={() => handleMediaSelect(media)}
                      sx={{
                        marginBottom: 1,
                        backgroundColor:
                          selectedMedia.id === media.id ? "#e0f7fa" : "#fff",
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar src={media.thumbnail} variant="rounded" />
                      </ListItemAvatar>
                      <ListItemText primary={media.title} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}

            {/* Right Side: Player and Details */}
            <Grid item xs={12} md={isGalleryOpen ? 7.8 : 12}>
              <Typography
                variant="h3"
                sx={{ fontSize: "18px", marginBottom: "18px" }}
              >
                {!isGalleryOpen && (
                  <FaCircleChevronRight
                    onClick={toggleGallery}
                    style={{ marginRight: "8px" }}
                  />
                )}
                {selectedMedia?.title}
              </Typography>
              <Box sx={{ height: "calc(100vh - 244px)", overflowY: "auto" }}>
                <Grid container spacing={2}>
                  {/* Video Player */}
                  <Grid item xs={!isGalleryOpen ? 6 : 12}>
                    <ReactPlayer
                      url={selectedMedia.url}
                      controls
                      width="100%"
                      height={isGalleryOpen ? "400px" : "100%"}
                      ref={(playerInstance) => setPlayer(playerInstance)}
                      onDuration={handleDuration}
                      onProgress={handleProgress}
                    />
                  </Grid>

                  {/* Transcription and Keywords */}
                  <Grid item xs={!isGalleryOpen ? 6 : 12}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      aria-label="TODO tabs"
                      className="configure-action-tab"
                    >
                      <Tab label={"Transcriptions"} />
                      <Tab label={"Insights"} />
                    </Tabs>
                    <TabPanel value={activeTab} index={0}>
                      <List>
                        {selectedMedia.transcription.map((segment, index) => {
                          // If the transcription is within the current time range, display it
                          return (
                            <ListItem
                              key={index}
                              button
                              sx={{
                                marginBottom: 1,
                                backgroundColor: isInTimeframe(
                                  segment.timeframes,
                                  currentTime
                                )
                                  ? "#64bcd2"
                                  : "#f1f1f1",
                                color: isInTimeframe(
                                  segment.timeframes,
                                  currentTime
                                )
                                  ? "white"
                                  : "black",
                                transition: "all 0.3s ease", // Smooth transition for hover effect
                                "&:hover": {
                                  backgroundColor: isInTimeframe(
                                    segment.timeframes,
                                    currentTime
                                  )
                                    ? "#4ca3ba" // Slightly darker shade for active item hover
                                    : "#e0e0e0", // Slightly darker shade for inactive item hover
                                  color: isInTimeframe(
                                    segment.timeframes,
                                    currentTime
                                  )
                                    ? "white"
                                    : "black", // Ensure text remains visible
                                },
                              }}
                              onClick={() => handleSkipTotranscription(segment)}
                            >
                              <PlayCircleIcon
                                sx={{ marginRight: 1, color: "#036185" }}
                              />
                              <Box>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    display: "block",
                                    color: isInTimeframe(
                                      segment.timeframes,
                                      currentTime
                                    )
                                      ? "white"
                                      : "#757575", // Subtle color for time
                                    fontSize: "0.8rem",
                                    marginBottom: "0.2rem",
                                  }}
                                >
                                  {formatTime(segment.timeframes[0].start)} -{" "}
                                  {formatTime(segment.timeframes[0].end)}
                                </Typography>
                                <ListItemText primary={segment.text} />
                              </Box>
                            </ListItem>
                          );
                        })}
                      </List>
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{ marginBottom: 1, fontWeight: 500 }}
                        >
                          Topics
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                          {selectedMedia?.topics?.map((topic, index) => (
                            <Chip
                              key={index}
                              label={topic.topic}
                              sx={{
                                cursor: "pointer",
                                fontSize: "14px !important",

                                color: "#fff",
                                "&:hover": {
                                  backgroundColor: "#b6877a",
                                },
                              }}
                              style={{
                                backgroundColor:
                                  topic.topic === selectedTopic?.topic
                                    ? "#64bcd2"
                                    : "",
                              }}
                              onClick={() => setSelectedTopic(topic)}
                            />
                          ))}
                        </Box>
                        {TimeLine(selectedTopic)}
                      </Grid>
                      <Grid xs={12}>
                        <Divider sx={{ margin: "8px 0" }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{ marginBottom: 1, fontWeight: 500 }}
                        >
                          Keywords
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                          {selectedMedia?.keywords?.map((keyword, index) => {
                            console.log(
                              keyword.keyword === selectedKeyword?.keyword
                            );
                            return (
                              <Chip
                                key={index}
                                label={keyword.keyword}
                                sx={{
                                  cursor: "pointer",
                                  fontSize: "14px !important",
                                  padding: "0 !important",
                                  color: "#fff",
                                  "&:hover": {
                                    backgroundColor: "#b6877a",
                                  },
                                }}
                                style={{
                                  backgroundColor:
                                    keyword.keyword === selectedKeyword?.keyword
                                      ? "#64bcd2"
                                      : "",
                                }}
                                onClick={() => handleKeywordClick(keyword)}
                              />
                            );
                          })}
                        </Box>
                        {TimeLine(selectedKeyword)}
                      </Grid>
                      <Grid xs={12}>
                        <Divider sx={{ margin: "8px 0" }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{ marginBottom: 1, fontWeight: 500 }}
                        >
                          Named Entities
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                          {selectedMedia?.branches?.map((branch, index) => (
                            <Chip
                              key={index}
                              label={branch.branch}
                              sx={{
                                cursor: "pointer",
                                fontSize: "14px !important",
                                padding: "0 !important",
                                color: "#fff",
                                "&:hover": {
                                  backgroundColor: "#b6877a",
                                },
                              }}
                              style={{
                                backgroundColor:
                                  branch.branch === selectedBranch?.branch
                                    ? "#64bcd2"
                                    : "",
                              }}
                              onClick={() => handleEntityClick(branch)}
                            />
                          ))}
                        </Box>
                        {TimeLine(selectedBranch)}
                      </Grid>
                    </TabPanel>
                  </Grid>

                  {/* Keywords */}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </TabPanel2>

      {/* <TabPanel2 value={mainTab} index={1}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={12}>
            <Box
              className="box-container"
              sx={{ height: "calc(100vh - 180px)" }}
            >
              {formType === "work_permit" && (
                <>
                  <Typography variant="body1" component="label">
                    Select User
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                  <Select
                    options={users}
                    value={selectedUser}
                    name="user"
                    onChange={(e) => {
                      setSelectedUser(e);
                      setErrors((prevErrors) => {
                        const newErrors = { ...prevErrors };
                        delete newErrors["user"];
                        return newErrors;
                      });
                    }}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                    placeholder="Select Users"
                  />
                  {errors["user"] && (
                    <div className="form-error-message">{errors["user"]}</div>
                  )}
                  <br />
                </>
              )}

              <form ref={formRef} onSubmit={handleSubmit}>
                {formSections &&
                  formSections.map((section) => (
                    <Accordion key={section.id} defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="expand-icon" />}
                        className="accordion-summary-background accordion-summary-root"
                      >
                        <Typography className="accordion-summary-typography">
                          {section.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="accordion-details">
                        <Grid container spacing={3}>
                          {section.questions.map((question) => (
                            <Grid
                              item
                              lg={question.width}
                              xs={12}
                              key={question.id}
                            >
                              <Typography variant="body1" component="label">
                                {question.label}
                                {question.required && (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </Typography>

                              {question.type === "Text Field" && (
                                <>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Your answer here"
                                    size="small"
                                    required={question.required}
                                    name={question.id}
                                    id={question.id}
                                    value={imageData[question.label] || ""}
                                    onChange={(e) => {
                                      const { name, value } = e.target;
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors };
                                        delete newErrors[name];
                                        return newErrors;
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value;
                                        return newData;
                                      });
                                    }}
                                    error={!!errors[question.id]}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "CheckBox" && (
                                <>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={question.id}
                                        id={question.id}
                                        value={imageData[question.label]}
                                        onChange={(e) => {
                                          const { name, checked } = e.target; // Get the name and value of the input field
                                          // Update the errors state to remove the error for the current question
                                          setErrors((prevErrors) => {
                                            const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                            delete newErrors[name]; // Remove the error for the specific question using its name
                                            return newErrors; // Return the updated errors object
                                          });

                                          setImageData((prevData) => {
                                            const newData = { ...prevData };
                                            newData[question.label] = checked;
                                            return newData;
                                          });
                                        }}
                                      />
                                    }
                                    sx={{ fontWeight: 600 }}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "Number" && (
                                <>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter a number"
                                    size="small"
                                    required={question.required}
                                    name={question.id}
                                    id={question.id}
                                    value={imageData[question.label] || ""}
                                    onChange={(e) => {
                                      const { name, value } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[name]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value;
                                        return newData;
                                      });
                                    }}
                                    error={!!errors[question.id]}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "Date & Time" && (
                                <>
                                  <TextField
                                    type="datetime-local"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    required={question.required}
                                    name={question.id}
                                    id={question.id}
                                    value={imageData[question.label] || ""}
                                    onChange={(e) => {
                                      const { name, value } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[name]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value;
                                        return newData;
                                      });
                                    }}
                                    error={!!errors[question.id]}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "Time" && (
                                <>
                                  <TextField
                                    type="time"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    required={question.required}
                                    name={question.id}
                                    id={question.id}
                                    value={imageData[question.label] || ""}
                                    onChange={(e) => {
                                      const { name, value } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[name]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value;
                                        return newData;
                                      });
                                    }}
                                    error={!!errors[question.id]}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "Date" && (
                                <>
                                  <TextField
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    required={question.required}
                                    name={question.id}
                                    id={question.id}
                                    value={imageData[question.label] || ""}
                                    onChange={(e) => {
                                      const { name, value } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[name]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value;
                                        return newData;
                                      });
                                    }}
                                    error={!!errors[question.id]}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "Location" && (
                                <>
                                  <Autocomplete
                                    className="project-location-autocomplete"
                                    freeSolo
                                    onInputChange={(event, values) => {
                                      handleAutocompleteChange(event, values);
                                    }}
                                    value={imageData[question.label] || ""}
                                    onChange={(e, value) => {
                                      const { name } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[name]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value.label;
                                        return newData;
                                      });
                                    }}
                                    options={locationOption}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label=""
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        name={question.id}
                                        id={question.id}
                                        placeholder="Please, Enter location"
                                        error={!!errors[question.id]}
                                      />
                                    )}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}

                              {question.type === "Media" && (
                                <>
                                  <Button
                                    variant="outlined"
                                    component="label"
                                    startIcon={<PhotoCamera />}
                                    sx={{
                                      mt: 1,
                                      mb: 2,
                                      color: "#036185",
                                      width: "100% ",
                                    }}
                                  >
                                    Upload Images
                                    <input
                                      type="file"
                                      accept="image/*"
                                      hidden
                                      name={question.id}
                                      id={question.id}
                                      multiple // Allow multiple files
                                      onChange={(e) => {
                                        const files = e.target.files;
                                        if (Array.from(files)?.length > 0) {
                                          setErrors((prevErrors) => {
                                            const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                            delete newErrors[question.id]; // Remove the error for the specific question using its name
                                            return newErrors; // Return the updated errors object
                                          });
                                        }
                                        const imageList = Array.from(files).map(
                                          (file) => {
                                            const reader = new FileReader();
                                            return new Promise((resolve) => {
                                              reader.onloadend = () => {
                                                resolve({
                                                  base64: reader.result,
                                                  extension:
                                                    file.type.split("/")[1], // Get the file extension
                                                });
                                              };
                                              reader.readAsDataURL(file);
                                            });
                                          }
                                        );

                                        Promise.all(imageList).then(
                                          (images) => {
                                            setImageData((prevData) => {
                                              const newData = { ...prevData };
                                              newData[question.label] = images;
                                              return newData;
                                            });
                                          }
                                        );
                                      }}
                                    />
                                  </Button>
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                  {imageData[question.label] &&
                                    imageData[question.label].length > 0 && (
                                      <Box
                                        sx={{
                                          mt: 2,
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: 2,
                                        }}
                                      >
                                        <Grid container spacing={2}>
                                          {imageData[question.label].map(
                                            (image, index) => (
                                              <Grid item xs={2} key={index}>
                                                <Box
                                                  key={index}
                                                  sx={{
                                                    position: "relative",
                                                    borderRadius: "8px",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  <img
                                                    src={image.base64}
                                                    alt={`Preview ${index + 1}`}
                                                    style={{
                                                      width: "100%", // Set the desired width
                                                      height: "100%",
                                                      objectFit: "cover",
                                                      borderRadius: "8px",
                                                    }}
                                                  />
                                                 
                                                  <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                      const updatedImages =
                                                        imageData[
                                                          question.label
                                                        ].filter(
                                                          (_, i) => i !== index
                                                        );
                                                      setImageData(
                                                        (prevData) => {
                                                          const newData = {
                                                            ...prevData,
                                                          };
                                                          newData[
                                                            question.label
                                                          ] = updatedImages; // Update images after deletion
                                                          return newData;
                                                        }
                                                      );
                                                    }}
                                                    sx={{
                                                      position: "absolute",
                                                      top: 0,
                                                      right: 0,
                                                      backgroundColor:
                                                        "rgba(0, 0, 0, 0.5)",
                                                      color: "white",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(0, 0, 0, 0.8)",
                                                      },
                                                    }}
                                                  >
                                                    <DeleteIcon fontSize="small" />
                                                  </IconButton>
                                                </Box>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Box>
                                    )}
                                </>
                              )}

                              {question.type === "Video" && (
                                <>
                                  <Button
                                    variant="outlined"
                                    component="label"
                                    startIcon={<PhotoCamera />}
                                    sx={{
                                      mt: 1,
                                      mb: 2,
                                      color: "#036185",
                                      width: "100%",
                                    }}
                                  >
                                    Upload Videos
                                    <input
                                      type="file"
                                      accept="video/*"
                                      hidden
                                      name={question.id}
                                      id={question.id}
                                      multiple // Allow multiple videos
                                      onChange={(e) => {
                                        const files = e.target.files;
                                        if (Array.from(files)?.length > 0) {
                                          setErrors((prevErrors) => {
                                            const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                            delete newErrors[question.id]; // Remove the error for the specific question using its name
                                            return newErrors; // Return the updated errors object
                                          });
                                        }

                                        const videoList = Array.from(files).map(
                                          (file) => {
                                            const reader = new FileReader();
                                            return new Promise((resolve) => {
                                              reader.onloadend = () => {
                                                resolve({
                                                  base64: reader.result,
                                                  extension:
                                                    file.type.split("/")[1], // Get the file extension
                                                });
                                              };
                                              reader.readAsDataURL(file);
                                            });
                                          }
                                        );

                                        Promise.all(videoList).then(
                                          (videos) => {
                                            setImageData((prevData) => {
                                              const newData = { ...prevData };
                                              newData[question.label] = videos;
                                              return newData;
                                            });
                                          }
                                        );
                                      }}
                                    />
                                  </Button>
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                  {imageData[question.label] &&
                                    imageData[question.label].length > 0 && (
                                      <Box
                                        sx={{
                                          mt: 2,
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: 2,
                                        }}
                                      >
                                        <Grid container spacing={2}>
                                          {imageData[question.label].map(
                                            (video, index) => (
                                              <Grid item xs={4} key={index}>
                                                <Box
                                                  key={index}
                                                  sx={{
                                                    position: "relative",
                                                    borderRadius: "8px",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  <video
                                                    src={video.base64}
                                                    controls
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      objectFit: "cover",
                                                      borderRadius: "8px",
                                                    }}
                                                  />
                                                  <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                      const updatedVideos =
                                                        imageData[
                                                          question.label
                                                        ].filter(
                                                          (_, i) => i !== index
                                                        );
                                                      setImageData(
                                                        (prevData) => {
                                                          const newData = {
                                                            ...prevData,
                                                          };
                                                          newData[
                                                            question.label
                                                          ] = updatedVideos; // Update videos after deletion
                                                          return newData;
                                                        }
                                                      );
                                                    }}
                                                    sx={{
                                                      position: "absolute",
                                                      top: 0,
                                                      right: 0,
                                                      backgroundColor:
                                                        "rgba(0, 0, 0, 0.5)",
                                                      color: "white",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(0, 0, 0, 0.8)",
                                                      },
                                                    }}
                                                  >
                                                    <DeleteIcon fontSize="small" />
                                                  </IconButton>
                                                </Box>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Box>
                                    )}
                                </>
                              )}

                              {question.type === "Radio Buttons" && (
                                <>
                                  <FormControl
                                    component="fieldset"
                                    fullWidth
                                    required={question.required}
                                  >
                                    <RadioGroup
                                      name={`${question.id}`}
                                      id={question.id}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                      value={
                                        imageData[question.label] ||
                                        question.options[0]
                                      }
                                      defaultValue={question.options[0]}
                                      onChange={(e) => {
                                        setImageData((prevData) => {
                                          const newData = { ...prevData };
                                          newData[question.label] =
                                            e.target.value;
                                          return newData;
                                        });
                                      }}
                                    >
                                      {question.options.map((option, index) => (
                                        <FormControlLabel
                                          key={index}
                                          value={option}
                                          control={<Radio />}
                                          label={option}
                                        />
                                      ))}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              )}

                              {question.type === "Service Unit" && (
                                <>
                                  <Select
                                    options={serviceUnitOptions}
                                    value={serviceUnit}
                                    onChange={(e) => {
                                      setServiceUnit(e);
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[`${question.id}`]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = e;
                                        return newData;
                                      });
                                    }}
                                    name={`${question.id}`}
                                    id={question.id}
                                    styles={customStyles}
                                    placeholder="Select Type"
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </form>
            </Box>
          </Grid>
        </Grid>

        <Box className="footer">
          <Button
            className="secondary-footer-btn"
            onClick={() => navigate("/")}
          >
            Cancel
          </Button>

          <Button className="primary-footer-btn" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </TabPanel2> */}
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "../../styles/subscription.scss";
import { setSnackbar } from "../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getSubscriptionPlanThunk,
  userSignupThunk,
  userSignupOtpVerificationThunk,
} from "../../redux/slices/subscription.slice";
import { PAGE_URL } from "../../constants/pageUrl.constant";
import Enterprice from "../../assets/icons/enterprise.svg";
import ProSub from "../../assets/icons/proSub.svg";
import BasicSub from "../../assets/icons/BasicSub.svg";
import OTPInput from "./OtpInput";
import EmailIcon from "../../utils/icon/EmailIcon";
import Pricingcard from "./Pricingcard";

const steps = ["Sign Up", "Choose Your Plan", "Verify Email"];

const CreateAssistant = () => {
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [subPlan, setSubPlan] = useState([]);
  const [initialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    city: "",
    organization_name: "",
    department: "",
    platform: "",
    planId: location?.state?.id ?? "",
    otp: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [oldShowPassword, setOldShowPassword] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOldShowPassword = () => setOldShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const validationSchema1 = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Please specify password")
      .min(6, "The password should have at least 6 characters"),
    city: Yup.string().required("city is required"),
    organization_name: Yup.string(),
    department: Yup.string(),
    platform: Yup.string(),
  });

  const validationSchema2 = Yup.object({
    planId: Yup.string().required("Please select Plan"),
  });

  const validationSchema3 = Yup.object({
    otp: Yup.string()
      .required("Please specify OTP")
      .max(4, "OTP Must be exactly 4 digits")
      .min(4, "OTP Must be exactly 4 digits"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      activeStep === 0
        ? validationSchema1
        : activeStep === 1
        ? validationSchema2
        : validationSchema3,
    onSubmit: async (values) => {
      if (activeStep === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (activeStep === 1) {
        setIsLoading(true);
        const data = { ...values };
        delete data.planId;
        let response = await dispatch(userSignupThunk(data));
        if (response.payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "Verify Code Has Been Sent successfully",
            })
          );
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setIsLoading(false);
        } else
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: response.payload.message || "Internal server error",
            })
          );
        setIsLoading(false);
      } else {
        setIsLoading(true);
        const data = {
          plan_id: formik.values.planId === "free" ? "" : formik.values.planId,
          is_free_subscription: formik.values.planId === "free" ? true : false,
          email: formik.values.email,
          otp: formik.values.otp,
        };

        let response = await dispatch(userSignupOtpVerificationThunk(data));
        if (response.payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "User Signed Up successfully",
            })
          );
          if (response?.payload?.payment_link) {
            window.location.href = response.payload.payment_link;
          } else {
            Navigate(PAGE_URL.LOGIN);
          }
        } else
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: response.payload.message || "Internal server error",
            })
          );
        setIsLoading(false);
      }
    },
  });

  const BootstrapInput = {
    marginBottom: "20px",
    "& label": {
      width: "100%",
      "&.Mui-focused": {
        color: "#273167",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& .Mui-disabled": {
        backgroundColor: "rgba(39, 49, 103, 0.1)",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#273167 !important",
          borderWidth: "1px !important",
        },
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#273167 !important",
          borderWidth: "1px !important",
        },
      },
    },
  };

  function StarSpan() {
    return <span style={{ color: "red" }}>*</span>;
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const getData = (payload, timeframe) => {
    return payload?.data
      .map((item) => {
        return [
          ...item?.plans?.filter((ele) => ele?.interval === timeframe),
        ].map((ele) => ({
          ...ele,
          planId: item?.id,
          description: item?.description,
          Icon:
            ele?.product === "Enterprise"
              ? Enterprice
              : ele?.product === "Basic"
              ? BasicSub
              : ProSub,
          Features: ele?.functions,
        }));
      })
      .flat();
  };

  const fetchSubscriptionPlan = async () => {
    let response = await dispatch(getSubscriptionPlanThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      const monthlyData = getData(payload, "month");
      const yearlyData = getData(payload, "year");
      setSubPlan([monthlyData, yearlyData]);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchSubscriptionPlan();

    // eslint-disable-next-line
  }, []);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    Navigate("/");
  };

  const gridData = { xs: 12, sm: 12, md: 6, lg: 6 };

  return (
    <>
      <Box
        className={`${
          activeStep === 1
            ? "login-wrapper-subscription-choose-your-plan"
            : "login-wrapper-subscription"
        }  p-5`}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={10} lg={10}>
            {/* <Box sx={{ width: "100%" }}> */}
            <div className="mt mx-auto main-div-assistant">
              <Stepper
                className="media-steps"
                sx={{
                  paddingBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
                activeStep={activeStep}
              >
                {steps.map((label, index) => {
                  return (
                    <Step
                      className={`step-lable ${
                        activeStep === index && activeStep !== 2
                          ? "step-lable-before"
                          : ""
                      }`}
                      key={label}
                      sx={{
                        backgroundColor:
                          activeStep === index
                            ? "#CA9688"
                            : activeStep > index
                            ? "#fff"
                            : "#fff",
                      }}
                    >
                      <StepLabel
                        className={`${
                          activeStep === index
                            ? "step-circle active-step"
                            : "inactive-text"
                        } ${activeStep > index ? "step-circle" : ""} `}
                        sx={{ color: "white !important" }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                lg={11}
                xl={11}
                sx={{ paddingX: "40px !important" }}
              >
                <hr style={{ marginTop: "0px", marginBottom: "40px" }}></hr>
              </Grid>
              <>
                <form onSubmit={formik.handleSubmit}>
                  {activeStep + 1 === 1 ? (
                    <Grid
                      container
                      spacing={4}
                      sx={{ justifyContent: "center" }}
                    >
                      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            {...gridData}
                            className="margin-bottom-input"
                          >
                            <Box
                              variant="div"
                              component="div"
                              sx={BootstrapInput}
                            >
                              <Typography
                                color="#0D333F"
                                variant="body1"
                                component="label"
                              >
                                First Name{StarSpan()}
                              </Typography>
                              <TextField
                                fullWidth
                                id="first_name"
                                name="first_name"
                                label=""
                                variant="outlined"
                                placeholder="Enter First Name"
                                size="small"
                                {...formik.getFieldProps("first_name")}
                              />
                              {formik.touched.first_name &&
                                formik.errors.first_name && (
                                  <div className="form-error-message">
                                    {formik.errors.first_name}
                                  </div>
                                )}
                            </Box>
                          </Grid>
                          <Grid
                            {...gridData}
                            item
                            className="margin-bottom-input"
                          >
                            <Box
                              variant="div"
                              component="div"
                              sx={BootstrapInput}
                            >
                              <Typography
                                color="#0D333F"
                                variant="body1"
                                component="label"
                              >
                                Last Name{StarSpan()}
                              </Typography>
                              <TextField
                                fullWidth
                                id="last_name"
                                name="last_name"
                                label=""
                                variant="outlined"
                                placeholder="Enter Last Name"
                                size="small"
                                {...formik.getFieldProps("last_name")}
                              />
                              {formik.touched.last_name &&
                                formik.errors.last_name && (
                                  <div className="form-error-message">
                                    {formik.errors.last_name}
                                  </div>
                                )}
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            {...gridData}
                            className="margin-bottom-input"
                          >
                            <Box
                              variant="div"
                              component="div"
                              sx={BootstrapInput}
                            >
                              <Typography
                                color="#0D333F"
                                variant="body1"
                                component="label"
                              >
                                Email{StarSpan()}
                              </Typography>
                              <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label=""
                                variant="outlined"
                                placeholder="Enter Email"
                                size="small"
                                {...formik.getFieldProps("email")}
                              />
                              {formik.touched.email && formik.errors.email && (
                                <div className="form-error-message">
                                  {formik.errors.email}
                                </div>
                              )}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            {...gridData}
                            className="margin-bottom-input"
                          >
                            <Box
                              variant="div"
                              component="div"
                              sx={BootstrapInput}
                            >
                              <Typography
                                color="#0D333F"
                                variant="body1"
                                component="label"
                              >
                                Password{StarSpan()}
                              </Typography>
                              <OutlinedInput
                                id="outlined-email"
                                type={oldShowPassword ? "text" : "password"}
                                fullWidth
                                name="password"
                                label=""
                                variant="outlined"
                                placeholder="Enter Password"
                                size="small"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickOldShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {!oldShowPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility sx={{ fill: "#273167" }} />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                {...formik.getFieldProps("password")}
                              />
                              {formik.touched.password &&
                                formik.errors.password && (
                                  <div className="form-error-message">
                                    {formik.errors.password}
                                  </div>
                                )}
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            {...gridData}
                            className="margin-bottom-input"
                          >
                            <Box
                              variant="div"
                              component="div"
                              sx={BootstrapInput}
                            >
                              <Typography
                                color="#0D333F"
                                variant="body1"
                                component="label"
                              >
                                City{StarSpan()}
                              </Typography>
                              <TextField
                                fullWidth
                                id="city"
                                name="city"
                                label=""
                                variant="outlined"
                                placeholder="Enter City"
                                size="small"
                                {...formik.getFieldProps("city")}
                              />
                              {formik.touched.city && formik.errors.city && (
                                <div className="form-error-message">
                                  {formik.errors.city}
                                </div>
                              )}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            {...gridData}
                            className="margin-bottom-input"
                          >
                            <Box
                              variant="div"
                              component="div"
                              sx={BootstrapInput}
                            >
                              <Typography
                                color="#0D333F"
                                variant="body1"
                                component="label"
                              >
                                Organization Name
                              </Typography>
                              <TextField
                                fullWidth
                                id="organization_name"
                                name="organization_name"
                                label=""
                                variant="outlined"
                                placeholder="Enter Organization Name"
                                size="small"
                                {...formik.getFieldProps("organization_name")}
                              />
                              {formik.touched.organization_name &&
                                formik.errors.organization_name && (
                                  <div className="form-error-message">
                                    {formik.errors.organization_name}
                                  </div>
                                )}
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            {...gridData}
                            className="margin-bottom-input"
                          >
                            <Box
                              variant="div"
                              component="div"
                              sx={BootstrapInput}
                            >
                              <Typography
                                color="#0D333F"
                                variant="body1"
                                component="label"
                              >
                                Department
                              </Typography>
                              <TextField
                                fullWidth
                                id="department"
                                name="department"
                                label=""
                                variant="outlined"
                                placeholder="Enter Department"
                                size="small"
                                {...formik.getFieldProps("department")}
                              />
                              {formik.touched.department &&
                                formik.errors.department && (
                                  <div className="form-error-message">
                                    {formik.errors.department}
                                  </div>
                                )}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            {...gridData}
                            className="margin-bottom-input"
                          >
                            <Box
                              variant="div"
                              component="div"
                              sx={BootstrapInput}
                            >
                              <Typography
                                color="#0D333F"
                                variant="body1"
                                component="label"
                              >
                                Platform
                              </Typography>
                              <TextField
                                fullWidth
                                id="platform"
                                name="platform"
                                label=""
                                variant="outlined"
                                placeholder="Enter Platform"
                                size="small"
                                {...formik.getFieldProps("platform")}
                              />
                              {formik.touched.platform &&
                                formik.errors.platform && (
                                  <div className="form-error-message">
                                    {formik.errors.platform}
                                  </div>
                                )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : activeStep + 1 === 2 ? (
                    <>
                      <Grid
                        className="sub-tab"
                        margin={"auto"}
                        container
                        spacing={2}
                        sx={{ justifyContent: "center" }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Box>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                              justifyContent="center"
                              className="tab_buttons"
                            >
                              <Tab
                                label="Monthly"
                                className="monthly_plan"
                                {...a11yProps(0)}
                              />
                              <Tab
                                label="Annually"
                                className="annual_plan"
                                {...a11yProps(1)}
                              />
                            </Tabs>
                          </Box>
                          <CustomTabPanel
                            className="sub-box"
                            value={value}
                            index={0}
                          >
                            <Grid
                              container
                              spacing={2}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Pricingcard
                                subPlan={subPlan}
                                value={value}
                                location={location.pathname}
                                formik={formik}
                                plan_id={formik.values.planId}
                              />
                            </Grid>
                          </CustomTabPanel>
                          <CustomTabPanel value={value} index={1}>
                            <Grid
                              container
                              spacing={2}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Pricingcard
                                subPlan={subPlan}
                                // planId={formik.values.planId}
                                value={value}
                                location={location.pathname}
                                formik={formik}
                                plan_id={formik.values.planId}
                              />
                            </Grid>
                          </CustomTabPanel>
                        </Box>
                      </Grid>
                    </>
                  ) : activeStep + 1 === 3 ? (
                    <Grid
                      container
                      spacing={4}
                      sx={{ justifyContent: "center" }}
                    >
                      <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                        <Grid item className="margin-bottom-input">
                          <Box
                            variant="div"
                            component="div"
                            sx={BootstrapInput}
                            justifyContent={"center"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                          >
                            <Box
                              backgroundColor="#F1FBFF"
                              borderRadius="20px"
                              padding="5px"
                              width="fit-content"
                            >
                              <EmailIcon />
                            </Box>
                            <Grid
                              container
                              spacing={4}
                              sx={{ justifyContent: "center", margin: "20px" }}
                            >
                              <Grid
                                item
                                md={6}
                                lg={6}
                                xl={6}
                                padding={"0px !important"}
                              >
                                <Typography
                                  color="#2e2c50 !important"
                                  textAlign={"center"}
                                >
                                  An email with a verification code has been
                                  sent to your email
                                </Typography>
                              </Grid>
                            </Grid>
                            <OTPInput name={"otp"} formik={formik} />
                          </Box>
                          {formik.touched.otp && formik.errors.otp && (
                            <div
                              className="form-error-message"
                              style={{ textAlign: "center" }}
                            >
                              {formik.errors.otp}
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Box
                    className="sub-button"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                      justifyContent: "center",
                    }}
                  >
                    {activeStep !== 0 && (
                      <Button
                        onClick={handleCancel}
                        sx={{
                          padding: "8px 32px !important",
                          borderRadius: "5px",
                          minWidth: "auto",
                          backgroundColor: "#fff",
                          color: "#036185 !important",
                          border: 1,
                          borderColor: "#273167",
                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#273167",
                            border: 1,
                            borderColor: "#273167",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      onClick={activeStep === 0 ? handleCancel : handleBack}
                      sx={{
                        padding: "8px 32px !important",
                        borderRadius: "5px",
                        marginLeft: "15px",
                        minWidth: "auto",
                        backgroundColor: "#fff",
                        color: "#036185 !important",
                        border: 1,
                        borderColor: "#273167",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#273167",
                          border: 1,
                          borderColor: "#273167",
                        },
                      }}
                    >
                      {activeStep === 0 ? "Cancel" : "Back"}
                    </Button>

                    <Button
                      type="submit"
                      disabled={
                        (activeStep + 1 === 2 && !formik.values.planId) ||
                        isLoading
                      }
                      sx={{
                        padding: "8px 32px !important",
                        borderRadius: "5px",
                        marginLeft: "15px",
                        backgroundColor: "#036185",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#273167",
                          border: 1,
                          borderColor: "#273167",
                        },
                      }}
                      className={`${
                        (activeStep + 1 === 2 && !formik.values.planId) ||
                        isLoading
                          ? "disablestep"
                          : ""
                      }`}
                    >
                      {isLoading && (
                        <CircularProgress
                          size="1rem"
                          sx={{
                            mr: 1,
                            zIndex: 1,
                            position: "absolute",
                            color: "#000",
                          }}
                        />
                      )}
                      {activeStep + 1 !== 3 ? "Next" : "Verify Now"}
                    </Button>
                  </Box>
                </form>
              </>
            </div>
            {/* </Box> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateAssistant;

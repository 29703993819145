import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";

import WorkPermitList from "./WorkPermitsList";
import {
  setHeaderTitle,
  setSnackbar,
} from "../../../redux/slices/common.slice";
import { getOneUserThunk } from "../../../redux/slices/tenant.slice";
import CreateEmployees from "./createEmployee";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { JobTitle } from "../../../utils/icon/CommonFunctions";
import TaskList from "./taskList";
import EmployeeTrainingList from "./TrainingList";
const TabOptions = ["Employee Details", "Work Permits", "Task", "Training"];
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function EmployeeView() {
  const location = useLocation();
  const state = location.state;

  const [value, setValue] = useState(state?.tab || 0);
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState();
  const [isOpenEditFormDrawer, setIsOpenEditFormDrawer] = useState(false);
  const [token, setToken] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { empId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function formatUSPhoneNumber(phoneNumber) {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumber;
  }

  const fetchEmployeeData = async () => {
    setLoading(true);
    try {
      let response = await dispatch(getOneUserThunk({ id: empId }));
      const { payload } = response;
      if (payload && payload?.status) {
        const data = {
          first_name: payload?.data?.first_name,
          last_name: payload?.data?.last_name,
          email: payload?.data?.email,
          password: payload?.data?.password,

          id: payload?.data?.id,
          phone_number: payload?.data?.phone_number
            ? formatUSPhoneNumber(payload?.data?.phone_number)
            : "",
          emergency_phone_number: payload?.data?.emergency_phone_number
            ? formatUSPhoneNumber(payload?.data?.emergency_phone_number)
            : "",
          emergency_first_name: payload?.data?.emergency_first_name,
          emergency_last_name: payload?.data?.emergency_last_name,
          user_role: payload?.data?.user_role || "",
          prefer_language: payload?.data?.prefer_language,
          system_role: payload?.data?.system_role === "admin" ? true : false,
          city: payload?.data?.city,
          state: payload?.data?.state,
          profile_picture: payload?.data?.profile_picture,
          additional_documents: payload?.data?.additional_documents,
          date_of_birth: payload?.data?.date_of_birth,
          is_active: payload?.data?.is_active,
        };
        setToken(payload?.token);
        setEmployeeData(data);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (employeeData) dispatch(setHeaderTitle(employeeData?.first_name));
    else {
      dispatch(setHeaderTitle(""));
    }
  }, [employeeData]);

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        mb={2}
      >
        <Tabs
          value={value}
          className="configure-action-tab"
          onChange={handleChange}
          aria-label="Free form assistants"
        >
          {employeeData?.is_active &&
            TabOptions?.map((item, index) => (
              <Tab label={item} {...a11yProps(index)} />
            ))}
          {!employeeData?.is_active &&
            ["Employee Details"]?.map((item, index) => (
              <Tab label={item} {...a11yProps(index)} />
            ))}
        </Tabs>
        {value === 0 && employeeData?.is_active && (
          <Button
            variant="contained"
            size="small"
            className="primary-button"
            disableRipple
            style={{ height: "44px", minWidth: "44px", marginLeft: "auto" }}
            onClick={() => {
              setIsOpenEditFormDrawer(true);
            }}
          >
            <EditIcon sx={{ fontSize: "22px" }} />
          </Button>
        )}
      </Box>
      {loading && (
        <Box
          sx={{
            height: "calc(100vh - 200px)",
          }}
          className="center"
        >
          <CircularProgress />
        </Box>
      )}

      {!loading && value === 0 && (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={4}>
            <Box
              className="box-container"
              sx={{ height: "calc(100vh - 180px)" }}
            >
              <Grid container spacing={2} justifyContent="center">
                {/* Profile Picture */}
                <Grid item xs={12} textAlign="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "40px" }}
                  >
                    <Typography
                      sx={{
                        color: "#00446B",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      Profile Picture
                    </Typography>
                  </Box>
                  <Box
                    mt={1}
                    sx={{
                      overflow: "hidden",
                      display: "inline-flex", // keeps image centered in the box}}>
                    }}
                  >
                    <Avatar
                      src={`${employeeData?.profile_picture?.img_url}?${token}`}
                      sx={{
                        width: 120,
                        height: 120,
                        border: "2px solid #ddd",

                        backgroundColor: "#64bcd2",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Typography
                    sx={{
                      color: "#00446B",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Documents
                  </Typography>
                  {employeeData?.additional_documents?.length > 0 ? (
                    <>
                      <List sx={{ mt: 0 }}>
                        {employeeData?.additional_documents.map(
                          (file, index) => (
                            <ListItem key={index}>
                              <ListItemIcon>
                                <InsertDriveFileIcon />
                              </ListItemIcon>
                              {file.url ? (
                                <Link
                                  to={`${file.url}?${token}`}
                                  target="_blank"
                                >
                                  <ListItemText primary={file.file_name} />
                                </Link>
                              ) : (
                                <ListItemText primary={file.file_name} />
                              )}
                            </ListItem>
                          )
                        )}
                      </List>
                    </>
                  ) : (
                    <Typography>-</Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box
              className="box-container"
              sx={{ height: "calc(100vh - 180px)" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    First Name
                  </Typography>
                  <TextField
                    disabled={true}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={employeeData?.first_name || "-"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    size="small"
                    value={employeeData?.last_name || "-"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    size="small"
                    value={employeeData?.email || "-"}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    City
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    id="city"
                    label=""
                    variant="outlined"
                    size="small"
                    value={employeeData?.city || "-"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    State
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    size="small"
                    value={employeeData?.state || "-"}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    Contact number
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    size="small"
                    // type="number"
                    value={employeeData?.phone_number || "-"}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    User role
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    size="small"
                    // type="number"
                    value={
                      employeeData?.user_role != null
                        ? JobTitle[employeeData?.user_role]
                        : "-"
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    Date Of Birth
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    value={employeeData?.date_of_birth || "-"}
                    type="date"
                    variant="outlined"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    User Prefered Language
                  </Typography>
                  <TextField
                    disabled={true}
                    fullWidth
                    variant="outlined"
                    value={employeeData?.prefer_language || "-"}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    Emergency first name
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={"true"}
                    value={employeeData?.emergency_first_name || "-"}
                    variant="outlined"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    Emergency last name
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    value={employeeData?.emergency_last_name || "-"}
                    variant="outlined"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    Emergency contact number
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    size="small"
                    value={employeeData?.emergency_phone_number || "-"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body1" component="label">
                    Is Admin?
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isAdmin" // Unique name for this checkbox
                        checked={employeeData?.system_role} // Assume `isAdmin` is a state variable that stores whether the user is an admin
                      />
                    }
                    // label="Is Admin?" // Label for the checkbox
                    labelPlacement="start"
                    sx={{ fontWeight: "bold", marginLeft: "2px" }}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {!loading && value === 1 && (
        <WorkPermitList isActive={employeeData?.is_active} />
      )}
      {!loading && value === 2 && (
        <TaskList isActive={employeeData?.is_active} />
      )}
      {!loading && value === 3 && (
        <EmployeeTrainingList isActive={employeeData?.is_active} />
      )}
      {isOpenEditFormDrawer && (
        <CreateEmployees
          drawerState={"edit"}
          setIsOpenDrawer={setIsOpenEditFormDrawer}
          isOpenDrawer={isOpenEditFormDrawer}
          fetchAllUsers={fetchEmployeeData}
          selectedRow={employeeData}
          token={token}
        />
      )}
    </Box>
  );
}

import { API_URL } from "../../constants/apiUrl.constant";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createForm300 = createAsyncThunk(
  "createForm300",
  async ({ data, key, title, description }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM300}?generate=${key}`,
        { form300Data: data, title: title, description: description },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateForm300 = createAsyncThunk(
  "updateForm300",
  async ({ data, title, description, isFormChanged, form300Id }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM300}/${form300Id}`,
        {
          form300Data: data,
          title: title,
          description: description,
          json_changed: isFormChanged,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllForm300 = createAsyncThunk("AllForm300", async (payload) => {
  console.log(payload);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_FORM300}`,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage?.getItem("user")).access_token
          }`,
        },
        params: payload,
      }
    );
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
});
export const getForm300ById = createAsyncThunk(
  "Form300ById",
  async ({ page_number, page_size, form300Id }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM300}/${form300Id}?page_number=${page_number}&page_size=${page_size}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const createForm301 = createAsyncThunk(
  "createForm301",
  async ({ data, key, title, description }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM301}?generate=${key}`,
        { form301Data: data, title: title, description: description },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateForm301 = createAsyncThunk(
  "updateForm301",
  async ({ data, title, description, isFormChanged, form301Id, key }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM301}/${form301Id}?generate=${key}`,
        {
          form301Data: data,
          title: title,
          description: description,
          json_changed: isFormChanged,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllForm301 = createAsyncThunk("AllForm300", async (payload) => {
  console.log(payload);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_FORM301}`,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage?.getItem("user")).access_token
          }`,
        },
        params: payload,
      }
    );
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
});
export const getForm301ById = createAsyncThunk(
  "Form301ById",
  async ({ form301Id }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM301}/${form301Id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getForm301DataById = createAsyncThunk(
  "Form301ByReportId",
  async ({ report_id }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM301_BY_REPORTID}/${report_id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteForm300 = createAsyncThunk(
  "deleteForm300",
  async ({ formId }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM300}/${formId}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteForm301 = createAsyncThunk(
  "deleteFormI301",
  async ({ id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM301}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Assistants slice
const form300Slice = createSlice({
  name: "form300",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {},
});

export default form300Slice.reducer;

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  generateQR,
  getServiceUnitById,
} from "../../../redux/slices/serviceUnit.slice";
import {
  setHeaderTitle,
  setSnackbar,
} from "../../../redux/slices/common.slice";
import ActionList from "./inspectionList";
import TaskList from "./taskList";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import InspectionScheduleList from "./inspectionScheduleList";
const TabOptions = [
  "General Information",
  "Inspection",
  "Tasks",
  "Inspection Schedule",
];
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ViewServiceUnit() {
  const location = useLocation();
  const state = location.state;

  const [value, setValue] = useState(state?.tab || 0);
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [type, setType] = useState("");
  const [token, setToken] = useState("");
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [qr, setQr] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serviceUnitId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchServiceUnitDetailsById = async () => {
    setLoading(true);

    try {
      let response = await dispatch(
        getServiceUnitById({
          id: serviceUnitId,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setDisplayName(payload?.data?.name);
        setToken(payload?.token);
        setSelectedQuestions(payload?.data?.service_unit_details_json);
        setType(payload?.data?.type?.name);
        setAttachments(payload?.data?.documents);
        setProfilePicFile(
          `${payload?.data?.profile_picture?.img_url}?${payload?.token}`
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    if (displayName) dispatch(setHeaderTitle(displayName));
    else {
      dispatch(setHeaderTitle(""));
    }
  }, [displayName]);

  const fetchQR = async () => {
    setLoading(true);

    try {
      let response = await dispatch(
        generateQR({
          id: serviceUnitId,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setQr(payload.data);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchServiceUnitDetailsById();
    fetchQR();
  }, []);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `data:image/png;base64,${qr}`;
    link.download = "QRCode.png";
    link.click();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        mb={2}
      >
        <Tabs
          value={value}
          className="configure-action-tab"
          onChange={handleChange}
          aria-label="Free form assistants"
        >
          {TabOptions?.map((item, index) => (
            <Tab label={item} {...a11yProps(index)} />
          ))}
        </Tabs>
        {value === 0 && (
          <Button
            variant="contained"
            size="small"
            className="primary-button"
            disableRipple
            style={{ height: "44px", minWidth: "44px", marginLeft: "auto" }}
            onClick={() => navigate(`/service-unit/${serviceUnitId}/edit`)}
          >
            <EditIcon sx={{ fontSize: "22px" }} />
          </Button>
        )}
      </Box>
      {loading && (
        <Box
          sx={{
            height: "calc(100vh - 200px)",
          }}
          className="center"
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && value === 0 && (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={4}>
            <Box
              className="box-container"
              sx={{ height: "calc(100vh - 180px)" }}
            >
              <Grid container spacing={2} justifyContent="center">
                {/* Profile Picture */}
                <Grid item xs={6} textAlign="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "40px" }}
                  >
                    <Typography
                      sx={{
                        color: "#00446B",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      Profile Picture
                    </Typography>
                  </Box>
                  <Box
                    mt={1}
                    sx={{
                      overflow: "hidden",
                      display: "inline-flex", // keeps image centered in the box}}>
                    }}
                  >
                    <Avatar
                      src={`${profilePicFile}`}
                      sx={{
                        width: 120,
                        height: 120,
                        border: "2px solid #ddd",

                        backgroundColor: "#64bcd2",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} textAlign="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "40px" }}
                  >
                    <Typography
                      sx={{
                        color: "#00446B",
                        fontSize: "18px",
                        fontWeight: "500",
                        mr: 1,
                      }}
                    >
                      QR Code
                    </Typography>
                    <Tooltip title="Print">
                      <IconButton
                        onClick={handleDownload}
                        sx={{
                          color: "white",
                          bgcolor: "#00446B", // Background color for the button
                          borderRadius: "50%", // Makes it a perfect circle
                          width: 30, // Sets width and height for the circle
                          height: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover": {
                            bgcolor: "#003356", // Darker shade on hover
                          },
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box
                    mt={1}
                    sx={{
                      overflow: "hidden",
                      display: "inline-flex", // keeps image centered in the box}}>
                    }}
                  >
                    <Avatar
                      src={`data:image/png;base64,${qr}`}
                      sx={{
                        width: 120,
                        height: 120,
                        border: "2px solid #ddd",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  {attachments?.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          color: "#00446B",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Documents
                      </Typography>
                      <List sx={{ mt: 0 }}>
                        {attachments.map((file, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <InsertDriveFileIcon />
                            </ListItemIcon>
                            {file.url ? (
                              <Link to={`${file.url}?${token}`} target="_blank">
                                <ListItemText primary={file.file_name} />
                              </Link>
                            ) : (
                              <ListItemText primary={file.file_name} />
                            )}
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box
              className="box-container"
              sx={{ height: "calc(100vh - 180px)" }}
            >
              {/* <Box sx={{ flex: 1, overflowY: "auto", padding: "24px" }}> */}
              <Grid container spacing={2} className="form-disabled">
                <Grid item xs={6}>
                  <Typography variant="body1" component="label">
                    Display Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Enter Display Name"
                    value={displayName}
                    name={"displayName"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" component="label">
                    Service Unit Type
                  </Typography>

                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Enter Type"
                    value={type}
                    name={"type"}
                  />
                </Grid>
                {selectedQuestions?.map((question) => (
                  <Grid item xs={question.width} key={question.id}>
                    <Typography variant="body1" component="label">
                      {question.label}
                    </Typography>

                    {question.type === "Text Field" && (
                      <>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Your answer here"
                          required={question.required}
                          name={question.id}
                          value={question.answer || ""}
                        />
                      </>
                    )}

                    {question.type === "Date" && (
                      <>
                        <TextField
                          type="date"
                          fullWidth
                          variant="outlined"
                          size="small"
                          required={question.required}
                          value={question.answer || ""}
                          name={question.id}
                        />
                      </>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}

      {!loading && value === 1 && <ActionList />}
      {!loading && value === 2 && <TaskList />}
      {!loading && value === 3 && <InspectionScheduleList />}
    </Box>
  );
}

import { API_URL } from "../../constants/apiUrl.constant";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createServiceUnitType = createAsyncThunk(
  "createServiceUnitType",
  async ({ data }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_TYPE}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateServiceUnitType = createAsyncThunk(
  "updateServiceUnitType",
  async ({ data, id }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_TYPE}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllServiceUnitType = createAsyncThunk(
  "getAllServiceUnitType",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_TYPE}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const generateQR = createAsyncThunk("generateQR", async ({ id }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${API_URL.GENERATE_QR}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage?.getItem("user")).access_token
          }`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
});
export const deleteServiceUnitType = createAsyncThunk(
  "deleteServiceUnitType",
  async ({ data, id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_TYPE}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getServiceUnitTypeById = createAsyncThunk(
  "getServiceUnitTypeById",
  async ({ id }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_TYPE}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const createServiceUnit = createAsyncThunk(
  "createServiceUnit",
  async ({ data }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateServiceUnit = createAsyncThunk(
  "updateServiceUnit",
  async ({ data, id }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllServiceUnit = createAsyncThunk(
  "getAllServiceUnit",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const deleteServiceUnit = createAsyncThunk(
  "deleteServiceUnit",
  async ({ data, id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getServiceUnitById = createAsyncThunk(
  "getServiceUnitById",
  async ({ id }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getScheduledInspection = createAsyncThunk(
  "getScheduledInspection",
  async ({ id }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_SCHEDULED_INSPECTION}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createServiceUnitTask = createAsyncThunk(
  "createServiceUnitTask",
  async ({ data }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT}/assign/inspection`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateServiceUnitTask = createAsyncThunk(
  "updateServiceUnitTask",
  async ({ data, id }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_TASK}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllServiceUnitTask = createAsyncThunk(
  "getAllServiceUnitTask",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_TASK}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const deleteServiceUnitTask = createAsyncThunk(
  "deleteServiceUnitTask",
  async ({ id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_TASK}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const createServiceUnitSchedule = createAsyncThunk(
  "createServiceUnitSchedule",
  async ({ data }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_INSPECTION_SCHEDULE}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateServiceUnitSchedule = createAsyncThunk(
  "updateServiceUnitSchedule",
  async ({ data, id }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_INSPECTION_SCHEDULE}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllServiceUnitSchedule = createAsyncThunk(
  "getAllServiceUnitSchedule",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_INSPECTION_SCHEDULE}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const deleteServiceUnitSchedule = createAsyncThunk(
  "deleteServiceUnitSchedule",
  async ({ id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_INSPECTION_SCHEDULE}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Assistants slice
const ServiceUnitSlice = createSlice({
  name: "Form",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {},
});

export default ServiceUnitSlice.reducer;

import React, { useState, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Paper,
  Divider,
} from "@mui/material";
import "../../../styles/dashboard.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Selectt from "react-select";
import CustomizedDialog from "../Dialog";
import {
  createServiceUnitTask,
  getAllServiceUnit,
  updateServiceUnitTask,
} from "../../../redux/slices/serviceUnit.slice";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  assignTraining,
  getAllTraining,
  updateUserTraining,
} from "../../../redux/slices/employees.slice";
import logAnalyticsEvent from "../../../firebase/analyticsLogger";
import moment from "moment";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};
const trainingOptions = [
  {
    label: "Effective Communication Skills",
    title: "Effective Communication Skills",
    description:
      "A comprehensive training to enhance interpersonal and professional communication abilities.",
    value: 1,
  },
  {
    label: "Project Management Basics",
    title: "Project Management Basics",
    description:
      "An introductory course covering the fundamental principles of project management.",
    value: 2,
  },
  {
    label: "Introduction to Data Analysis",
    title: "Introduction to Data Analysis",
    description:
      "Learn the basics of data analysis, including tools and techniques for handling data.",
    value: 3,
  },
  {
    label: "Time Management Techniques",
    title: "Time Management Techniques",
    description:
      "Techniques and strategies to optimize productivity through effective time management.",
    value: 4,
  },
  {
    label: "Advanced Excel Training",
    title: "Advanced Excel Training",
    description:
      "An advanced course on Excel features for data management and analytics.",
    value: 5,
  },
  {
    label: "Conflict Resolution Strategies",
    title: "Conflict Resolution Strategies",
    description:
      "Learn strategies to manage and resolve conflicts effectively in professional settings.",
    value: 6,
  },
  {
    label: "Cybersecurity Basics",
    title: "Cybersecurity Basics",
    description:
      "Introduction to cybersecurity principles, including online safety and data protection.",
    value: 7,
  },
  {
    label: "Customer Service Excellence",
    title: "Customer Service Excellence",
    description:
      "Training to enhance customer service skills and improve client satisfaction.",
    value: 8,
  },
  {
    label: "Leadership and Team Building",
    title: "Leadership and Team Building",
    description:
      "Develop leadership qualities and learn techniques for effective team building.",
    value: 9,
  },
];

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),
  menuList: (base) => ({
    ...base,
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};

const CreateTraining = (
  { isOpenDrawer, setIsOpenDrawer, drawerState, values, fetchAlltasks },
  props
) => {
  const [initialValues, setInitialValues] = useState({
    due_date: "",

    training: null,

    description: "",
  });

  const [reviewCompleteOpen, setReviewCompleteOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const validationSchema = yup.object({
    training: yup
      .object()
      .shape({
        value: yup.string().required("Training is required"),
        label: yup.string(),
      })
      .nullable()
      .required("Training is required"),
    description: yup.string().required("Description is required"),
    due_date: yup.date().required("Due Date is required"),
  });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trainingOptions, setTrainingOptions] = useState([]);
  const { empId } = useParams();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (valuess) => {
      setIsLoading(true);

      try {
        let response = await dispatch(
          drawerState === "edit"
            ? updateUserTraining({
                id: values.id,
                data: {
                  description: valuess.description,
                  due_date: valuess.due_date,
                },
              })
            : assignTraining({
                id: valuess.training.value,
                description: valuess.description,
                due_date: valuess.due_date,
                assigned_to: [empId],
              })
        );
        const { payload } = response;
        if (payload && payload.status) {
          if (drawerState === "edit") {
            await dispatch(
              setSnackbar({
                open: true,
                severity: "success",
                message: "Training Details Updated Succesfully.",
              })
            );
          } else {
            await dispatch(
              setSnackbar({
                open: true,
                severity: "success",
                message: "Training Assigned Succesfully.",
              })
            );
          }

          setIsOpenDrawer(false);
          fetchAlltasks();
          formik.resetForm();

          if (drawerState === "create") {
            logAnalyticsEvent("assign_training", {
              user_id: user?.data?.user?.id,
            });
          } else {
            logAnalyticsEvent("update_user_training", {
              user_id: user?.data?.user?.id,
            });
          }
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });

  const handleCloseDrawer = () => {
    formik.resetForm();
    setIsOpenDrawer(false);
  };

  const fetchTrainingOptions = async () => {
    setLoading(true);

    try {
      let response = await dispatch(
        getAllTraining({
          page_number: 1,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setTrainingOptions(
          payload?.data?.map((item) => ({
            ...item,
            value: item?.id,
            label: item?.title,
          }))
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrainingOptions();
  }, []);

  useEffect(() => {
    if (drawerState !== "create") {
      console.log(values);
      formik.setValues({
        due_date: values?.due_date
          ? moment(values.due_date).format("YYYY-MM-DD")
          : null,
        description: values?.description,
        training: {
          label: values?.training?.title,
          value: values?.training?.id,
        },
      });
    }
  }, [drawerState]);
  console.log(formik.values);
  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // slots={{ backdrop: Backdrop }}
        title={
          drawerState === "view"
            ? "Training Detail"
            : drawerState === "create"
            ? "Assign Training"
            : "Edit Training"
        }
        open={isOpenDrawer}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent>
          <form className={drawerState === "view" && "form-disabled"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Training
                  </Typography>
                  <Selectt
                    styles={customStyles}
                    size="small"
                    fullWidth
                    options={trainingOptions}
                    value={formik?.values?.training}
                    onChange={(e) => {
                      formik.setFieldValue("training", e);

                      formik.setFieldValue("description", e?.description);
                    }}
                    classNamePrefix="select"
                    placeholder={"Select Training..."}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isLoading={loading}
                    menuPosition="fixed"
                    menuPlacement="top"
                  ></Selectt>
                  {formik.touched.training && formik.errors.training && (
                    <div className="form-error-message">
                      {formik.errors.training}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Due Date
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    type="date"
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Select Due Date"
                    size="small"
                    name="Due Date"
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      }, // Restrict past dates
                    }}
                    {...formik.getFieldProps("due_date")}
                  />
                  {formik.touched.due_date && formik.errors.due_date && (
                    <div className="form-error-message">
                      {formik.errors.due_date}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    multiline
                    rows="3"
                    variant="outlined"
                    placeholder="Enter Description"
                    size="small"
                    name="description"
                    {...formik.getFieldProps("description")}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="form-error-message">
                      {formik.errors.description}
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {drawerState !== "view" && (
          <DialogActions>
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Save
                </Button>

                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </DialogActions>
        )}
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Update Status ( IN REVIEW => REVIEW COMPLETED )"}
        open={reviewCompleteOpen}
        handleClose={() => {
          setReviewCompleteOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are You Sure You want to mark the <b>{formik?.values?.title}</b>{" "}
              as reviewed and completed?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={() => {
                formik.setFieldValue("clickedButton", "ReviewComplete");
                formik.handleSubmit();
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setReviewCompleteOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default CreateTraining;

export const Status = {
  in_process: {
    color: "red",
    hover: "Pending Assignment",
    label: "IN PROCESS",
  },
  PENDING: { color: "red", hover: "Pending Assignment", label: "PENDING" },
  pending: { color: "red", hover: "Pending Assignment", label: "PENDING" },
  IN_REVIEW: {
    color: "orange",
    hover: "Report Under Review",
    label: "IN REVIEW",
  },
  COMPLETED: { color: "green", hover: "Review Completed", label: "COMPLETED" },
  completed: { color: "green", hover: "Review Completed", label: "COMPLETED" },
  open: { color: "orange", label: "Open" },
  closed: { color: "green", label: "Closed" },
  up_coming: { color: "orange", label: "UPCOMING", hover: "Upcoming" },
  ongoing: { color: "orange", label: "ONGOING", hover: "Ongoing" },
  on_going: { color: "orange", label: "ONGOING", hover: "Ongoing" },
};

export const JobTitle = {
  onsite_engineer: "Onsite Engineer",
  supervisor: "Supervisor",
  director: "Director",
};
//for rich text editor
export const modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline"], // toggled buttons

    [{ list: "ordered" }, { list: "bullet" }],

    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

    [{ color: [] }, { background: [] }],
  ],
};
export const ReportType = {
  hazard: "Hazard",
  incident: "Incident",
  maintenance: "Maintenance",
  general_observation: "General Observation",
  near_miss: "Near Miss",
};

export const ReportStatusOptions = [
  { value: "All", label: "All" },
  { value: "COMPLETED", label: "Completed" },
  { value: "PENDING", label: "Pending" },
  { value: "IN_REVIEW", label: "In Review" },
];

export const FormTypeMapping = {
  employee_induction: "Employee Induction",
  work_permit: "Work Permit",
  inspection: "Inspection",
  audit: "Incident Audit",
  custom: "Custom",
};

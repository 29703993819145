import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";

const TableStyle = (height = "calc(100vh - 300px)") => ({
  "& .MuiDataGrid-root": {
    maxHeight: height, // Setting the max height of the DataGrid
  },
  "& .MuiDataGrid-columnHeaders": {
    position: "sticky",
    top: 0,
    zIndex: 1000, // Ensure it's above other content
    backgroundColor: "#64bcd2",
    color: "#FFFFFF",
    fontSize: "15px",
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      "& .MuiDataGrid-iconButtonContainer": {
        "& svg": {
          fill: "#FFFFFF",
        },
      },
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  "& .MuiDataGrid-cell": {
    py: 2,
    "& .MuiDataGrid-withBorderColor": {
      display: "none !important",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    overflowY: "auto",
    maxHeight: height, // Control the height of the scroll area
    minHeight: "147px",
    "&::-webkit-scrollbar": {
      height: "8px", // Set the height of the horizontal scrollbar
      width: "6px", // Set the width for the vertical scrollbar
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1", // Light gray background for the track
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#64bcd2", // Blue color for the scrollbar
      borderRadius: "10px", // Rounder edges
    },

    "& .MuiDataGrid-row": {
      "&.Mui-hovered": {
        backgroundColor: "rgb(255 115 100 / 5%)",
      },
    },
  },
});

// Custom No Rows Overlay Component
function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%", // Ensure it fills available space
      }}
    >
      <Typography variant="subtitle1" color="textSecondary">
        No data available
      </Typography>
    </Box>
  );
}

const DataGridTable = ({
  data,
  columns,
  totalRow,
  paginationModel,
  setPaginationModel,
  isLoading,
  height,
}) => {
  // Dynamically adjust grid height based on data availability
  const columnsWithNoSorting = columns.map((column) => ({
    ...column,
    sortable: false,
  }));

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#FFFFFF",
        overflow: "auto",
      }}
    >
      <DataGrid
        sx={TableStyle(height)}
        rows={data}
        rowCount={totalRow}
        getRowId={(row) => row.kbId || row.id}
        columns={columnsWithNoSorting}
        hideFooter={paginationModel === undefined ? true : false}
        pageSizeOptions={[5, 10, 20, 50]}
        loading={isLoading}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        getRowHeight={() => "auto"}
        slots={{
          NoRowsOverlay: CustomNoRowsOverlay, // Set custom "No Data" overlay
        }}
      />
    </div>
  );
};

export default DataGridTable;

import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Paper,
  InputBase,
  Stack,
  DialogContent,
  DialogActions,
  AvatarGroup,
  Avatar,
  ListItem,
  List,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Divider,
} from "@mui/material";

import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../../assets/icons/pencil.svg";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import DeleteIcon from "../../../../assets/icons/deleteRed.svg";

import { setSnackbar } from "../../../../redux/slices/common.slice";
import CustomizedDialog from "../../Dialog";

import logAnalyticsEvent from "../../../../firebase/analyticsLogger";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import CreateTraining from "./createTraining";
import Select from "react-select";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  assignTraining,
  deleteTraining,
  getAllTraining,
} from "../../../../redux/slices/employees.slice";
import DataGridTable from "../../dataGrid";
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    boxShadow: theme.shadows[1], // Subtle shadow for a clean look
    fontSize: "1rem", // Slightly larger font
  },
}));

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 170,
    overflowY: "auto",
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};

export default function EmployeeTrainingList() {
  const dispatch = useDispatch();
  const [training, setTraining] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [sortKey, setSortKey] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [openAssignDrawer, setOpenAssignDrawer] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState();
  const user = useSelector((state) => state.user);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");

  const users = useSelector((state) => state.common.users);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [dueDate, setDueDate] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [dueDateError, setDueDateError] = useState();
  const [assignUserError, setAssignUserError] = useState();

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setAssignedUsers(users);
      setAssignUserError();
    }
  };
  const fetchAllTraining = async () => {
    setIsLoading(true);

    try {
      let response = await dispatch(
        getAllTraining({
          page_number: paginationModel.page + 1,
          page_size: paginationModel.pageSize,

          sort: sortKey,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setTraining(payload?.data);
        setTotalRow(payload?.object_count);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAllTraining();
  }, [paginationModel, sortKey]);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        deleteTraining({ id: selectedTraining?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        setOpenDeleteModal(false);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Training Deleted succesfully",
          })
        );
        logAnalyticsEvent("delete_training", {
          user_id: user?.data?.user?.id,
        });
        fetchAllTraining();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssignUsers = async () => {
    if (!assignedUsers || assignedUsers.length === 0) {
      setAssignUserError(
        "Please select at least one user to assign the training."
      );
      return;
    }

    if (!dueDate) {
      setDueDateError("Please select a due date.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await dispatch(
        assignTraining({
          assigned_to: assignedUsers?.map((item) => item.value),
          due_date: dueDate,
          description: selectedTraining?.description,
          id: selectedTraining?.id,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setOpenAssignDrawer(false);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Training Assigned succesfully",
          })
        );
        logAnalyticsEvent("assign_training", {
          user_id: user?.data?.user?.id,
        });
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }
  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        setSortKey(sortKey.substring(1));
      } else {
        setSortKey(`-${key}`);
      }
    }
  };

  const MembersColumn = ({ row }) => {
    return (
      <Box className="custom-avatar">
        <StyledTooltip
          title={
            <List disablePadding>
              {row?.members?.map((member, index) => (
                <ListItem
                  key={index}
                  sx={{
                    fontSize: "0.9rem",
                    padding: "4px 2px",
                    display: "list-item", // Ensures list-style-like behavior
                  }}
                >
                  {index + 1}. {member}
                </ListItem>
              ))}
            </List>
          }
          arrow
          placement="left" // Tooltip opens to the left
        >
          <AvatarGroup max={3}>
            {row.members.map((member, index) => (
              <Avatar key={index} />
            ))}
          </AvatarGroup>
        </StyledTooltip>
      </Box>
    );
  };

  const columns = [
    {
      field: "title",
      headerName: "title",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "title")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "description")}
        >
          Description
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: ({ row }) => {
        return (
          <div>
            {row.description?.length > 140
              ? row.description.slice(0, 140) + "..."
              : row.description}
          </div>
        );
      },
    },
    // {
    //   field: "members",
    //   headerName: "Employees",
    //   flex: 1,
    //   minWidth: 120,
    //   renderHeader: () => (
    //     <div
    //       style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    //       onClick={(e) => handleSorting(e, "description")}
    //     >
    //       Employees
    //       <SwapVertIcon style={{ marginLeft: 4 }} />
    //     </div>
    //   ),
    //   renderCell: ({ row }) => <MembersColumn row={row} />,
    // },

    {
      field: "created_at",
      headerName: "Created At",

      width: 140,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 140,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0} alignItems="center">
            <Tooltip
              title="View"
              onClick={() => {
                setDrawerState("view");
                setSelectedTraining(params.row);
                setIsOpenDrawer(true);
              }}
            >
              <IconButton>
                <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Edit">
              <IconButton
                sx={{ color: "#000000", padding: "0px" }}
                onClick={() => {
                  setDrawerState("edit");
                  setSelectedTraining(params.row);
                  setIsOpenDrawer(true);
                }}
              >
                <img
                  alt="Edit Details"
                  src={Pencil}
                  width={24}
                  height={24}
                  className="actionIcon"
                  cursor={"pointer"}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Assign">
              <IconButton
                onClick={() => {
                  setSelectedTraining(params.row);
                  setOpenAssignDrawer(true);
                }}
              >
                <AssignmentIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                sx={{ color: "#000000", padding: "0" }}
                onClick={() => {
                  setSelectedTraining(params.row);
                  setOpenDeleteModal(true);
                }}
              >
                <img
                  alt="Delete Details"
                  src={DeleteIcon}
                  width={24}
                  height={24}
                  className="actionIcon"
                  cursor={"pointer"}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Box className="content-header kb-list-header mb-4">
          <Box
            variant="div"
            component="div"
            className="content-header-right"
            sx={{ marginLeft: "auto !important" }}
          >
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => {
                setIsOpenDrawer(true);
                setDrawerState("create");
              }}
            >
              Create Training
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? training.filter(
                    (item) =>
                      item.title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      item.description
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
                : training
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>

      {isOpenDrawer && (
        <CreateTraining
          drawerState={drawerState}
          setIsOpenDrawer={setIsOpenDrawer}
          isOpenDrawer={isOpenDrawer}
          values={selectedTraining}
          fetchAlltasks={fetchAllTraining}
        />
      )}

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Assign Employees"}
        height="400px"
        width="700px"
        open={openAssignDrawer}
        handleClose={() => setOpenAssignDrawer(false)}
      >
        <DialogContent>
          <Box sx={{ mb: 4 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
                fontSize: "16px",
              }}
            >
              Assign Employees to <b>{selectedTraining?.title}</b>
            </Paper>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box variant="div" component="div">
                <Typography variant="body1" component="label">
                  Select Employees
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Select All"
                  />
                </Box>
                <Select
                  value={assignedUsers}
                  isMulti
                  onChange={(e) => {
                    setAssignedUsers(e);
                    setAssignUserError();
                    if (e.length !== users.length) {
                      setChecked(false);
                    } else {
                      setChecked(true);
                    }
                  }}
                  options={users}
                  styles={customStyles}
                  placeholder="Select Employees"
                />
              </Box>
              {assignUserError && (
                <div className="form-error-message">{assignUserError}</div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box variant="div" component="div">
                <Typography variant="body1" component="label">
                  Due Date
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Select Due Date"
                  size="small"
                  name="Due Date"
                  value={dueDate}
                  onChange={(e) => {
                    setDueDate(e.target.value);
                    setDueDateError();
                  }}
                  InputProps={{
                    inputProps: {
                      min: new Date().toISOString().split("T")[0],
                    },
                  }}
                />
                {dueDateError && (
                  <div className="form-error-message">{dueDateError}</div>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={handleAssignUsers}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setOpenAssignDrawer(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete <b>{selectedTraining?.title}</b> ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
}

import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFormById } from "../../../../redux/slices/forms.slice";
import "../../../../styles/forms.css";
import { getServiceUnitTypeById } from "../../../../redux/slices/serviceUnit.slice";

export default function FormRenderer({ id = null, questions }) {
  const [formQuestions, setFormQuestions] = useState([]); // Store questions directly
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchFormById = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getServiceUnitTypeById({ id }));
      const { payload } = response;
      if (payload?.status) {
        setFormQuestions(payload?.data?.service_unit_json);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchFormById();
    else {
      setFormQuestions(questions);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            margin: "auto",
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            padding: "24px",
            backgroundColor: "#ffffff",
            border: "1px solid #036185",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* <form onSubmit={handleSubmit}> */}
          <Grid container spacing={3}>
            {formQuestions &&
              formQuestions.map((question) => (
                <Grid item xs={question.width} key={question.id}>
                  <Typography variant="body1" component="label">
                    {question.label}
                    {question.required && (
                      <span style={{ color: "red" }}> *</span>
                    )}
                  </Typography>

                  {/* Render only Text Field and Date Field */}
                  {question.type === "Text Field" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Your answer here"
                      required={question.required}
                      name={question.id} // Use question.id for name
                    />
                  )}

                  {question.type === "Date" && (
                    <TextField
                      type="date"
                      fullWidth
                      variant="outlined"
                      size="small"
                      required={question.required}
                      name={question.id} // Use question.id for name
                    />
                  )}
                </Grid>
              ))}
          </Grid>
          {/* <Box
              sx={{ marginTop: "16px", display: "flex", justifyContent: "end" }}
            >
              <button type="submit">Submit</button>
            </Box>
          </form> */}
        </Box>
      )}
    </>
  );
}

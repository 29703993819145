import {
  Box,
  Button,
  MenuItem,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  Divider,
  Select,
  CircularProgress,
  Stack,
  Paper,
  Tabs,
  Tab,
  Avatar,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Selectt from "react-select";
import CustomizedDialog from "./Dialog";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  setNotificationReportId,
  setSnackbar,
} from "../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import { updateReport } from "../../redux/slices/tenant.slice";
import { sassNull } from "sass";
import { PhotoCamera } from "@mui/icons-material";
import ImageSlider from "./imageSlider";
import logAnalyticsEvent from "../../firebase/analyticsLogger";
import { useSelector } from "react-redux";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const CreateReport = ({
  isLayout,
  setLayout,
  drawerState,
  values,
  fetchAllReport,
  setReportId,
  imageToken,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportStatus, setReportStatus] = useState("PENDING");
  const [reviewCompleteOpen, setReviewCompleteOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.common.users);
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    event_occurred: null,
    injury_description: null,
    classify_of_case: "death",
    away_work_days: null,
    job_transfer_days: null,
    illness: "injury",
    type: "",
    assigned_to: "",

    employees: [],
  });

  const SetReportValues = () => {
    console.log(values?.type, values?.classify_of_case);
    let data = {
      title: values?.title,
      description: values?.description,
      event_occurred: values?.event_occurred || "",
      injury_description: values?.injury_description || null,
      classify_of_case: values?.classify_of_case || "death",
      illness: values?.illness == null ? "injury" : values?.illness,
      away_work_days: values?.number_of_days_injured?.away_work_days,
      job_transfer_days: values?.number_of_days_injured?.job_transfer_days,
      type: values?.type,
      assigned_to: values?.assigned_to?.id,

      employees: values?.employees?.map((emp) => ({
        value: emp.id,
        label: emp.first_name + " " + emp.last_name,
      })),
    };
    setReportStatus(values?.status);
    setInitialValues(data);
    formik.setValues(data);
  };

  const validationSchema = yup.object({
    title: yup.string().required("Please, Enter report title"),
    description: yup.string().required("Please, Enter report description"),
    // event_occurred: yup
    //   .string()
    //   .required("Please,Enter Event occured place information"),
    // injury_description: yup
    //   .string()
    //   .required("Please, Enter injury description"),
    // classify_of_case: yup
    //   .string()
    //   .required("Please, Select classification of case"),
    // illness: yup.string().required("Please, Select illness"),

    // away_work_days: yup.number().when(["classify_of_case", "drawerState"], {
    //   is: (classify_of_case, drawerState) =>
    //     drawerState !== "view" &&
    //     classify_of_case !== "death" &&
    //     classify_of_case !== "job_transfer_or_restriction" &&
    //     classify_of_case !== "other_recordable_case",
    //   then: yup
    //     .number()
    //     .min(0, "Away work days cannot be negative")
    //     .integer("Away work days must be an integer"),
    //   otherwise: yup.number().nullable(),
    // }),
    // job_transfer_days: yup.number().when(["drawerState", "classify_of_case"], {
    //   is: (drawerState, classify_of_case) =>
    //     !(
    //       drawerState === "view" ||
    //       classify_of_case === "death" ||
    //       classify_of_case === "other_recordable_case"
    //     ),
    //   then: yup
    //     .number()
    //     .typeError("Must be a number")
    //     .positive("Must be a positive number"),
    //   otherwise: yup.number().notRequired(),
    // }),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    SetReportValues();
  }, [values]);

  const Status = {
    PENDING: { color: "red", hover: "Pending Assignment" },
    IN_REVIEW: { color: "orange", hover: "Report Under Review" },
    COMPLETED: { color: "green", hover: "Review Completed" },
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (requestValue) => {
      const clickedButtonValue = requestValue.clickedButton;

      setIsLoading(true);

      try {
        let requestPayload = {
          ...requestValue,
          employees: requestValue?.employees?.map((item) => item.value),
          assigned_to:
            requestValue?.assigned_to === initialValues.assigned_to
              ? undefined
              : requestValue?.assigned_to,
          number_of_days_injured: {
            away_work_days:
              requestValue?.away_work_days === ""
                ? null
                : requestValue?.away_work_days,
            job_transfer_days:
              requestValue?.job_transfer_days === ""
                ? null
                : requestValue?.job_transfer_days,
          },
          event_occurred:
            requestValue?.event_occurred === ""
              ? null
              : requestValue?.event_occurred,
          injury_description:
            requestValue?.injury_description === ""
              ? null
              : requestValue?.injury_description,
          status: !requestValue?.assigned_to
            ? "PENDING"
            : clickedButtonValue === "ReviewComplete"
            ? "COMPLETED"
            : "IN_REVIEW",
        };
        delete requestPayload["away_work_days"];
        delete requestPayload["job_transfer_days"];
        delete requestPayload["clickedButton"];

        let response = await dispatch(
          updateReport({
            id: values?.id,
            payload: requestPayload,
          })
        );

        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "Report updated successfully",
            })
          );
          logAnalyticsEvent("update_incident_report", {
            user_id: user?.data?.user?.id,
          });
          await dispatch(setNotificationReportId(null));
          setLayout(false);
          fetchAllReport();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload?.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        // await dispatch(
        //   setSnackbar({
        //     open: true,
        //     severity: "error",
        //     message: error || "Internal server error",
        //   })
        // );
      }
      setIsLoading(false);
    },
  });
  console.log(formik.values);
  const scrollToError = (errors) => {
    const errorField = Object.keys(errors)[0]; // Get the first error field

    const errorElement = document.getElementById(errorField); // Find the DOM element of that field
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to the error field
    }
  };

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={drawerState === "view" ? "View Report" : "Update Report"}
        open={isLayout}
        handleClose={() => {
          setLayout(false);
          formik.resetForm();
        }}
      >
        <DialogContent
          style={{
            maxHeight: "75vh",
          }}
        >
          <form>
            <Grid container spacing={4}>
              {/* Report title input */}
              <Grid container item xs={12} spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={values?.image && values.image.length > 0 ? 6 : 12}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Typography variant="body1" component="label">
                          Report Title
                        </Typography>
                        <TextField
                          disabled={drawerState === "view"}
                          fullWidth
                          id="title"
                          label=""
                          variant="outlined"
                          placeholder="Enter Report title"
                          size="small"
                          name="title"
                          {...formik.getFieldProps("title")}
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="form-error-message">
                            {formik.errors.title}
                          </div>
                        )}
                      </Box>
                    </Grid>
                    {drawerState === "view" && (
                      <Grid item xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Box variant="div" component="div" sx={BootstrapInput}>
                          <Typography variant="body1" component="label">
                            Site Title
                          </Typography>
                          <TextField
                            disabled={drawerState === "view"}
                            fullWidth
                            id="title"
                            label=""
                            variant="outlined"
                            value={values?.project?.name}
                            size="small"
                            name="project title"
                          />
                        </Box>
                      </Grid>
                    )}

                    {/* Report description  */}
                    <Grid
                      item
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ marginTop: -15 }}
                    >
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Typography variant="body1" component="label">
                          Report description
                        </Typography>
                        <TextField
                          disabled={drawerState === "view"}
                          fullWidth
                          id="description"
                          label=""
                          variant="outlined"
                          placeholder="Enter Report description"
                          size="small"
                          multiline
                          maxRows={3}
                          name="description"
                          {...formik.getFieldProps("description")}
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className="form-error-message">
                              {formik.errors.description}
                            </div>
                          )}
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ marginTop: -15 }}
                    >
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Typography variant="body1" component="label">
                          Report type
                        </Typography>
                        <Select
                          disabled={drawerState === "view"}
                          fullWidth
                          labelId=""
                          placeholder="Select case"
                          variant="outlined"
                          id="type"
                          value={formik?.values?.type}
                          size="small"
                          label=""
                          onChange={(e) => {
                            formik.setFieldValue("type", e.target.value);
                          }}
                        >
                          <MenuItem value={"hazard"}>Hazard</MenuItem>
                          <MenuItem value={"incident"}>Incident</MenuItem>
                          <MenuItem value={"maintenance"}>Maintenance</MenuItem>
                          <MenuItem value={"general_observation"}>
                            General observation
                          </MenuItem>
                          <MenuItem value={"near_miss"}>Near Miss</MenuItem>
                        </Select>
                        {formik.touched.type && formik.errors.type && (
                          <div className="form-error-message">
                            {formik.errors.type}
                          </div>
                        )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ marginTop: -15 }}
                    >
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Typography variant="body1" component="label">
                          Where the event occurred
                        </Typography>
                        <TextField
                          disabled={drawerState === "view"}
                          fullWidth
                          id="event_occurred"
                          label=""
                          variant="outlined"
                          placeholder="Where event occurred"
                          multiline
                          maxRows={2}
                          size="small"
                          name="event_occurred"
                          {...formik.getFieldProps("event_occurred")}
                        />
                        {formik.touched.event_occurred &&
                          formik.errors.event_occurred && (
                            <div className="form-error-message">
                              {formik.errors.event_occurred}
                            </div>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {values?.image && values?.image?.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    display="flex"
                    // alignItems="center"
                    justifyContent="center"
                    // sx={{ position: "relative" }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        // maxWidth: 300,
                        // maxHeight: 300,
                        // height: "290px",
                        // overflow: "hidden",

                        borderRadius: "8px",
                      }}
                    >
                      <Typography variant="body1" component="label">
                        Incident Image
                      </Typography>
                      {/* <Avatar
                        src={`${values?.image[0]}?${imageToken}`} // Display preview image or default
                        variant="rounded"
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      /> */}
                      <ImageSlider
                        images={values?.image}
                        imageToken={imageToken}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={12} spacing={4}>
                <Grid
                  item
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xl={12}
                  style={{ marginTop: -15 }}
                >
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Typography variant="body1" component="label">
                      Employees
                    </Typography>
                    <Selectt
                      isMulti // Enables multiple selection
                      name="employees"
                      options={users}
                      id="employees"
                      value={formik.values.employees} // Value should be an array of selected options
                      onChange={(selectedOptions) => {
                        console.log(selectedOptions);
                        formik.setFieldValue("employees", selectedOptions); // Update formik's state
                      }}
                      placeholder="Select case"
                      isDisabled={drawerState === "view"} // Disable select if needed
                    />
                    {formik.touched.employees && formik.errors.employees && (
                      <div className="form-error-message">
                        {formik.errors.employees}
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xl={12}
                  style={{ marginTop: -15 }}
                >
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Typography variant="body1" component="label">
                      Assigned To:
                    </Typography>
                    <Select
                      disabled={drawerState === "view"}
                      fullWidth
                      labelId=""
                      placeholder="Select Assigned To"
                      variant="outlined"
                      id="assigned_to"
                      value={formik?.values?.assigned_to}
                      size="small"
                      label=""
                      onChange={(e) => {
                        formik.setFieldValue("assigned_to", e.target.value);
                      }}
                    >
                      {users?.map((user) => (
                        <MenuItem value={user.value}>{user.label}</MenuItem>
                      ))}
                    </Select>
                    {formik.touched.assigned_to &&
                      formik.errors.assigned_to && (
                        <div className="form-error-message">
                          {formik.errors.assigned_to}
                        </div>
                      )}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginTop: -15 }}
                >
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Typography variant="body1" component="label">
                      Description about injury
                    </Typography>
                    <TextField
                      disabled={drawerState === "view"}
                      fullWidth
                      id="injury_description"
                      label=""
                      variant="outlined"
                      placeholder="Enter injury description"
                      size="small"
                      name="injury_description"
                      multiline={true} // This makes it a textarea
                      rows={4} // Number of rows to display for the textarea
                      {...formik.getFieldProps("injury_description")}
                    />
                    {formik.touched.injury_description &&
                      formik.errors.injury_description && (
                        <div className="form-error-message">
                          {formik.errors.injury_description}
                        </div>
                      )}
                  </Box>
                </Grid>
                <Grid item xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Divider />
                </Grid>

                <Grid
                  item
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xl={12}
                  style={{ marginTop: -15 }}
                >
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{
                        fontSize: 18,

                        // marginBottom: 10,
                        fontWeight: 500,
                      }}
                    >
                      Classify case
                    </Typography>

                    <Select
                      defaultValue="death"
                      disabled={drawerState === "view"}
                      fullWidth
                      labelId=""
                      placeholder="Select case"
                      variant="outlined"
                      id="classify_of_case"
                      value={formik?.values?.classify_of_case}
                      size="small"
                      label=""
                      onChange={(e) => {
                        formik.setFieldValue(
                          "classify_of_case",
                          e.target.value
                        );
                      }}
                    >
                      <MenuItem value={"death"}>Death</MenuItem>
                      <MenuItem value={"days away from work"}>
                        Days away from work
                      </MenuItem>
                      <MenuItem value={"job transfer or restriction"}>
                        Job transfer or restriction
                      </MenuItem>
                      <MenuItem value={"other recordable case"}>
                        Other recordable case
                      </MenuItem>
                    </Select>
                    {formik.touched.classify_of_case &&
                      formik.errors.classify_of_case && (
                        <div className="form-error-message">
                          {formik.errors.classify_of_case}
                        </div>
                      )}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xl={12}
                  style={{ marginTop: -15 }}
                >
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{
                        fontSize: 18,

                        // marginBottom: 10,
                        fontWeight: 500,
                      }}
                    >
                      Illness
                    </Typography>
                    <Select
                      disabled={drawerState === "view"}
                      fullWidth
                      labelId=""
                      placeholder="Select illness"
                      variant="outlined"
                      id="illness"
                      value={formik?.values?.illness}
                      size="small"
                      label=""
                      onChange={(e) => {
                        formik.setFieldValue("illness", e.target.value);
                      }}
                    >
                      <MenuItem value={"injury"}>Injury</MenuItem>
                      <MenuItem value={"skin_disorder"}>
                        Skin disorder Skin disorder
                      </MenuItem>
                      <MenuItem value={"respiratory_condition"}>
                        Respiratory condition
                      </MenuItem>
                      <MenuItem value={"poisoning"}>Poisoning</MenuItem>
                      <MenuItem value={"hearing_loss"}>
                        Hearing loss Hearing loss
                      </MenuItem>
                      <MenuItem value={"all_other_illness"}>
                        All other illness
                      </MenuItem>
                    </Select>
                    {formik.touched.illness && formik.errors.illness && (
                      <div className="form-error-message">
                        {formik.errors.illness}
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Divider />
                  <Typography
                    variant="body1"
                    component="label"
                    style={{
                      fontSize: 18,
                      marginTop: 10,
                      marginBottom: 10,
                      fontWeight: 500,
                    }}
                  >
                    Numered of days the injured or ill worker was
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xl={12}
                  style={{ marginTop: -15 }}
                >
                  {formik?.values?.classify_case == "" && <></>}

                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Typography variant="body1" component="label">
                      Away from work days
                    </Typography>

                    <TextField
                      disabled={
                        drawerState === "view" ||
                        formik?.values?.classify_case == "death" ||
                        formik?.values?.classify_case ==
                          "job_transfer_or_restriction" ||
                        formik?.values?.classify_case == "other_recordable_case"
                      }
                      fullWidth
                      type="number"
                      id="away_work_days"
                      label=""
                      variant="outlined"
                      placeholder="Enter days"
                      size="small"
                      name="away_work_days"
                      {...formik.getFieldProps("away_work_days")}
                    />
                    {formik.touched.away_work_days &&
                      formik.errors.away_work_days && (
                        <div className="form-error-message">
                          {formik.errors.away_work_days}
                        </div>
                      )}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xl={12}
                  style={{ marginTop: -15 }}
                >
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Typography variant="body1" component="label">
                      On job transfer or restriction days
                    </Typography>
                    <TextField
                      disabled={
                        drawerState === "view" ||
                        formik?.values?.classify_case == "death" ||
                        formik?.values?.classify_case == "other_recordable_case"
                      }
                      fullWidth
                      type="number"
                      id="job_transfer_days"
                      label=""
                      variant="outlined"
                      placeholder="Enter days"
                      size="small"
                      name="job_transfer_days"
                      {...formik.getFieldProps("job_transfer_days")}
                    />
                    {formik.touched.job_transfer_days &&
                      formik.errors.job_transfer_days && (
                        <div className="form-error-message">
                          {formik.errors.job_transfer_days}
                        </div>
                      )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Box className="modal-footers" sx={{ width: "100%" }}>
            <Box
              className="space-between"
              sx={{ width: "100%", padding: "0 20px" }}
            >
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: ` ${Status[reportStatus].color} !important`,
                    borderRadius: "var(--border-radius) !important",
                    cursor: "default !important",
                    boxShadow: "none",
                  }}
                >
                  {Status[reportStatus].hover}
                </Button>
              </Box>
              <Box>
                {drawerState !== "view" && (
                  <>
                    <Button
                      variant="contained"
                      className="primary-button"
                      disabled={isLoading}
                      onClick={() => {
                        formik.setFieldValue("clickedButton", "Save");

                        formik.errors && scrollToError(formik.errors);

                        formik.handleSubmit();
                      }}
                      sx={{ marginRight: "16px" }}
                    >
                      {isLoading && (
                        <CircularProgress
                          size="1rem"
                          sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                        />
                      )}
                      Save
                    </Button>
                    {reportStatus === "IN_REVIEW" && (
                      <Button
                        variant="contained"
                        className="primary-button"
                        disabled={isLoading}
                        onClick={() => {
                          setReviewCompleteOpen(true);
                        }}
                      >
                        {isLoading && (
                          <CircularProgress
                            size="1rem"
                            sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                          />
                        )}
                        Review Complete
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Update Status ( IN REVIEW => REVIEW COMPLETED )"}
        open={reviewCompleteOpen}
        handleClose={() => {
          setReviewCompleteOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are You Sure You want to mark the <b>{formik?.values?.title}</b>{" "}
              as reviewed and completed?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={() => {
                formik.setFieldValue("clickedButton", "ReviewComplete");
                formik.handleSubmit();
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setReviewCompleteOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default CreateReport;

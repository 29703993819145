import React, { useEffect, useState } from "react";
import { HiDocumentText } from "react-icons/hi2";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Divider,
  Tooltip,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logo/ver-logo.png";
import { CiSearch } from "react-icons/ci";
import { IoMdSettings } from "react-icons/io";
import BookIcon from "../../utils/icon/BookIcon";
import { HiDocumentReport } from "react-icons/hi";
import Workflow from "../../utils/icon/Workflow";
import ExternalAssistant from "../../utils/icon/ExternalAssitant";
import { BsChatLeftText } from "react-icons/bs";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MdDashboard } from "react-icons/md";
import { SiGoogleforms } from "react-icons/si";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { FaUsers } from "react-icons/fa";
import { useSelector } from "react-redux";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { FaCircleChevronLeft } from "react-icons/fa6";
const SideMenu = ({ openSideMenu, setOpenSideMenu }) => {
  const Navigate = useNavigate();
  const location = useLocation();
  const isLargeSidebar = useSelector((state) => state.common.isFullSidebar);

  const MenuOptions = [
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: <MdDashboard size={36} />,
    },
    {
      label: "Sites",
      path: "/project",
      icon: <HiDocumentText size={36} />,
    },

    {
      label: "Employees",
      path: "/employees",
      icon: <FaUsers size={36} />,
    },
    {
      label: "Incident Reports",
      path: "/reports",
      icon: <HiDocumentReport size={36} />,
    },
    {
      label: "Forms",
      path: "/forms",
      icon: <SiGoogleforms size={36} />,
    },
    {
      label: "Service Unit",
      path: "/service-unit",
      icon: (
        <PrecisionManufacturingIcon sx={{ height: "36px", width: "36px" }} />
      ),
    },
    {
      label: "Knowldge Base",
      path: "/knowledge-base",
      icon: (
        <BookIcon
          size={36}
          color={
            location.pathname.includes("/knowledge-base") ? "white" : "black"
          }
        />
      ),
    },
    {
      label: "Search",
      path: "/search-document",
      icon: <CiSearch size={36} />,
    },
    // {
    //   label: "KB Chat",
    //   path: "/chat",
    //   icon: <DocumentChat size={36} width={36} height={36} />,
    // },
    // {
    //   label: "Workflows",
    //   path: "/workflows",
    //   icon: <Workflow size={32} />,
    // },
    // {
    //   label: "Assistants",
    //   path: "/assistants",
    //   icon: <RiAccountCircleLine size={36} />,
    // },
    {
      label: "Assistants Chat",
      path: "/assistant-chat-history",
      icon: <BsChatLeftText size={36} />,
    },
    // {
    //   label: "External Assistant",
    //   path: "/configure-assistant",
    //   icon: <ExternalAssistant size={32} />,
    // },
    {
      label: "Settings",
      path: "/settings",
      icon: <IoMdSettings size={36} />,
    },
  ];

  const handleNavigate = (path) => {
    Navigate(path);
    setOpenSideMenu((prev) => !prev);
  };

  const isMobile = useMediaQuery("(max-width: 601px)");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    if (window.innerWidth < 601) {
      setOpenSideMenu(false);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [isMobile]);

  const handleDrawerClose = () => {
    setOpenSideMenu((prev) => !prev);
  };
  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setOpenSideMenu((prevState) => !prevState);
  };
  return (
    <>
      {!isMobile ? (
        <Drawer
          open={openSideMenu}
          variant="persistent"
          anchor="left"
          className={`sidebar-wrapper ${
            openSideMenu ? "sidebar-menu-open" : "ismobile-closed"
          }`}
        >
          {windowWidth < 601 && (
            <>
              <IconButton
                sx={{ justifyContent: "end" }}
                onClick={handleDrawerClose}
              >
                <ChevronLeftIcon />
              </IconButton>
              <Divider />
            </>
          )}

          <Box className="left-logo" key="side-logo">
            <img
              src={logo}
              alt="logo-main"
              loading="lazy"
              className="large-logo"
              style={{
                maxWidth: isLargeSidebar ? "100px" : "80px",
              }}
              onClick={() => Navigate("/")}
            />
          </Box>
          <Box
            className={`list-wrap ${
              isLargeSidebar ? "large-sidebar-width" : "small-sidebar-width"
            }`}
            sx={{ padding: "0 12px" }}
          >
            <Grid
              className={`sidebar-grid ${
                !isLargeSidebar ? "small-width-sidebar-grid" : ""
              }`}
              container
              spacing={2}
            >
              {MenuOptions.map((item, index) => {
                return (
                  <Grid item xs={isLargeSidebar ? 6 : 12}>
                    <Box
                      onClick={() => handleNavigate(item.path)}
                      className={`sidebar-item `}
                    >
                      {/* Icon Container */}
                      <Box
                        className={`${
                          location.pathname.includes(item.path)
                            ? "active item-box"
                            : "item-box"
                        }`}
                      >
                        {item.icon}
                      </Box>

                      {/* Text */}
                      <Typography
                        sx={{
                          fontSize: isLargeSidebar
                            ? "14px !important"
                            : "12px !important",
                          fontWeight: "500 !important",
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Drawer>
      ) : (
        <Drawer
          variant="temporary"
          open={openSideMenu}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className={`sidebar-wrapper ${
            openSideMenu
              ? "sidebar-menu-open"
              : "sidebar-menu-closed ismobile-closed"
          } ${windowWidth > 601 ? "sidebar-menu-open" : "ismobile-closed"}`}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Box className="left-logo">
            <Box sx={{ position: "absolute", top: 8, right: 8, zIndex: 11 }}>
              <FaCircleChevronLeft size={24} onClick={handleDrawerToggle} />
            </Box>
            <Box className="left-logo" key="side-logo">
              <img
                src={logo}
                alt="logo-main"
                loading="lazy"
                className="large-logo"
                style={{
                  maxWidth: "100px",
                }}
                onClick={() => Navigate("/")}
              />
            </Box>
          </Box>

          <Box className="list-wrap" sx={{ marginTop: "0 !important" }}>
            <List className="sidebar">
              {MenuOptions.map((item, index) => {
                return (
                  <Tooltip title={item.label} arrow placement="right">
                    <ListItem
                      disablePadding
                      key={index}
                      onClick={() => handleNavigate(item.path)}
                      className={
                        location.pathname.includes(item.path) ? "active" : ""
                      }
                    >
                      <ListItemButton>
                        <ListItemIcon className="icon-wrap" title={item.label}>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.label}
                          className="menu-text-wrap"
                        />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default SideMenu;

// analyticsLogger.js
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase"; // Ensure this imports your Firebase analytics instance

/**
 * Logs events to Firebase Analytics
 * @param {string} eventName - Name of the event (e.g., "button_click", "page_view").
 * @param {object} [eventParams] - Additional parameters to provide context (optional).
 */
const logAnalyticsEvent = (eventName, eventParams = {}) => {
  try {
    if (!analytics) {
      console.error("Firebase Analytics is not initialized.");
      return;
    }

    logEvent(analytics, eventName, eventParams);
    console.log(`Logged event: ${eventName}`, eventParams);
  } catch (error) {
    console.error("Error logging event to Firebase Analytics:", error);
  }
};

export default logAnalyticsEvent;

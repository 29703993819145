import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import "../../styles/auth.scss";
import LoginLogo from "../../assets/logo/PrismViewLogoWhite.png";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { loginThunk } from "../../redux/slices/user.slice";
import { PAGE_URL } from "../../constants/pageUrl.constant";
import { setSnackbar } from "../../redux/slices/common.slice";

const BootstrapInput = {
  "& label": {
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#273167 !important",
      borderWidth: "1px !important",
    },
  },
};
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .trim()
        .email("Please enter a valid email address")
        .required("Email is required"),
      password: yup
        .string()
        .required("Please specify password")
        .min(6, "The password should have at least 6 characters"),
      // .matches(PASSWORD_REGEX, 'Please ensure a minimum of 8 characters, 1 lowercase, 1 uppercase, 1 number, and 1 symbol')
    }),
    onSubmit: async (values) => {
      setisLoading(true);
      if (rememberMe) {
        localStorage.setItem("email", formik.values.email);
        localStorage.setItem("password", formik.values.password);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
      try {
        let response = await dispatch(
          loginThunk({ email: values.email, password: values.password })
        );
        const { payload } = response;
        console.log(payload);
        if (payload && payload.status) {
          console.log(payload.data);
          if (payload?.data?.system_role === "admin") {
            await dispatch(
              setSnackbar({
                open: true,
                severity: "success",
                message: "Login Successfully",
              })
            );
            navigate(PAGE_URL.REPORT_DASHBOARD);
          } else {
            navigate(PAGE_URL.NO_ACCESS);
          }
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
        console.error("Error during login:", error);
      }
      setisLoading(false);
    },
  });

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");

    if (savedEmail && savedPassword) {
      formik.setFieldValue("email", savedEmail);
      formik.setFieldValue("password", savedPassword);
      setRememberMe(true);
    }
    // eslint-disable-next-line
  }, [formik.setFieldValue]);

  return (
    <Box className="login-wrapper p-5">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {/* <Grid item xs={12} sm={12} md={6} lg={7}>
                    <Box className='text-center'>
                        <img src={ChatBot} alt='ChatBoat' style={{ maxWidth: '600px', margin: '0 auto' }} />
                    </Box>
                </Grid> */}
        <Grid item xs={12} sm={8} md={5} lg={4}>
          <Box className="text-center logo-wrap mb-5">
            <Link to="/">
              <img src={LoginLogo} alt="Logo" />
            </Link>
          </Box>
          <Paper component="form" elevation={1} className="p-4">
            <Typography variant="h3" className="mb-4 text-center">
              Log in to your account
            </Typography>

            <Stack spacing={3}>
              <FormControl variant="outlined" sx={BootstrapInput}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-email"
                  type="text"
                  label="Email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="form-error-message">
                    {formik.errors.email}
                  </div>
                )}
              </FormControl>
              <FormControl variant="outlined" sx={BootstrapInput}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  {...formik.getFieldProps("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility sx={{ fill: "#273167" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="form-error-message">
                    {formik.errors.password}
                  </div>
                )}
              </FormControl>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="mt-0"
              >
                <FormControlLabel
                  className="remeberme-check"
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                  }
                  label="Remember me"
                />
                <Link to={PAGE_URL.FORGOT_PASSWORD} className="forgotPWD">
                  Forgot password?
                </Link>
              </Stack>
              <Button
                variant="contained"
                className="primary-button mt-2"
                disabled={isLoading}
                size="large"
                onClick={formik.handleSubmit}
              >
                {isLoading && (
                  <CircularProgress
                    size="1rem"
                    sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                  />
                )}
                Log in
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Paper,
  InputBase,
  Stack,
  DialogContent,
  DialogActions,
  Chip,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";

import { RiAddCircleLine } from "react-icons/ri";
import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { MdOutlineWatchLater } from "react-icons/md";

import SwapVertIcon from "@mui/icons-material/SwapVert";

import DataGridTable from "../dataGrid";

import { setSnackbar } from "../../../redux/slices/common.slice";

import { Status } from "../../../utils/icon/CommonFunctions";

import CreateTraining from "./createTraining";
import Pencil from "../../../assets/icons/pencil.svg";
import {
  getUsersTraining,
  unAssignUserFromTraining,
  updateUserTraining,
} from "../../../redux/slices/employees.slice";
import { IoIosCheckmarkCircle } from "react-icons/io";
import CustomizedDialog from "../Dialog";
import { AiOutlineMinusCircle } from "react-icons/ai";
const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    padding: "12px",

    pr: 3,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

const statusOptions = [
  { value: "All", label: "All" },
  { value: "completed", label: "Completed" },
  { value: "pending", label: "Pending" },
  { value: "on_going", label: "Ongoing" },
];
export default function EmployeeTrainingList() {
  const dispatch = useDispatch();
  const [training, setTraining] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const [sortKey, setSortKey] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [selectedTraining, setSelectedTraining] = useState();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");

  const [statusFilter, setStatusFilter] = useState("All");

  const [reviewCompleteOpen, setReviewCompleteOpen] = useState(false);
  const [unAssignOpen, setUnAssignOpen] = useState(false);
  const { empId } = useParams();
  const fetchAllTraining = async () => {
    setIsLoading(true);

    try {
      let response = await dispatch(
        getUsersTraining({
          params: {
            page_number: 1,

            // page_number: paginationModel.page + 1,
            // page_size: paginationModel.pageSize,
            status:
              statusFilter === "All"
                ? undefined
                : statusFilter === "completed"
                ? statusFilter
                : "on_going",
            sort: sortKey,
          },
          id: empId,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        let tempData = [];
        if (statusFilter === "pending") {
          tempData = payload?.data?.filter((item) =>
            isDueDatePast(item.due_date)
          );
        } else if (statusFilter === "on_going") {
          tempData = payload?.data?.filter(
            (item) => !isDueDatePast(item.due_date)
          );
        } else {
          tempData = payload?.data;
        }

        setTraining(tempData);
        setTotalRow(tempData?.length);
        // setTraining(payload?.data);
        // setTotalRow(payload?.object_count);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAllTraining();
  }, [paginationModel, sortKey, statusFilter]);

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }
  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };

  const isDueDatePast = (dueDate) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
    const due = new Date(dueDate).toISOString().split("T")[0]; // Get due date in YYYY-MM-DD format

    return due < today; // Compare date strings
  };

  const columns = [
    {
      field: "title",
      headerName: "title",
      flex: 2,
      minWidth: 150,
      valueGetter: (params) => params.row.training.title || "-",
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "training__title")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 150,

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "description")}
        >
          Description
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 120,

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "status")}
        >
          Status
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: ({ row }) => {
        if (row.status !== "completed" && isDueDatePast(row.due_date)) {
          return (
            <Chip
              label={Status["PENDING"]?.label}
              style={{
                backgroundColor: Status["PENDING"]?.color,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            />
          );
        } else if (row.status) {
          return (
            <Chip
              label={Status[row?.status]?.label}
              style={{
                backgroundColor: Status[row?.status]?.color,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return "-";
        }
      },
    },

    {
      field: "due_date",
      headerName: "Due Date",
      flex: 1,
      minWidth: 130,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "due_date")}
        >
          Due Date
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Stack direction="row" spacing={0} alignItems="center">
            {params.row.status === "completed" ? (
              <Tooltip title="Completed">
                <IconButton sx={{ color: "#000000" }} disabled>
                  <IoIosCheckmarkCircle size={24} color="green" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Mark as Complete">
                <IconButton
                  onClick={() => {
                    setReviewCompleteOpen(true);
                    setSelectedTraining(params?.row);
                  }}
                >
                  <MdOutlineWatchLater
                    size={24}
                    cursor={"pointer"}
                    color="orange"
                  />
                </IconButton>
              </Tooltip>
            )}
            {params.row?.status !== "completed" && (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    sx={{ color: "#000000", padding: "0px" }}
                    onClick={() => {
                      setDrawerState("edit");
                      setIsOpenDrawer(true);
                      setSelectedTraining(params.row);
                    }}
                  >
                    <img
                      alt="Edit Details"
                      src={Pencil}
                      width={24}
                      height={24}
                      className="actionIcon"
                      cursor={"pointer"}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Unassignment">
                  <IconButton
                    onClick={() => {
                      setUnAssignOpen(true);
                      setSelectedTraining(params?.row);
                    }}
                  >
                    <AiOutlineMinusCircle
                      size={24}
                      cursor={"pointer"}
                      color="red"
                    />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
        );
      },
    },
  ];

  const handleUpdateStatus = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        updateUserTraining({
          data: { status: "completed" },
          id: selectedTraining?.id,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Training Status Updated Successfully.",
          })
        );
        setReviewCompleteOpen(false);
        fetchAllTraining();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleUnassignment = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        unAssignUserFromTraining({
          id: selectedTraining?.id,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Training Unassigned Successfully.",
          })
        );
        setUnAssignOpen(false);
        fetchAllTraining();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="employee-training ">
        <Box className="content-header kb-list-header mb-4">
          <Select
            sx={customStyles}
            name="status"
            className="basic-multi-select basic-multi-select-status"
            options={statusOptions}
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e.target.value);
            }}
            classNamePrefix="select"
            components={{
              IndicatorSeparator: () => null,
            }}
          >
            {statusOptions.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </Select>
          <Box
            variant="div"
            component="div"
            className="content-header-right"
            sx={{ marginLeft: "auto" }}
          >
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => {
                setIsOpenDrawer(true);
                setDrawerState("create");
              }}
            >
              Assign Training
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? training?.filter(
                    (item) =>
                      item?.training?.title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      item.training?.description
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
                : training.slice(
                    paginationModel.page * paginationModel.pageSize,
                    (paginationModel.page + 1) * paginationModel.pageSize
                  )
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            height="calc(100vh - 345px)"
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>

      {isOpenDrawer && (
        <CreateTraining
          drawerState={drawerState}
          setIsOpenDrawer={setIsOpenDrawer}
          isOpenDrawer={isOpenDrawer}
          fetchAlltasks={fetchAllTraining}
          values={selectedTraining}
        />
      )}

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Update Status"}
        open={reviewCompleteOpen}
        handleClose={() => {
          setReviewCompleteOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are You Sure You want to mark the{" "}
              <b>{selectedTraining?.training?.title}</b> as completed?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={handleUpdateStatus}
            >
              {isLoading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setReviewCompleteOpen(false)}
            >
              {isLoading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Unassignment"}
        open={unAssignOpen}
        handleClose={() => {
          setUnAssignOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are you sure you want to unassign the employee from the training{" "}
              <b>{selectedTraining?.training?.title}</b>?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={handleUnassignment}
            >
              {isLoading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setUnAssignOpen(false)}
            >
              {isLoading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
}

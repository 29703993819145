import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/slices/common.slice";
import { getDashboardMessages } from "../../../redux/slices/dashboard.slice";
import CreateMessages from "../settings/Project/createMessage";

export default function Messages() {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState();
  const [selectedMessage, setSelectedMessage] = useState();
  const [messageOpen, setMessageOpen] = useState(false);

  const dispatch = useDispatch();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchMessages = async () => {
    setLoading(true);

    let response = await dispatch(
      getDashboardMessages({
        type: activeTab === 0 ? "scheduled" : "on_demand",
      })
    );
    const { payload } = response;

    if (payload?.status) {
      setMessages(payload?.data);
    } else {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchMessages();
  }, [activeTab]);
  return (
    <>
      <Paper elevation={3} sx={{ padding: "8px" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="TODO tabs"
          className="configure-action-tab"
        >
          <Tab label={"Scheduled Messages"} disabled={loading} />
          <Tab label={"On Demand Messages"} disabled={loading} />
        </Tabs>
        <Box sx={{ height: "calc(100vh - 230px)", overflowY: "auto" }}>
          {loading ? (
            <Box
              sx={{
                height: "100%",
              }}
              className="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!loading && messages && messages?.length === 0 ? (
                <Box
                  className="center"
                  sx={{
                    height: "100%",
                  }}
                >
                  <Typography variant="subtitle1">No Messages</Typography>
                </Box>
              ) : (
                <Grid container spacing={2} sx={{ p: 2 }}>
                  {messages?.map((message) => (
                    <Grid
                      item
                      xs={12} // Full width on small screens
                      sm={6} // Half width on medium and larger screens
                      key={message.id}
                    >
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",

                          "&:hover": { boxShadow: 3 },
                        }}
                        onClick={() => {
                          setMessageOpen(true);
                          setSelectedMessage(message);
                        }}
                      >
                        <CardContent sx={{ flexGrow: 1, position: "relative" }}>
                          {/* Title */}
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {message.title}
                          </Typography>
                          {/* Description with truncation */}
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3, // Limit to 3 lines
                              WebkitBoxOrient: "vertical",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: message.message,
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        </Box>
      </Paper>
      {messageOpen && (
        <CreateMessages
          drawerState={"view"}
          setIsOpenDrawer={setMessageOpen}
          isOpenDrawer={messageOpen}
          values={selectedMessage}
          fetchAlltasks={fetchMessages}
        />
      )}
    </>
  );
}

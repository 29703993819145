import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Paper,
  InputBase,
  Stack,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import { RiAddCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";

import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../assets/icons/pencil.svg";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import DeleteIcon from "../../../assets/icons/deleteRed.svg";

import DataGridTable from "../dataGrid";
import { PAGE_URL } from "../../../constants/pageUrl.constant";
import {
  deleteServiceUnit,
  deleteServiceUnitType,
  getAllServiceUnitType,
} from "../../../redux/slices/serviceUnit.slice";
import { setSnackbar } from "../../../redux/slices/common.slice";
import CustomizedDialog from "../Dialog";
import { getAllServiceUnit } from "../../../redux/slices/serviceUnit.slice";
import Select from "react-select";

import { IoBuild } from "react-icons/io5";
import logAnalyticsEvent from "../../../firebase/analyticsLogger";
import { useSelector } from "react-redux";

const customStyles = {
  control: (base) => ({
    ...base,
    border: "1px solid #036185",
    borderRadius: 8,
    padding: 4,
    boxShadow: "none",

    "&:hover": {
      borderColor: "#036185",
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 180,
    overflowY: "auto",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};
export default function ServiceUnitList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serviceUnits, setserviceUnits] = useState([]);
  const [serviceUnitTypes, setServiceUnitTypes] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedServiceUnit, setSelectedServiceUnit] = useState();
  const [sortKey, setSortKey] = useState();
  const [selectedType, setSelectedType] = useState(null);
  const [error, setError] = useState();
  const user = useSelector((state) => state.user);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [openPreview, setOpenPreview] = useState(false);
  const fetchAllserviceUnit = async () => {
    setIsLoading(true);

    try {
      let response = await dispatch(
        getAllServiceUnit({
          page_number: paginationModel.page + 1,
          page_size: paginationModel.pageSize,
          //   custom_filter: JSON.stringify(custom_filter),
          sort: sortKey,
          name__icontains: searchTerm ? searchTerm : undefined,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setserviceUnits(payload?.data);

        setTotalRow(payload?.total_object);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setIsLoading(false);
  };
  useEffect(() => {
    fetchAllserviceUnit();
  }, [paginationModel, sortKey, searchTerm]);

  useEffect(() => {
    const fetchAllServiceType = async () => {
      setIsLoading(true);

      try {
        let response = await dispatch(
          getAllServiceUnitType({
            page_number: 1,
          })
        );
        const { payload } = response;
        if (payload?.status) {
          if (payload?.data && payload?.data?.length > 0) {
            setServiceUnitTypes(
              payload?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))
            );
            setServiceTypes(payload?.data);
          }
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload?.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: "Internal server error",
          })
        );
      }

      setIsLoading(false);
    };
    fetchAllServiceType();
  }, []);

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption);
    setError();
    setSelectedQuestions(
      serviceTypes
        ?.find((item) => item.id === selectedOption.value)
        ?.service_unit_json.map((question) => ({
          ...question,
          answer: "",
        })) || []
    );
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        deleteServiceUnit({ id: selectedServiceUnit?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        setOpenDeleteModal(false);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Service Unit Deleted succesfully",
          })
        );
        logAnalyticsEvent("delete_service-unit", {
          user_id: user?.data?.user?.id,
        });
        fetchAllserviceUnit();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }
  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "title",
      flex: 2,
      minWidth: 250,

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "name")}
        >
          Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "type",
      headerName: "Service Unit Type",
      flex: 2,
      minWidth: 200,

      valueGetter: (params) => params.row.type?.name || "-",
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "type__name")}
        >
          Service Unit Type
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "created_at",
      headerName: "Created At",

      width: 130,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",

      width: 130,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Stack direction="row" spacing={0} alignItems="center">
            <Tooltip title="View">
              <IconButton onClick={() => navigate(`/service-unit/${id}/view`)}>
                <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                sx={{ color: "#000000", padding: 0 }}
                onClick={() => {
                  setOpenDeleteModal(true);
                  setSelectedServiceUnit(params.row);
                }}
              >
                <img
                  alt="Delete Details"
                  src={DeleteIcon}
                  width={24}
                  height={24}
                  className="actionIcon"
                  cursor={"pointer"}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <div className="history">
        <Box className="content-header kb-list-header mb-4">
          <Box
            variant="div"
            component="div"
            className="content-header-right"
            sx={{ marginLeft: "auto !important" }}
          >
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              // onClick={() => navigate(PAGE_URL.SERVICE_UNIT_CREATE)}
              onClick={() => setOpenPreview(true)}
            >
              Create Service Unit
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={serviceUnits}
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Select Service Unit Type"}
        height="300px"
        width="600px"
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
      >
        <DialogContent>
          <Box p={2}>
            {serviceTypes?.length !== 0 && (
              <>
                {error && <div className="form-error-message">{error}</div>}
                <Select
                  value={selectedType}
                  onChange={(e) => handleTypeChange(e)}
                  options={serviceUnitTypes}
                  styles={customStyles}
                  placeholder="Select Service Unit Type"
                  defaultMenuIsOpen={true}
                />
              </>
            )}

            {!serviceTypes.length && (
              <Box
                sx={{
                  textAlign: "center",
                  padding: 3,

                  marginTop: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, color: "#333" }}
                >
                  No Service Unit Type Found
                </Typography>
                <Typography
                  variant="label"
                  sx={{ marginTop: 1, color: "#555" }}
                >
                  Please create a new service unit type to proceed.
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {!serviceTypes?.length ? (
              <Button
                sx={{ marginRight: "15px" }}
                variant="contained"
                className="primary-button"
                onClick={() => navigate(PAGE_URL.SERVICE_TYPE_CREATE)}
              >
                Create Service Unit Type
              </Button>
            ) : (
              <Button
                sx={{ marginRight: "15px" }}
                variant="contained"
                className="primary-button"
                onClick={() => {
                  if (!selectedType) {
                    setError("Select Service Unit Type First");
                    return;
                  }
                  navigate(PAGE_URL.SERVICE_UNIT_CREATE, {
                    state: {
                      type: selectedType,
                      questions: selectedQuestions,
                    },
                  });
                }}
              >
                Create Service Unit
              </Button>
            )}

            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setOpenPreview(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete {selectedServiceUnit?.name} ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
}

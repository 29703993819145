import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Autocomplete,
  MenuItem,
  Tooltip,
  Checkbox,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
import Select, { components } from "react-select";
import "../../../../styles/dashboard.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import CustomizedDialog from "../../Dialog";
import KBselection from "./selectKnowledgeBase";
import {
  ProjectLocationAutoCompleteSuggesstionThunk,
  createProjectThunk,
  updateProjectThunk,
} from "../../../../redux/slices/tenant.slice";
import { fetchAllPublicKBThunk } from "../../../../redux/slices/knowledgeBase.slice";
import AssistantSelectKB from "../../Assistants/AssistantSelectKB";
import { useSelector } from "react-redux";
import logAnalyticsEvent from "../../../../firebase/analyticsLogger";
import ReactQuill from "react-quill";
import { modules } from "../../../../utils/icon/CommonFunctions";
import { FaInfoCircle } from "react-icons/fa";
const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "40px",
    "&:focus": {
      borderColor: "#e0f7fa", // Remove blue border when focused
    },
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    backgroundColor: state.isSelected
      ? "#e0f7fa" // Keep selected color
      : state.isFocused
      ? "transparent" // Make the focus background transparent
      : "white", // Default background color for unselected items
    padding: "10px",
    "&:active": {
      backgroundColor: "transparent", // Remove active background when clicked
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
  }),
};

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

let timeOut = null;
const CreateProject = (
  {
    drawerState,
    setDrawerState,
    setIsOpenDrawer,
    isOpenDrawer,
    fetchAllProject,
    viewOpen,
    selectedRow,
  },
  props
) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    projectid: "",
    location: "",
    radius: "",
    notification_description: "",
    safety_description: "",
    project_safety_docs: {},
    safety_management_team: [],
    employees: [],
  });
  const [publicKb, setPublicKb] = useState([]);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const user = useSelector((state) => state.user);

  const validationSchema = yup.object({
    name: yup.string().required("Please, Enter project name"),
    description: yup
      .string()
      // .test("not-empty-or-br", "Please, Enter project description", (value) => {
      //   const trimmedValue = value?.trim();
      //   return trimmedValue && trimmedValue !== "<p><br></p>";
      // })
      .required("Please, Enter project description"),
    projectid: yup.string().required("Please, Enter project id"),
    location: yup.string().required("Please, Enter project location"),
    radius: yup.string().required("Please, Enter project radius"),
    notification_description: yup
      .string()
      .required("Please, Enter notification description"),
    safety_description: yup
      .string()
      .required("Please, Enter safety description"),
  });

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const fetchAllKB = async () => {
    let response = await dispatch(fetchAllPublicKBThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      let temp = payload?.data.map((item) => ({
        label: item.kbName,
        value: item.kbId,
      }));
      setPublicKb(temp);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchAllKB();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let matchPlaceId = null;
      locationOption?.map((element) => {
        if (element?.value == placeId) {
          matchPlaceId = element?.value;
        }
      });
      let kb = [];
      // values?.knowledgebase?.map((element) => {
      //   kb.push(element?.value);
      // });
      // values["knowledgebase"] = kb;
      // const fianlData = {
      //   ...values,
      //   employees: values?.employees?.map((item) => item.value),
      //   safety_management_team: values?.safety_management_team?.map(
      //     (item) => item.value
      //   ),
      //   id: selectedRow?.id,
      // };

      setIsLoading(true);
      try {
        values["place_id"] = matchPlaceId;
        let response = await dispatch(
          drawerState === "edit"
            ? updateProjectThunk({
                ...values,
                employees: values?.employees?.map((item) => item.value),
                safety_management_team: values?.safety_management_team?.map(
                  (item) => item.value
                ),
                id: selectedRow?.id,
              })
            : createProjectThunk({
                ...values,
                // employees: values?.employees?.map((item) => item.value),
                // safety_management_team: values?.safety_management_team?.map(
                //   (item) => item.value
                // ),
              })
        );
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message:
                drawerState === "edit"
                  ? "Project Successfully updated"
                  : "Project successfully created",
            })
          );
          if (drawerState === "edit") {
            logAnalyticsEvent("update_project", {
              userId: user?.data?.user?.id,
            });
          } else {
            logAnalyticsEvent("create_project", {
              userId: user?.data?.user?.id,
            });
          }

          setIsOpenDrawer(false);
          fetchAllProject();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });
  console.log(formik.values.description);
  useEffect(() => {
    if (drawerState == "view" || drawerState == "edit") {
      setLoading(true);
      let data = {
        name: selectedRow?.name,
        description: selectedRow?.description,
        projectid: selectedRow?.projectid,
        location: selectedRow?.location,
        radius: selectedRow?.radius,
        notification_description: selectedRow?.notification_description,
        safety_description: selectedRow?.safety_description,
      };

      data = {
        ...data,
        project_safety_docs: selectedRow?.project_safety_docs,
        safety_management_team: selectedRow?.safety_management_team?.map(
          (item) => ({
            label: item.first_name + " " + item.last_name,
            value: item.id,
          })
        ),
        employees: selectedRow?.employees?.map((item) => ({
          label: item.first_name + " " + item.last_name,
          value: item.id,
        })),
      };
      formik.setValues(data);
      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: selectedRow?.location,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }, 500);
    }
  }, [drawerState, selectedRow, publicKb]);

  const [locationOption, setLocationOption] = useState([]);
  const [placeId, setPlaceId] = useState(null);

  const handleAutocompleteChange = (event, values) => {
    formik?.setFieldValue("location", values);

    if (values !== "") {
      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: values,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
        } catch (error) {}
      }, 500);
    }
  };
  const handleSelectionChange = (selectedOptions) => {
    // Extract the selected user IDs (values)
    const userIds = selectedOptions
      ? selectedOptions.map((user) => user.value)
      : [];
    formik.setFieldValue("employees", userIds);

    // Ensure that the safety team only contains users who are also in the selected users list
    formik.setFieldValue(
      "safety_management_team",
      formik.values.safety_management_team.filter((userId) =>
        userIds.includes(userId)
      )
    );
  };

  const toggleSafetyTeam = (userValue, e) => {
    e.stopPropagation(); // Prevent select option click when checkbox is toggled

    // Only allow toggling for users who are currently selected
    if (formik.values.employees.includes(userValue)) {
      const updatedSafetyTeam = [...formik.values.safety_management_team];

      if (updatedSafetyTeam.includes(userValue)) {
        // If user is already in the safety team, remove them
        const updatedArray = updatedSafetyTeam.filter((id) => id !== userValue);
        formik.setFieldValue("safety_management_team", updatedArray);
      } else {
        // Add user to safety team
        updatedSafetyTeam.push(userValue);
        formik.setFieldValue("safety_management_team", updatedSafetyTeam);
      }
    }
  };

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={
          drawerState === "view"
            ? "Site Details"
            : drawerState === "create"
            ? "Create Site"
            : "Edit site"
        }
        open={isOpenDrawer || viewOpen}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent>
          {!loading ? (
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Typography variant="body1" component="label">
                      Site name
                    </Typography>
                    <TextField
                      disabled={drawerState === "view"}
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      placeholder="Enter Project name"
                      size="small"
                      name="name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="form-error-message">
                        {formik.errors.name}
                      </div>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body1"
                        component="label"
                        sx={{ width: "auto !important" }}
                      >
                        ID
                      </Typography>
                      <Tooltip
                        title="Unique Identifier for the site if any"
                        aria-label="tooltip"
                      >
                        <span>
                          <FaInfoCircle color="var(--color-theme-secondary)" />
                        </span>
                      </Tooltip>
                    </Box>

                    <TextField
                      disabled={drawerState === "view"}
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      placeholder="Enter Projectid"
                      size="small"
                      name="projectid"
                      {...formik.getFieldProps("projectid")}
                    />
                    {formik.touched.projectid && formik.errors.projectid && (
                      <div className="form-error-message">
                        {formik.errors.projectid}
                      </div>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Typography variant="body1" component="label">
                      Site description
                    </Typography>
                    <TextField
                      disabled={drawerState === "view"}
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      placeholder="Enter Project description"
                      size="small"
                      multiline
                      rows={2}
                      maxRows={3}
                      name="description"
                      {...formik.getFieldProps("description")}
                    />
                    {/* <Box className="rich-editor">
                      <ReactQuill
                        theme="snow"
                        value={formik.values.description}
                        modules={modules}
                        onChange={(value) =>
                          formik.setFieldValue("description", value)
                        }
                      />
                    </Box> */}
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div className="form-error-message">
                          {formik.errors.description}
                        </div>
                      )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body1"
                        component="label"
                        sx={{ width: "auto !important" }}
                      >
                        Notification radius (ft)
                      </Typography>
                      <Tooltip title="The radius around the site when notifications can be triggered on the mobile app">
                        <span>
                          <FaInfoCircle color="var(--color-theme-secondary)" />
                        </span>
                      </Tooltip>
                    </Box>
                    <TextField
                      type="number"
                      disabled={drawerState === "view"}
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      placeholder="Enter Project radius"
                      size="small"
                      name="radius"
                      {...formik.getFieldProps("radius")}
                    />
                    {formik.touched.radius && formik.errors.radius && (
                      <div className="form-error-message">
                        {formik.errors.radius}
                      </div>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Typography variant="body1" component="label">
                      Site location
                    </Typography>
                    <Autocomplete
                      className="project-location-autocomplete"
                      disabled={drawerState === "view"}
                      freeSolo
                      inputValue={formik.values.location}
                      onInputChange={(event, values) => {
                        handleAutocompleteChange(event, values);
                      }}
                      onChange={(options, value) => {
                        setPlaceId(value?.value);
                      }}
                      options={locationOption}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          id="outlined-basic"
                          fullWidth
                          size="small"
                          variant="outlined"
                          name="location"
                          placeholder="Please, Enter project location  "
                        />
                      )}
                    />
                    {formik.touched.location && formik.errors.location && (
                      <div className="form-error-message">
                        {formik.errors.location}
                      </div>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body1"
                        component="label"
                        sx={{ width: "auto !important" }}
                      >
                        Notification Description
                      </Typography>
                      <Tooltip title="The notification that will be displayed when someone enters site defined by the radius above">
                        <span>
                          <FaInfoCircle color="var(--color-theme-secondary)" />
                        </span>
                      </Tooltip>
                    </Box>
                    <TextField
                      disabled={drawerState === "view"}
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      placeholder="Enter Notification description"
                      size="small"
                      multiline
                      rows={2}
                      maxRows={3}
                      name="notification_description"
                      {...formik.getFieldProps("notification_description")}
                    />

                    {/* <Box className="rich-editor">
                      <ReactQuill
                        theme="snow"
                        value={formik.values.notification_description}
                        modules={modules}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "notification_description",
                            value
                          )
                        }
                      />
                    </Box> */}
                    {formik.touched.notification_description &&
                      formik.errors.notification_description && (
                        <div className="form-error-message">
                          {formik.errors.notification_description}
                        </div>
                      )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body1"
                        component="label"
                        sx={{ width: "auto !important" }}
                      >
                        Report notification description
                      </Typography>
                      <Tooltip title=" Enter the text that will be sent as notification when someone reports an incident, hazard, near miss etc on the site">
                        <span>
                          <FaInfoCircle color="var(--color-theme-secondary)" />
                        </span>
                      </Tooltip>
                    </Box>

                    <TextField
                      disabled={drawerState === "view"}
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      placeholder="Enter Safety description"
                      size="small"
                      multiline
                      rows={2}
                      maxRows={3}
                      name="safety_description"
                      {...formik.getFieldProps("safety_description")}
                    />

                    {formik.touched.safety_description &&
                      formik.errors.safety_description && (
                        <div className="form-error-message">
                          {formik.errors.safety_description}
                        </div>
                      )}
                  </Box>
                </Grid>
                {drawerState !== "create" && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box variant="div" component="div" sx={BootstrapInput}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography
                          variant="body1"
                          component="label"
                          sx={{ width: "auto !important" }}
                        >
                          Project Safety Docs
                        </Typography>
                        <Tooltip title="Site specific JSA, JHA, Standard operating procedure documents that will be referenced by Safety buddy in the mobile app for any safety related questions">
                          <span>
                            <FaInfoCircle color="var(--color-theme-secondary)" />
                          </span>
                        </Tooltip>
                      </Box>
                      <KBselection
                        id={1}
                        formik={formik}
                        disabled={drawerState === "view"}
                        docs={selectedRow?.project_safety_docs}
                      />
                    </Box>
                  </Grid>
                )}
                {drawerState === "create" && (
                  <Grid
                    container
                    spacing={3}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Grid item xs={12} lg={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box
                            variant="div"
                            component="div"
                            sx={BootstrapInput}
                          >
                            <Typography variant="body1" component="label">
                              Employees
                            </Typography>

                            <Select
                              isMulti
                              options={users}
                              value={users.filter((user) =>
                                formik.values.employees.includes(user.value)
                              )} // Show selected users only
                              onChange={handleSelectionChange}
                              styles={customStyles}
                              // closeMenuOnSelect={false}
                              // hideSelectedOptions={false}
                              placeholder="Select employees..."
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box
                            variant="div"
                            component="div"
                            sx={BootstrapInput}
                          >
                            <Box display="flex" alignItems="center" gap={1}>
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ width: "auto !important" }}
                              >
                                Safety Management team
                              </Typography>
                              <Tooltip title="Users assigned to site as Safety manager and who need to get notified and alerted of safety incidents or observations">
                                <span>
                                  <FaInfoCircle color="var(--color-theme-secondary)" />
                                </span>
                              </Tooltip>
                            </Box>
                            {users?.filter((item) =>
                              formik.values.employees?.includes(item.value)
                            )?.length > 0 ? (
                              <List
                                sx={{
                                  height: "278px",
                                  overflowY: "auto",
                                  border: "1px solid rgb(208, 204, 204)",
                                  borderRadius: "5px",
                                  margin: "10px 0",
                                }}
                              >
                                {users
                                  ?.filter((item) =>
                                    formik.values.employees?.includes(
                                      item.value
                                    )
                                  )
                                  .map((user) => (
                                    <ListItem
                                      key={user.value}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <ListItemText
                                        primary={user.label}
                                        sx={{ fontSize: "16px !important" }}
                                      />
                                      <Checkbox
                                        checked={formik.values.safety_management_team?.includes(
                                          user.value
                                        )} // Check if the user is in the safety team
                                        onChange={(e) =>
                                          toggleSafetyTeam(user.value, e)
                                        } // Toggle safety team status
                                        sx={{
                                          padding: "0", // Remove extra padding/margin of Checkbox
                                          height: "auto", // Ensures checkbox doesn't change layout height
                                          alignSelf: "center", // Centers the checkbox vertically within the box
                                        }}
                                      />
                                    </ListItem>
                                  ))}
                              </List>
                            ) : (
                              "-"
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box variant="div" component="div" sx={BootstrapInput}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <Typography
                              variant="body1"
                              component="label"
                              sx={{ width: "auto !important" }}
                            >
                              Project Safety Docs
                            </Typography>
                            <Tooltip title="Site specific JSA, JHA, Standard operating procedure documents that will be referenced by Safety buddy in the mobile app for any safety related questions">
                              <span>
                                <FaInfoCircle color="var(--color-theme-secondary)" />
                              </span>
                            </Tooltip>
                          </Box>
                          <KBselection
                            id={1}
                            formik={formik}
                            disabled={drawerState === "view"}
                            docs={selectedRow?.project_safety_docs}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </form>
          ) : (
            <CircularProgress
              sx={{
                height: "450px !important",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          {(drawerState !== "view" || drawerState === "edit") && (
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  {drawerState == "edit" ? "UPDATE" : "CREATE"}
                </Button>
                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          )}
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default CreateProject;

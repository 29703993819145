import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import KBselection from "./selectKnowledgeBase";
import { RichTreeView, SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getAllKBFilesThunk } from "../../../../redux/slices/assistants.slice";
import { useDispatch } from "react-redux";
import {
  setHeaderTitle,
  setSnackbar,
} from "../../../../redux/slices/common.slice";
import CreateProject from "./CreateProject";
import { getProjectDetailById } from "../../../../redux/slices/tenant.slice";
import { useSelector } from "react-redux";
import Select from "react-select";
import TeamMembersCallouts from "./TeamMembersCallouts";
import Messages from "./Messages";
import ReactQuill from "react-quill";
import { FaInfoCircle } from "react-icons/fa";
const TabOptions = ["Site Details", "Team Members", "Messages"];
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ViewProject() {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [kbs, setKbs] = useState([]);
  const [projectData, setProjectData] = useState();
  const users = useSelector((state) => state.common.users);

  const fetchAllKb = async () => {
    let response = await dispatch(getAllKBFilesThunk());

    if (response?.payload?.status) {
      const kbs = (response?.payload?.data || []).map((item) => ({
        id: item.kbId,
        label: item.kbName,
        children: item?.kbfiles?.files
          ? item?.kbfiles?.files?.map((ele) => ({
              id: ele.fileurl,
              label: ele.filename,
              parentId: item.kbId,
            }))
          : [],
      }));
      setKbs(kbs);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response?.payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    if (projectData?.name) dispatch(setHeaderTitle(projectData?.name));
    else {
      dispatch(setHeaderTitle(""));
    }
  }, [projectData]);

  const fetchProjectDetails = async () => {
    setLoading(true);
    try {
      let response = await dispatch(getProjectDetailById({ id: projectId }));
      const { payload } = response;
      if (payload && payload?.status) {
        setProjectData(payload?.data);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllKb();
    fetchProjectDetails();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
          alignItems: { xs: "flex-start", sm: "center" }, // Align tabs and button properly
          gap: { xs: 1, sm: 2 }, // Add gap between Tabs and Button
          mb: 2,
        }}
      >
        <Tabs
          value={value}
          className="configure-action-tab"
          onChange={handleChange}
          aria-label="Free form assistants"
          sx={{
            width: { xs: "100%", sm: "auto" }, // Full width on small screens
            marginBottom: { xs: 1, sm: 0 }, // Add margin below tabs on small screens
          }}
        >
          {TabOptions?.map((item, index) => (
            <Tab label={item} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>

        {value === 0 && (
          <Button
            variant="contained"
            size="small"
            className="primary-button"
            disableRipple
            sx={{
              height: "44px",
              minWidth: "44px",
              marginLeft: { xs: 0, sm: "auto" }, // Center on small screens, align right on large
              alignSelf: { xs: "flex-end", sm: "auto" }, // Center button on small screens
            }}
            onClick={() => {
              setIsOpenDrawer(true);
            }}
          >
            <EditIcon sx={{ fontSize: "22px" }} />
          </Button>
        )}
      </Box>

      {loading && (
        <Box
          sx={{
            height: "calc(100vh - 200px)",
          }}
          className="center"
        >
          <CircularProgress />
        </Box>
      )}

      {!loading && value === 0 && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box
                className="box-container"
                sx={{ minHeight: "calc(100vh - 180px)" }}
              >
                <Typography
                  variant="h3"
                  //   component="label"
                  sx={{
                    fontSize: "18px !important",
                    color: "var(--color-theme-secondary) !important",
                    marginBottom: "12px",
                    // fontWeight: "500 !important",
                  }}
                >
                  Site Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body1" component="label">
                      Name
                    </Typography>
                    <TextField
                      disabled={true}
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={projectData?.name || "-"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body1" component="label">
                      Description
                    </Typography>

                    <TextField
                      fullWidth
                      disabled={true}
                      id="city"
                      label=""
                      multiline
                      maxRows={3}
                      variant="outlined"
                      size="small"
                      value={projectData?.description || "-"}
                    />

                    {/* <Box className="rich-editor view">
                      <ReactQuill
                        theme="snow"
                        value={projectData?.description}
                        modules={{ toolbar: false }}
                        readOnly={true}
                      />
                    </Box> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body1"
                        component="label"
                        sx={{ width: "auto !important" }}
                      >
                        ID
                      </Typography>
                      <Tooltip title="Unique Identifier for the site if any">
                        <span>
                          <FaInfoCircle color="var(--color-theme-secondary)" />
                        </span>
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      disabled={true}
                      variant="outlined"
                      size="small"
                      value={projectData?.projectid || "-"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body1"
                        component="label"
                        sx={{ width: "auto !important" }}
                      >
                        Notification radius (ft)
                      </Typography>
                      <Tooltip title="The radius around the site when notifications can be triggered on the mobile app">
                        <span>
                          <FaInfoCircle color="var(--color-theme-secondary)" />
                        </span>
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      disabled={true}
                      variant="outlined"
                      size="small"
                      value={projectData?.radius || "-"}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography variant="body1" component="label">
                      Location
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={true}
                      id="city"
                      label=""
                      variant="outlined"
                      size="small"
                      value={projectData?.location || "-"}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body1"
                        component="label"
                        sx={{ width: "auto !important" }}
                      >
                        Notification Description
                      </Typography>
                      <Tooltip title="The notification that will be displayed when someone enters site defined by the radius above">
                        <span>
                          <FaInfoCircle color="var(--color-theme-secondary)" />
                        </span>
                      </Tooltip>
                    </Box>

                    <TextField
                      fullWidth
                      disabled={true}
                      id="city"
                      label=""
                      multiline
                      maxRows={3}
                      variant="outlined"
                      size="small"
                      value={projectData?.notification_description || "-"}
                    />

                    {/* <Box className="rich-editor view">
                      <ReactQuill
                        theme="snow"
                        value={projectData?.notification_description}
                        modules={{ toolbar: false }}
                        readOnly={true}
                      />
                    </Box> */}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography variant="body1" component="label">
                      Employees
                    </Typography>
                    <Select
                      //   styles={customStyles}
                      options={users}
                      isMulti
                      name="kbs"
                      size="small"
                      fullWidth
                      className="basic-multi-select form-disabled"
                      value={projectData?.employees?.map((item) => ({
                        label: `${item.first_name} ${item.last_name}`,
                        value: item.id,
                      }))}
                      classNamePrefix="select"
                      components={{
                        IndicatorSeparator: () => null, // Hide the indicator separator
                        DropdownIndicator: () => null, // Hide the dropdown arrow
                        ClearIndicator: () => null, // Hide the clear button
                        MultiValueRemove: () => null,
                      }}
                      placeholder="-"
                    ></Select>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                className="box-container"
                sx={{ minHeight: "calc(100vh - 180px)" }}
              >
                <Typography
                  variant="h3"
                  //   component="label"
                  sx={{
                    fontSize: "18px !important",
                    color: "var(--color-theme-secondary) !important",
                    marginBottom: "12px",
                    // fontWeight: "500 !important",
                  }}
                >
                  Safety Related Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body1"
                        component="label"
                        sx={{ width: "auto !important" }}
                      >
                        Report notification description
                      </Typography>
                      <Tooltip title=" Enter the text that will be sent as notification when someone reports an incident, hazard, near miss etc on the site">
                        <span>
                          <FaInfoCircle color="var(--color-theme-secondary)" />
                        </span>
                      </Tooltip>
                    </Box>

                    <TextField
                      fullWidth
                      disabled={true}
                      id="city"
                      label=""
                      multiline
                      maxRows={3}
                      variant="outlined"
                      size="small"
                      value={projectData?.safety_description || "-"}
                    />

                    {/* <Box className="rich-editor view">
                      <ReactQuill
                        theme="snow"
                        value={projectData?.safety_description}
                        modules={{ toolbar: false }}
                        readOnly={true}
                      />
                    </Box> */}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body1"
                        component="label"
                        sx={{ width: "auto !important" }}
                      >
                        Safety Management team
                      </Typography>
                      <Tooltip title="Users assigned to site as Safety manager and who need to get notified and alerted of safety incidents or observations">
                        <span>
                          <FaInfoCircle color="var(--color-theme-secondary)" />
                        </span>
                      </Tooltip>
                    </Box>
                    <Select
                      //   styles={customStyles}
                      isMulti
                      name="kbs"
                      size="small"
                      fullWidth
                      className="basic-multi-select form-disabled"
                      value={projectData?.safety_management_team?.map(
                        (item) => ({
                          label: `${item.first_name} ${item.last_name}`,
                        })
                      )}
                      classNamePrefix="select"
                      components={{
                        IndicatorSeparator: () => null, // Hide the indicator separator
                        DropdownIndicator: () => null, // Hide the dropdown arrow
                        ClearIndicator: () => null, // Hide the clear button
                        MultiValueRemove: () => null,
                      }}
                      placeholder="-"
                    ></Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body1"
                        component="label"
                        sx={{ width: "auto !important" }}
                      >
                        Project Safety Docs
                      </Typography>
                      <Tooltip title="Site specific JSA, JHA, Standard operating procedure documents that will be referenced by Safety buddy in the mobile app for any safety related questions">
                        <span>
                          <FaInfoCircle color="var(--color-theme-secondary)" />
                        </span>
                      </Tooltip>
                    </Box>
                    <Box sx={{ padding: "8px" }}>
                      {Object.keys(projectData?.project_safety_docs || {})
                        ?.length > 0 ? (
                        <SimpleTreeView
                          defaultCollapseIcon={<ExpandMoreIcon />}
                          sx={{ height: "100%", flexGrow: 1 }}
                        >
                          {Object.keys(
                            projectData?.project_safety_docs || {}
                          ).map((key, keyIndex) => (
                            <TreeItem
                              key={keyIndex}
                              itemId={keyIndex}
                              label={
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: "16px !important",
                                    color: "text.secondary",
                                    fontWeight: "500 !important",
                                  }}
                                >
                                  {kbs.find((item) => +item.id === +key)
                                    ?.label || key}
                                </Typography>
                              }
                            >
                              {projectData?.project_safety_docs[key]?.length >
                              0 ? (
                                projectData?.project_safety_docs[key].map(
                                  (item, index) => (
                                    <TreeItem
                                      key={index}
                                      itemId={`${key}-${index}`}
                                      label={
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontSize: "16px !important",
                                            color: "text.secondary",
                                          }}
                                        >
                                          {item?.filename}
                                        </Typography>
                                      }
                                    />
                                  )
                                )
                              ) : (
                                <TreeItem
                                  key={`${key}-empty`}
                                  itemId={`${key}-empty`}
                                  label={
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: "text.secondary",
                                        fontSize: "16px !important",
                                        marginLeft: "24px",
                                      }}
                                    >
                                      No files available
                                    </Typography>
                                  }
                                />
                              )}
                            </TreeItem>
                          ))}
                        </SimpleTreeView>
                      ) : (
                        <Typography
                          style={{
                            fontSize: "16px", // Small font size
                            color: "#757575", // Neutral gray color
                          }}
                        >
                          No Site Safety Docs Found
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      {!loading && value === 1 && (
        <TeamMembersCallouts
          safetyTeamm={projectData?.safety_management_team?.map(
            (item) => item.id
          )}
          selectedUsers={projectData?.employees?.map((item) => item.id)}
          setValue={setValue}
          fetchProjectDetails={fetchProjectDetails}
        />
      )}
      {!loading && value === 2 && <Messages />}

      {isOpenDrawer && (
        <CreateProject
          drawerState={"edit"}
          setIsOpenDrawer={setIsOpenDrawer}
          isOpenDrawer={isOpenDrawer}
          fetchAllProject={fetchProjectDetails}
          selectedRow={projectData}
        />
      )}
    </Box>
  );
}

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  IconButton,
  ListItem,
  Stack,
  Tooltip,
  List,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RiAddCircleLine } from "react-icons/ri";
import DataGridTable from "../../dataGrid";
import { getAllProject } from "../../../../redux/slices/tenant.slice";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import EyeIcon from "../../../../assets/icons/eye.svg";
import CreateProject from "./CreateProject";
import Pencil from "../../../../assets/icons/pencil.svg";
import DeleteIcon from "../../../../assets/icons/deleteRed.svg";
import DeleteConfigureAction from "../ConfigureAction/DeleteConfigureAction";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { MdRemoveRedEye } from "react-icons/md";
import logAnalyticsEvent from "../../../../firebase/analyticsLogger";

// import { getPerformance, trace } from "firebase/performance";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

// const performance = getPerformance();
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    // backgroundColor: "#fff", // White background
    // color: "#000", // Black text color for readability
    boxShadow: theme.shadows[1], // Subtle shadow for a clean look
    fontSize: "1rem", // Slightly larger font
  },
  // [`& .MuiTooltip-arrow`]: {
  //   color: "#fff", // Match the arrow's color with the tooltip background
  // },
}));
const Project = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [totalRow, setTotalRow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [sortKey, setSortKey] = useState();
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [project, setProject] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const fetchAllProject = async () => {
    setIsLoading(true);
    // const networkTrace = trace(performance, "load_projects");
    // networkTrace.start(); // Start the trace
    try {
      let response = await dispatch(
        getAllProject({
          page_number: paginationModel.page + 1,
          page_size: paginationModel.pageSize,
          sort: sortKey,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setProject(payload?.data);
        setTotalRow(payload?.total_object);
      } else
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // networkTrace.stop();
    }
  };

  useEffect(() => {
    fetchAllProject();
  }, [paginationModel, sortKey]);

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#000000" }}
              // onClick={() => {
              //   setIsOpenDrawer(true);
              //   setDrawerState("view");
              //   setSelectedRowData(params.row);
              // }}
              onClick={() => navigate(`/project/${params.row.id}/view`)}
            >
              <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title="Edit">
            <IconButton sx={{ color: "#000000", padding: "0px" }}>
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
                onClick={() => {
                  setDrawerState("edit");
                  setIsOpenDrawer(true);
                  setViewOpen(params.row);
                  setSelectedRowData(params.row);
                }}
              />
            </IconButton>
          </Tooltip> */}

          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000", padding: "0" }}
              onClick={() => {
                setIsDelete(true);
                setSelectedRowData(params.row);
                // getKbValues();
              }}
            >
              <img
                alt="Delete Details"
                src={DeleteIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  function DataCell(params) {
    return moment(params?.value).format("MM-DD-yyyy");
  }
  const MembersColumn = ({ members }) => {
    if (members && members.length > 0) {
      return (
        <Box className="custom-avatar">
          <StyledTooltip
            title={
              <List disablePadding>
                {members?.map((member, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      fontSize: "0.9rem",
                      padding: "4px 2px",
                      display: "list-item", // Ensures list-style-like behavior
                    }}
                  >
                    {index + 1}. {member?.first_name + " " + member?.last_name}
                  </ListItem>
                ))}
              </List>
            }
            arrow
            placement="left" // Tooltip opens to the left
          >
            <AvatarGroup max={3}>
              {members.map((member, index) => (
                <Avatar key={index} />
              ))}
            </AvatarGroup>
          </StyledTooltip>
        </Box>
      );
    } else {
      return "-";
    }
  };

  const columns = [
    {
      field: "projectid",
      headerName: "Site Id",
      flex: 1,
      minWidth: 130,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "id")}
        >
          Site Id
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "name")}
        >
          Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    // {
    //   field: "description",
    //   headerName: "Description",
    //   minWidth: 300,
    //   renderHeader: () => (
    //     <div
    //       style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    //       onClick={(e) => handleSorting(e, "description")}
    //     >
    //       Description
    //       <SwapVertIcon style={{ marginLeft: 4 }} />
    //     </div>
    //   ),
    // },
    {
      field: "employees",
      headerName: "Employees",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          Employees
        </div>
      ),
      renderCell: ({ row }) => <MembersColumn members={row?.employees} />,
    },
    {
      field: "safety_management_team",
      headerName: " Safety Mnagament Team",
      flex: 1,
      minWidth: 240,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          Safety Managament Team
        </div>
      ),
      renderCell: ({ row }) => (
        <MembersColumn members={row?.safety_management_team} />
      ),
    },
    {
      field: "radius",
      headerName: "Radius",
      minWidth: 100,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "radius")}
        >
          Radius
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: DataCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      flex: 1,
      renderCell: DataCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
      minWidth: 120,
    },
  ];

  return (
    <>
      <Box className="content-header justify-content-end kb-list-header mb-4">
        <Box variant="div" component="div" className="content-header-right">
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              setDrawerState("create");
              setIsOpenDrawer(true);
              setSelectedRowData({});
            }}
          >
            CREATE Site
          </Button>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <DataGridTable
          data={project}
          columns={columns}
          setPaginationModel={setPaginationModel}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalRow={totalRow}
        />
      </Box>

      {isOpenDrawer && (
        <CreateProject
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          setIsOpenDrawer={setIsOpenDrawer}
          isOpenDrawer={isOpenDrawer}
          fetchAllProject={fetchAllProject}
          viewOpen={viewOpen}
          selectedRow={selectedRowData}
        />
      )}

      {isDelete && (
        <DeleteConfigureAction
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          selectedRowData={selectedRowData}
          isLoading={isLoading}
          fetchAllKnowledgeBase={fetchAllProject}
          kbmodule={"project"}
        />
      )}
    </>
  );
};
export default Project;

import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function ImageSlider({ images, imageToken }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: 450,
        height: 300,
        margin: "20px auto",
        overflow: "hidden",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {/* Image */}
      <Box
        component="img"
        src={`${images[currentIndex]}?${imageToken}`}
        alt={`Image ${currentIndex + 1}`}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: 2,
        }}
      />

      {/* Previous Button */}

      {images.length > 1 && (
        <>
          <IconButton
            onClick={handlePrev}
            sx={{
              position: "absolute",
              top: "50%",
              left: "8px",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              color: "black",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              boxShadow: 1,
              padding: "4px", // Smaller padding for compact size
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
            }}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>

          {/* Next Button */}
          <IconButton
            onClick={handleNext}
            sx={{
              position: "absolute",
              top: "50%",
              right: "8px",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              color: "black",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              boxShadow: 1,
              padding: "4px", // Smaller padding for compact size
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
            }}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </>
      )}
    </Box>
  );
}

export default ImageSlider;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Checkbox,
  Avatar,
  DialogContent,
  Paper,
  DialogActions,
  Autocomplete,
  IconButton,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useDispatch } from "react-redux";

import {
  getFormInstanceByid,
  updateFormInstance,
} from "../../../redux/slices/forms.slice";

import { setSnackbar } from "../../../redux/slices/common.slice";
import {
  getAllProject,
  ProjectLocationAutoCompleteSuggesstionThunk,
} from "../../../redux/slices/tenant.slice";

import Select from "react-select";

import { PhotoCamera } from "@mui/icons-material";
import CustomizedDialog from "../Dialog";
import { getAllServiceUnit } from "../../../redux/slices/serviceUnit.slice";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import logAnalyticsEvent from "../../../firebase/analyticsLogger";

const customStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};
const EditFormModal = ({
  mode,
  isOpenDrawer,
  setIsOpenDrawer,
  fetchAllTasks = () => {},
  templateId,
  title = "Start Inspection",
  empId = null,
}) => {
  const [formData, setFormData] = useState([]);
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();

  const [parentFormId, setParentFormId] = useState(null);
  const [errors, setErrors] = useState({});
  const [locationOption, setLocationOption] = useState([]);
  const [serviceUnitOptions, setServiceUnitOptions] = useState();
  const [serviceUnit, setServiceUnit] = useState();
  const [projectOptions, setProjectOptions] = useState();
  const [project, setProject] = useState();
  const [formType, setFormType] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const users = useSelector((state) => state.common.users);
  const user = useSelector((state) => state.user);

  const fetchAllProject = async () => {
    setLoading(true);
    // const networkTrace = trace(performance, "load_projects");
    // networkTrace.start(); // Start the trace
    try {
      let response = await dispatch(
        getAllProject({
          page_number: 1,
          page_size: 100000,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setProjectOptions(
          payload?.data?.map((item, idx) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } else
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllProject();
  }, []);
  const fetchAllserviceUnit = async () => {
    try {
      let response = await dispatch(
        getAllServiceUnit({
          page_number: 1,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setServiceUnitOptions(
          payload?.data?.map((item, idx) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }
  };
  useEffect(() => {
    fetchAllserviceUnit();
  }, []);

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setFormData([]);
    setServiceUnit();
  };
  const handleInputChange = (
    value,
    questionId,
    sectionIndex,
    questionIndex
  ) => {
    const updatedFormData = [...formData];

    updatedFormData[sectionIndex].questions[questionIndex].answer = value;

    setFormData(updatedFormData);
  };

  const handleDeleteImage = (
    indexToDelete,
    questionId,
    sectionIndex,
    questionIndex
  ) => {
    const updatedFormData = [...formData];

    // Get the current list of images
    const currentImages =
      updatedFormData[sectionIndex].questions[questionIndex].images || [];

    // Filter out the image that needs to be deleted based on index
    const updatedImages = currentImages.filter((_, i) => i !== indexToDelete);

    // Update the question's images
    updatedFormData[sectionIndex].questions[questionIndex].images =
      updatedImages;

    setFormData(updatedFormData);
  };

  const handleMediaChange = (
    newImages,
    questionId,
    sectionIndex,
    questionIndex
  ) => {
    const updatedFormData = [...formData];

    // Get the existing images, or initialize as an empty array if undefined
    const existingImages =
      updatedFormData[sectionIndex].questions[questionIndex].images || [];

    // Append the new images to the existing ones
    updatedFormData[sectionIndex].questions[questionIndex].images = [
      ...existingImages,
      ...newImages,
    ];

    setFormData(updatedFormData);
  };

  const handleVideoChange = (
    newVideos,
    questionId,
    sectionIndex,
    questionIndex
  ) => {
    const updatedFormData = [...formData];

    // Get the existing images, or initialize as an empty array if undefined
    const existingVideos =
      updatedFormData[sectionIndex].questions[questionIndex].videos || [];

    // Append the new images to the existing ones
    updatedFormData[sectionIndex].questions[questionIndex].videos = [
      ...existingVideos,
      ...newVideos,
    ];

    setFormData(updatedFormData);
  };

  const handleDeleteVideo = (
    indexToDelete,
    questionId,
    sectionIndex,
    questionIndex
  ) => {
    const updatedFormData = [...formData];

    // Get the current list of images
    const currentVideos =
      updatedFormData[sectionIndex].questions[questionIndex].videos || [];

    // Filter out the image that needs to be deleted based on index
    const updatedVideos = currentVideos.filter((_, i) => i !== indexToDelete);

    // Update the question's images
    updatedFormData[sectionIndex].questions[questionIndex].videos =
      updatedVideos;

    setFormData(updatedFormData);
  };

  const handleRadioChange = (e, questionId, sectionIndex, questionIndex) => {
    const { value } = e.target;

    const updatedFormData = [...formData];

    updatedFormData[sectionIndex].questions[questionIndex].group_value = value;

    setFormData(updatedFormData);
  };

  const handleCheckboxChange = (e, sectionIndex, questionIndex) => {
    const { checked } = e.target;

    // Create a deep copy of the form data to avoid directly mutating the state.
    const updatedFormData = [...formData];

    // Update the is_selected field for the checkbox question.
    updatedFormData[sectionIndex].questions[questionIndex].is_selected =
      checked;

    // Update the state.
    setFormData(updatedFormData);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!selectedUser && formType === "work_permit")
      newErrors["user"] = "Required";
    formData.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.required) {
          if (question.answer_type === "CheckBox") {
            // Checkbox can be unchecked, handle accor dingly
            if (!question.is_selected) {
              newErrors[question.question_id] = `Required`;
            }
          } else if (question.answer_type === "Media") {
            if (question?.images.length === 0) {
              newErrors[question.question_id] = `Required`;
            }
          } else if (question.answer_type === "Video") {
            if (question?.videos.length === 0) {
              newErrors[question.question_id] = `Required`;
            }
          } else if (question.answer_type === "Radio Buttons") {
          } else if (!question.answer) {
            newErrors[question.question_id] = `Required`;
          }
        }
      });
    });
    setErrors(newErrors);
    scrollToError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const scrollToError = (errors) => {
    const errorField = Object.keys(errors)[0]; // Get the first error field

    const errorElement = document.getElementById(errorField); // Find the DOM element of that field

    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to the error field
    }
  };
  function removeUntilFirstComma(str) {
    const commaIndex = str.indexOf(",");
    if (commaIndex !== -1) {
      return str.substring(commaIndex + 1); // +1 to skip the comma itself
    }
    return str; // return original string if no comma is found
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    const updatedJson = formData?.map((section, sectionIndex) => {
      return {
        ...section,
        questions: section.questions.map((question, questionIndex) => {
          if (question.answer_type === "Media") {
            return {
              ...question,
              images:
                question.images?.map((img) => {
                  if (img.base) {
                    return {
                      ...img,
                      base: removeUntilFirstComma(img.base),
                    };
                  }
                  return img; // Return unchanged image if no base is present
                }) || [],
            };
          } else if (question.answer_type === "Video") {
            return {
              ...question,
              videos:
                question.videos?.map((img) => {
                  if (img.base) {
                    return {
                      ...img,
                      base: removeUntilFirstComma(img.base),
                    };
                  }
                  return img; // Return unchanged image if no base is present
                }) || [],
            };
          }
          return question; // Return unchanged question if answer_type is not "Media"
        }),
      };
    });

    try {
      let response = await dispatch(
        updateFormInstance({
          formData: {
            form: parentFormId,
            submission_json: updatedJson,
            service_unit: serviceUnit?.value,
            user: selectedUser ? selectedUser?.value : undefined,
          },
          formId: templateId,
        })
      );
      const { payload } = response;
      if (payload && payload.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Data updated Succesfully.",
          })
        );
        logAnalyticsEvent("update_form_submission", {
          user_id: user?.data?.user?.id,
        });
        fetchAllTasks();
        handleCloseDrawer();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  const fetchFormInstanceByid = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        getFormInstanceByid({ formId: templateId })
      );
      const { payload } = response;
      if (payload?.status) {
        setFormData(payload?.data?.submission_json);
        setToken(payload?.token);
        setParentFormId(payload?.data?.form);
        setFormType(payload?.data?.form_type);
        if (payload?.data?.form_type === "work_permit")
          setSelectedUser({
            label: `${payload?.data?.user?.first_name} ${payload?.data?.user?.last_name}`,
            value: payload?.data?.user?.id,
          });
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFormInstanceByid();
  }, [templateId, isOpenDrawer]);

  let timeOut = null;
  const handleAutocompleteChange = (event, values) => {
    if (values !== "") {
      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: values,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
        } catch (error) {}
      }, 500);
    }
  };

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // slots={{ backdrop: Backdrop }}
        title={title}
        open={isOpenDrawer}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        {loading ? (
          <Box
            sx={{
              margin: "auto",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {" "}
            <DialogContent>
              {formType === "work_permit" && (
                <>
                  <Typography variant="body1" component="label">
                    Select User
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                  <Select
                    options={users}
                    value={selectedUser}
                    onChange={(e) => {
                      setSelectedUser(e);
                      setErrors((prevErrors) => {
                        const newErrors = { ...prevErrors };
                        delete newErrors["user"];
                        return newErrors;
                      });
                    }}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                    placeholder="Select Users"
                    isDisabled={mode === "view"}
                  />
                  {errors["user"] && (
                    <div className="form-error-message">{errors["user"]}</div>
                  )}
                  <br />
                </>
              )}

              <form ref={formRef}>
                {formData &&
                  formData.map((section, sectionIndex) => (
                    <Accordion key={section.section} defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="expand-icon" />}
                        className="accordion-summary-background accordion-summary-root"
                      >
                        <Typography className="accordion-summary-typography">
                          {section.section_name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className={
                          mode === "view"
                            ? "form-disabled accordion-details"
                            : "accordion-details"
                        }
                      >
                        <Grid container spacing={3}>
                          {section.questions.map((question, questionIndex) => (
                            <Grid
                              item
                              lg={question.width || 6}
                              xs={12}
                              key={question.question_id}
                            >
                              <Typography
                                variant="label"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px !important",
                                }}
                              >
                                {question.label}
                                {question.required && (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </Typography>

                              {question.answer_type === "Text Field" && (
                                <>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Your answer here"
                                    required={question.required}
                                    name={question.question_id}
                                    id={question.question_id}
                                    size="small"
                                    value={question.answer || ""}
                                    onChange={(e) => {
                                      handleInputChange(
                                        e.target.value,
                                        question.question_id,
                                        sectionIndex,
                                        questionIndex
                                      );
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                    }}
                                    error={!!errors[question.question_id]} // Shows error if validation fails
                                  />
                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}

                              {question.answer_type === "Date" && (
                                <>
                                  <TextField
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    required={question.required}
                                    name={question.question_id}
                                    id={question.question_id}
                                    value={question.answer || ""}
                                    onChange={(e) => {
                                      handleInputChange(
                                        e.target.value,
                                        question.question_id,
                                        sectionIndex,
                                        questionIndex
                                      );
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                    }}
                                    error={!!errors[question.question_id]}
                                  />
                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.answer_type === "Number" && (
                                <>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    required={question.required}
                                    name={question.question_id}
                                    id={question.question_id}
                                    value={question.answer || ""}
                                    onChange={(e) => {
                                      handleInputChange(
                                        e.target.value,
                                        question.question_id,
                                        sectionIndex,
                                        questionIndex
                                      );
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                    }}
                                    error={!!errors[question.question_id]}
                                  />
                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.answer_type === "Time" && (
                                <>
                                  <TextField
                                    type="time"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    required={question.required}
                                    name={question.question_id}
                                    id={question.question_id}
                                    value={question.answer || ""}
                                    onChange={(e) => {
                                      handleInputChange(
                                        e.target.value,
                                        question.question_id,
                                        sectionIndex,
                                        questionIndex
                                      );
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                    }}
                                    error={!!errors[question.question_id]}
                                  />
                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.answer_type === "Date & Time" && (
                                <>
                                  <TextField
                                    type="datetime-local"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    required={question.required}
                                    name={question.question_id}
                                    id={question.question_id}
                                    value={question.answer || ""}
                                    onChange={(e) => {
                                      handleInputChange(
                                        e.target.value,
                                        question.question_id,
                                        sectionIndex,
                                        questionIndex
                                      );
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                    }}
                                    error={!!errors[question.question_id]}
                                  />
                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}

                              {question.answer_type === "Location" && (
                                <>
                                  <Autocomplete
                                    className="project-location-autocomplete"
                                    freeSolo
                                    size="small"
                                    value={question.answer}
                                    onInputChange={(event, values) => {
                                      handleAutocompleteChange(event, values);
                                    }}
                                    onChange={(e, valuee) => {
                                      handleInputChange(
                                        valuee.label,
                                        question.question_id,
                                        sectionIndex,
                                        questionIndex
                                      );
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                    }}
                                    error={!!errors[question.question_id]}
                                    options={locationOption}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label=""
                                        fullWidth
                                        variant="outlined"
                                        // required={question.required}
                                        name={question.question_id}
                                        id={question.question_id}
                                        // value={question.answer || ""}
                                        placeholder="Please, Enter location"
                                      />
                                    )}
                                  />
                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}

                              {question.answer_type === "Radio Buttons" && (
                                <FormControl
                                  component="fieldset"
                                  fullWidth
                                  required
                                >
                                  <RadioGroup
                                    name={`${question.question_id}`}
                                    id={question.question_id}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                    value={question.group_value || ""}
                                    onChange={(e) =>
                                      handleRadioChange(
                                        e,
                                        question.question_id,
                                        sectionIndex,
                                        questionIndex
                                      )
                                    }
                                  >
                                    {question.options.map((option, index) => (
                                      <FormControlLabel
                                        key={index}
                                        value={option}
                                        control={<Radio />}
                                        label={option}
                                      />
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                              )}

                              {question.answer_type === "Media" && (
                                <>
                                  {question.images &&
                                    question.images.length > 0 && (
                                      <Box sx={{ mt: 2 }}>
                                        {/* Display all images in a single grid */}
                                        <Grid container spacing={2}>
                                          {question.images.map(
                                            (image, index) => (
                                              <Grid item xs={2} key={index}>
                                                {image.img_url ? (
                                                  <Box
                                                    sx={{
                                                      position: "relative",
                                                      borderRadius: "8px",
                                                      overflow: "hidden",
                                                    }}

                                                    // target="_blank"
                                                  >
                                                    {/* Display the image, whether it is img_url or base64 */}
                                                    <Link
                                                      to={`${image.img_url}?${token}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      <img
                                                        src={`${image.img_url}?${token}`}
                                                        alt={`something went wrong`}
                                                        style={{
                                                          width: "100%", // Set the desired width
                                                          height: "100%", // Adjust as necessary
                                                          objectFit: "cover",
                                                          borderRadius: "8px",
                                                        }}
                                                      />
                                                    </Link>

                                                    {/* Delete Icon */}
                                                    <IconButton
                                                      size="small"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDeleteImage(
                                                          index,
                                                          question.question_id,
                                                          sectionIndex,
                                                          questionIndex
                                                        );
                                                      }}
                                                      sx={{
                                                        position: "absolute",
                                                        top: 8,
                                                        right: 8,
                                                        backgroundColor:
                                                          "rgba(0, 0, 0, 0.5)",
                                                        color: "white",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 0, 0, 0.8)",
                                                        },
                                                      }}
                                                    >
                                                      <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                  </Box>
                                                ) : (
                                                  <Box
                                                    sx={{
                                                      position: "relative",
                                                      borderRadius: "8px",
                                                      overflow: "hidden",
                                                    }}

                                                    // target="_blank"
                                                  >
                                                    {/* Display the image, whether it is img_url or base64 */}
                                                    <img
                                                      src={image.base}
                                                      alt={`something went wrong`}
                                                      style={{
                                                        width: "100%", // Set the desired width
                                                        height: "100%", // Adjust as necessary
                                                        objectFit: "cover",
                                                        borderRadius: "8px",
                                                      }}
                                                    />

                                                    {/* Delete Icon */}
                                                    <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                        handleDeleteImage(
                                                          index,
                                                          question.question_id,
                                                          sectionIndex,
                                                          questionIndex
                                                        )
                                                      }
                                                      sx={{
                                                        position: "absolute",
                                                        top: 8,
                                                        right: 8,
                                                        backgroundColor:
                                                          "rgba(0, 0, 0, 0.5)",
                                                        color: "white",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 0, 0, 0.8)",
                                                        },
                                                      }}
                                                    >
                                                      <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                  </Box>
                                                )}
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Box>
                                    )}
                                  <Button
                                    variant="outlined"
                                    component="label"
                                    startIcon={<PhotoCamera />}
                                    sx={{
                                      mt: 1,
                                      mb: 2,
                                      color: "#036185",
                                      width: "100% ",
                                    }}
                                  >
                                    Upload Images
                                    <input
                                      type="file"
                                      accept="image/*"
                                      hidden
                                      name={question.question_id}
                                      id={question.question_id}
                                      multiple // Allow multiple files
                                      onChange={(e) => {
                                        const files = e.target.files;
                                        setErrors((prevErrors) => {
                                          const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                          delete newErrors[
                                            question.question_id
                                          ]; // Remove the error for the specific question using its name
                                          return newErrors; // Return the updated errors object
                                        });
                                        const imageList = Array.from(files).map(
                                          (file) => {
                                            const reader = new FileReader();
                                            return new Promise((resolve) => {
                                              reader.onloadend = () => {
                                                resolve({
                                                  base: reader.result,
                                                  extension:
                                                    file.type.split("/")[1], // Get the file extension
                                                  img_url: null,
                                                });
                                              };
                                              reader.readAsDataURL(file);
                                            });
                                          }
                                        );

                                        Promise.all(imageList).then(
                                          (images) => {
                                            handleMediaChange(
                                              images,
                                              question.question_id,
                                              sectionIndex,
                                              questionIndex
                                            );
                                          }
                                        );
                                      }}
                                    />
                                  </Button>

                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}

                              {question.answer_type === "Video" && (
                                <>
                                  {question.videos &&
                                    question.videos.length > 0 && (
                                      <Box sx={{ mt: 2 }}>
                                        {/* Display all videos in a single grid */}
                                        <Grid container spacing={2}>
                                          {question.videos.map(
                                            (video, index) => (
                                              <Grid item xs={2} key={index}>
                                                {video.video_url ? (
                                                  <Box
                                                    sx={{
                                                      position: "relative",
                                                      borderRadius: "8px",
                                                      overflow: "hidden",
                                                    }}
                                                  >
                                                    {/* Display the video if the URL exists */}
                                                    <Link
                                                      to={`${video.video_url}?${token}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      <video
                                                        src={`${video.video_url}?${token}`}
                                                        alt="video preview"
                                                        controls
                                                        style={{
                                                          width: "100%", // Set the desired width
                                                          height: "100%", // Adjust as necessary
                                                          objectFit: "cover",
                                                          borderRadius: "8px",
                                                        }}
                                                      />
                                                    </Link>

                                                    {/* Delete Icon */}
                                                    <IconButton
                                                      size="small"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDeleteVideo(
                                                          index,
                                                          question.question_id,
                                                          sectionIndex,
                                                          questionIndex,
                                                          "video" // Pass media type for deleting videos
                                                        );
                                                      }}
                                                      sx={{
                                                        position: "absolute",
                                                        top: 8,
                                                        right: 8,
                                                        backgroundColor:
                                                          "rgba(0, 0, 0, 0.5)",
                                                        color: "white",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 0, 0, 0.8)",
                                                        },
                                                      }}
                                                    >
                                                      <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                  </Box>
                                                ) : (
                                                  <Box
                                                    sx={{
                                                      position: "relative",
                                                      borderRadius: "8px",
                                                      overflow: "hidden",
                                                    }}
                                                  >
                                                    {/* Display the video preview from base64 if URL doesn't exist */}
                                                    <video
                                                      src={video.base}
                                                      alt="video preview"
                                                      controls
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        borderRadius: "8px",
                                                      }}
                                                    />

                                                    {/* Delete Icon */}
                                                    <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                        handleDeleteVideo(
                                                          index,
                                                          question.question_id,
                                                          sectionIndex,
                                                          questionIndex
                                                        )
                                                      }
                                                      sx={{
                                                        position: "absolute",
                                                        top: 8,
                                                        right: 8,
                                                        backgroundColor:
                                                          "rgba(0, 0, 0, 0.5)",
                                                        color: "white",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 0, 0, 0.8)",
                                                        },
                                                      }}
                                                    >
                                                      <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                  </Box>
                                                )}
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Box>
                                    )}

                                  <Button
                                    variant="outlined"
                                    component="label"
                                    startIcon={<PhotoCamera />}
                                    sx={{
                                      mt: 1,
                                      mb: 2,
                                      color: "#036185",
                                      width: "100%",
                                    }}
                                  >
                                    Upload Videos
                                    <input
                                      type="file"
                                      accept="video/*"
                                      hidden
                                      name={question.question_id}
                                      id={question.question_id}
                                      multiple
                                      onChange={(e) => {
                                        const files = e.target.files;
                                        setErrors((prevErrors) => {
                                          const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                          delete newErrors[
                                            question.question_id
                                          ]; // Remove the error for the specific question using its name
                                          return newErrors; // Return the updated errors object
                                        });
                                        const videoList = Array.from(files).map(
                                          (file) => {
                                            const reader = new FileReader();
                                            return new Promise((resolve) => {
                                              reader.onloadend = () => {
                                                resolve({
                                                  base: reader.result, // Read as base64
                                                  extension:
                                                    file.type.split("/")[1], // Get file extension
                                                  video_url: null, // URL is not available until the file is uploaded
                                                });
                                              };
                                              reader.readAsDataURL(file); // Read file as base64
                                            });
                                          }
                                        );

                                        Promise.all(videoList).then(
                                          (videos) => {
                                            handleVideoChange(
                                              videos,
                                              question.question_id,
                                              sectionIndex,
                                              questionIndex
                                            );
                                          }
                                        );
                                      }}
                                    />
                                  </Button>

                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}

                              {question.answer_type === "CheckBox" && (
                                <>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={question.is_selected || false}
                                        id={question.question_id}
                                        sx={{ marginLeft: "10px" }}
                                        onChange={(e) => {
                                          handleCheckboxChange(
                                            e,
                                            sectionIndex,
                                            questionIndex
                                          );
                                          setErrors((prevErrors) => {
                                            const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                            delete newErrors[
                                              question.question_id
                                            ]; // Remove the error for the specific question using its name
                                            return newErrors; // Return the updated errors object
                                          });
                                        }}
                                      />
                                    }
                                  />
                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}

                              {question.answer_type === "Service Unit" && (
                                <>
                                  <Select
                                    options={serviceUnitOptions}
                                    value={
                                      serviceUnit || // Use state value if available
                                      serviceUnitOptions?.find(
                                        (item) =>
                                          item.value === +question?.answer
                                      ) ||
                                      null // Fallback when data loads
                                    }
                                    menuPortalTarget={document.body}
                                    onChange={(e) => {
                                      setServiceUnit(e); // Update state with selected value
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors };
                                        delete newErrors[
                                          `${question.question_id}`
                                        ];
                                        return newErrors;
                                      });

                                      handleInputChange(
                                        e.value,
                                        question.question_id,
                                        sectionIndex,
                                        questionIndex
                                      );
                                    }}
                                    name={`${question.id}`}
                                    id={question.question_id}
                                    styles={customStyles}
                                    placeholder="Select Type"
                                  />

                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}

                              {question.answer_type === "Project" && (
                                <>
                                  <Select
                                    options={projectOptions}
                                    value={
                                      project || // Use state value if available
                                      projectOptions?.find(
                                        (item) =>
                                          item.value === +question?.answer
                                      ) ||
                                      null // Fallback when data loads
                                    }
                                    className={
                                      !projectOptions ? "form-disabled" : ""
                                    }
                                    onChange={(e) => {
                                      setProject(e); // Update state with selected value
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors };
                                        delete newErrors[
                                          `${question.question_id}`
                                        ];
                                        return newErrors;
                                      });
                                      handleInputChange(
                                        e.value,
                                        question.question_id,
                                        sectionIndex,
                                        questionIndex
                                      );
                                    }}
                                    menuPortalTarget={document.body}
                                    name={`${question.question_id}`}
                                    id={question.question_id}
                                    styles={customStyles}
                                    placeholder="Select Project"
                                  />

                                  {errors[question.question_id] && (
                                    <div className="form-error-message">
                                      {errors[question.question_id]}
                                    </div>
                                  )}
                                </>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </form>
            </DialogContent>
            {mode !== "view" && (
              <DialogActions>
                <Box className="modal-footers">
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      className="primary-button"
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      {loading && (
                        <CircularProgress
                          size="1rem"
                          sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                        />
                      )}
                      Save
                    </Button>

                    <Button
                      variant="outlined"
                      className="secondary-button"
                      disabled={loading}
                      onClick={handleCloseDrawer}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Box>
              </DialogActions>
            )}
          </>
        )}
      </CustomizedDialog>
    </>
  );
};

export default EditFormModal;

import React from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { Container, Typography, Button, Box } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block"; // MUI icon for "no access"

const NoAccessPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Redirect to homepage
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "white",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <BlockIcon sx={{ fontSize: 100, color: "#e53935", marginBottom: 2 }} />

        <Typography
          variant="h3"
          component="h1"
          sx={{ marginBottom: 2, fontWeight: "bold" }}
        >
          Access Denied
        </Typography>

        <Typography
          variant="body1"
          sx={{ marginBottom: 4, color: "text.secondary" }}
        >
          You don't have permission to view this page. Please contact your
          administrator if you believe this is an error.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleGoHome}
          sx={{
            textTransform: "none",
            padding: "10px 20px",
          }}
        >
          Back to Home
        </Button>
      </Box>
    </Container>
  );
};

export default NoAccessPage;

import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateProjectThunk } from "../../../../redux/slices/tenant.slice";
import { Navigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import Select from "react-select";
import { FaInfoCircle } from "react-icons/fa";
export default function TeamMembersCallouts({
  safetyTeamm = [],
  selectedUsers = [],
  setValue,
  fetchProjectDetails,
}) {
  const [selectedUserIds, setSelectedUserIds] = useState(selectedUsers); // Array of selected user IDs (values)
  const [safetyTeam, setSafetyTeam] = useState(safetyTeamm); // Array of safety team user IDs (values)
  const [loading, setLoading] = useState(false);
  const users = useSelector((state) => state.common.users);
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const updateProjectDetails = async () => {
    setLoading(true);

    try {
      let response = await dispatch(
        updateProjectThunk({
          safety_management_team: safetyTeam,
          employees: selectedUserIds,
          id: projectId,
        })
      );
      const { payload } = response;
      if (payload && payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Team Members Updated Successfully.",
          })
        );
        fetchProjectDetails();
        setValue(0);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelectionChange = (selectedOptions) => {
    // Extract the selected user IDs (values)
    const userIds = selectedOptions
      ? selectedOptions.map((user) => user.value)
      : [];
    setSelectedUserIds(userIds);

    // Ensure that the safety team only contains users who are also in the selected users list
    setSafetyTeam((prevState) =>
      prevState.filter((userId) => userIds.includes(userId))
    );
  };

  const toggleSafetyTeam = (userValue) => {
    // If the user is in the selected list, toggle their inclusion in the safety team
    if (selectedUserIds.includes(userValue)) {
      setSafetyTeam((prevState) => {
        const updatedSafetyTeam = [...prevState];
        if (updatedSafetyTeam.includes(userValue)) {
          // If already in the safety team, remove it
          return updatedSafetyTeam.filter((id) => id !== userValue);
        } else {
          // Add user to safety team
          updatedSafetyTeam.push(userValue);
          return updatedSafetyTeam;
        }
      });
    }
  };

  const CustomOption = (props) => {
    const { data } = props;
    return (
      <ListItem
        button
        onClick={() => toggleSafetyTeam(data.value)} // Toggle safety team on click
      >
        <ListItemText primary={data.label} />
        {safetyTeam.includes(data.value) && <Typography>✓</Typography>}
      </ListItem>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box className="box-container" sx={{ height: "calc(100vh - 250px)" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1" component="label">
                  Select Employees
                </Typography>
                <Select
                  isMulti
                  options={users}
                  value={users.filter((user) =>
                    selectedUserIds.includes(user.value)
                  )}
                  onChange={handleSelectionChange}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: "40px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#e0f7fa" : "white",
                    }),
                  }}
                  placeholder="Select employees..."
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography
                    variant="body1"
                    component="label"
                    sx={{ width: "auto !important" }}
                  >
                    Safety Management team
                  </Typography>
                  <Tooltip title="Users assigned to site as Safety manager and who need to get notified and alerted of safety incidents or observations">
                    <span>
                      <FaInfoCircle color="var(--color-theme-secondary)" />
                    </span>
                  </Tooltip>
                </Box>
                {users?.filter((item) => selectedUserIds?.includes(item.value))
                  ?.length > 0 ? (
                  <List
                    sx={{
                      border: "1px solid rgb(208, 204, 204)",
                      borderRadius: "5px",
                      margin: "10px 0",
                    }}
                  >
                    <Grid container sx={{ width: "100%" }}>
                      {users
                        ?.filter((item) =>
                          selectedUserIds?.includes(item.value)
                        )
                        .map((user) => (
                          <Grid item xs={12} sm={6}>
                            <ListItem
                              key={user.value}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <ListItemText
                                primary={user.label}
                                sx={{ fontSize: "16px !important" }}
                              />
                              <Checkbox
                                checked={safetyTeam.includes(user.value)} // Check if the user is in the safety team
                                onChange={() => toggleSafetyTeam(user.value)} // Toggle safety team status
                                sx={{
                                  padding: "0", // Remove extra padding/margin of Checkbox
                                  height: "auto", // Ensures checkbox doesn't change layout height
                                  alignSelf: "center", // Centers the checkbox vertically within the box
                                }}
                              />
                            </ListItem>
                          </Grid>
                        ))}
                    </Grid>
                  </List>
                ) : (
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "#757575",
                      marginTop: "2px",
                    }}
                  >
                    Select Employees to Select Safety Mnagement Team.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box className="footer">
        <Button
          className="secondary-footer-btn"
          loading={loading}
          disabled={loading}
          onClick={() => {
            setValue(0);
          }}
        >
          Cancel
        </Button>

        <Button
          className="primary-footer-btn"
          onClick={updateProjectDetails}
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}

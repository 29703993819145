import React, { useState, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import "../../../../styles/dashboard.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";

import CustomizedDialog from "../../Dialog";

import {
  createTraining,
  updateTraining,
} from "../../../../redux/slices/employees.slice";
import logAnalyticsEvent from "../../../../firebase/analyticsLogger";
import { useSelector } from "react-redux";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const CreateTraining = (
  { isOpenDrawer, setIsOpenDrawer, drawerState, values, fetchAlltasks },
  props
) => {
  const [initialValues, setInitialValues] = useState({
    // due_date: "",

    // users: [],
    title: "",
    description: "",
  });

  const validationSchema = yup.object({
    title: yup.string().required("Title is required"),

    description: yup.string().required("Description is required"),
  });

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (valuess) => {
      setIsLoading(true);

      try {
        let response = await dispatch(
          drawerState === "edit"
            ? updateTraining({
                data: {
                  ...valuess,
                },
                id: values?.id,
              })
            : createTraining({
                data: {
                  ...valuess,
                },
              })
        );
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message:
                drawerState === "edit"
                  ? "Training Updated Succesfully."
                  : "Training Created Succesfully.",
            })
          );
          setIsOpenDrawer(false);
          fetchAlltasks();
          formik.resetForm();

          if (drawerState === "create") {
            logAnalyticsEvent("create_training", {
              user_id: user?.data?.user?.id,
            });
          } else {
            logAnalyticsEvent("edit_training", {
              user_id: user?.data?.user?.id,
            });
          }
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });

  const handleCloseDrawer = () => {
    formik.resetForm();
    setIsOpenDrawer(false);
  };
  const setTrainingData = async () => {
    const obj = {
      title: values.title,
      description: values.description,
    };
    formik.setValues(obj);
  };
  useEffect(() => {
    if (drawerState !== "create") setTrainingData();
  }, [drawerState]);

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // slots={{ backdrop: Backdrop }}
        title={
          drawerState === "view"
            ? "Training Detail"
            : drawerState === "create"
            ? "Create Training"
            : "Edit Training"
        }
        open={isOpenDrawer}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent>
          <form className={drawerState === "view" && "form-disabled"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Title
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Title"
                    size="small"
                    name="title"
                    {...formik.getFieldProps("title")}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="form-error-message">
                      {formik.errors.title}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    multiline
                    minRows={5}
                    maxRows={10}
                    variant="outlined"
                    placeholder="Enter Description"
                    size="small"
                    name="description"
                    {...formik.getFieldProps("description")}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="form-error-message">
                      {formik.errors.description}
                    </div>
                  )}
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Due Date
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    type="date"
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Select Due Date"
                    size="small"
                    name="Due Date"
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      }, // Restrict past dates
                    }}
                    {...formik.getFieldProps("due_date")}
                  />
                  {formik.touched.due_date && formik.errors.due_date && (
                    <div className="form-error-message">
                      {formik.errors.due_date}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Assigned To
                  </Typography>
                  <Selectt
                    styles={customStyles}
                    isMulti
                    size="small"
                    fullWidth
                    options={users}
                    value={formik?.values?.users}
                    onChange={(e) => {
                      formik.setFieldValue("users", e);
                    }}
                    classNamePrefix="select"
                    placeholder={"Select Users..."}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPosition="fixed"
                    menuPlacement="top"
                  ></Selectt>
                  {formik.touched.users && formik.errors.users && (
                    <div className="form-error-message">
                      {formik.errors.users}
                    </div>
                  )}
                </Box>
              </Grid> */}
            </Grid>
          </form>
        </DialogContent>
        {drawerState !== "view" && (
          <DialogActions>
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Save
                </Button>

                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </DialogActions>
        )}
      </CustomizedDialog>
    </>
  );
};

export default CreateTraining;

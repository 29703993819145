import {
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Box,
  Tooltip,
  CircularProgress,
  Tabs,
  Tab,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Button,
  IconButton,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/slices/common.slice";
import {
  getAuditTemplates,
  getTenantReportById,
  getTODOList,
} from "../../../redux/slices/tenant.slice";

import Selectt from "react-select";
import {
  ReportStatusOptions,
  Status,
} from "../../../utils/icon/CommonFunctions";
import { FaPlayCircle, FaRegPlayCircle } from "react-icons/fa";
import CreateFormModal from "../forms/createFormModal";
import EditFormModal from "../forms/editFormModal";
import { useNavigate, useParams } from "react-router-dom";
import Pencil from "../../../assets/icons/pencil.svg";

import CustomizedDialog from "../Dialog";
import { updateServiceUnitTask } from "../../../redux/slices/serviceUnit.slice";
import CreateTasks from "../serviceUnit/createTask";
import CreateAudit from "../Audit/createAudit";
import CreateReport from "../createReport";
import { useSelector } from "react-redux";

const todoTypes = [
  { value: "incident_task", label: "Incident Tasks" },
  {
    value: "inspection_task",
    label: "Service Unit Tasks",
  },
  {
    value: "audit_task",
    label: "Incident Audit",
  },
];

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};
const statusOptions = [
  { value: "All", label: "All" },
  { value: "completed", label: "Completed" },
  { value: "pending", label: "Pending" },
  { value: "up_coming", label: "Upcoming" },
];
const TaskList = () => {
  const [todos, setTodos] = useState(null);
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [statusFilter, setStatusFilter] = useState({
    value: "All",
    label: "All",
  });
  const [statusIncidentFilter, setStatusIncidentFilter] = useState({
    value: "All",
    label: "All",
  });
  const [selectedTodo, setSelectedTodo] = useState();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState(false);
  const [reviewCompleteOpen, setReviewCompleteOpen] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState();
  const [taskDrawer, setTaskDrawer] = useState(false);
  const [auditDrawer, setAuditDrawer] = useState(false);
  const [auditTemplates, setAuditTemplates] = useState([]);

  const [reportLayout, setReportLayout] = useState(false);
  const [reportData, setReportData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { empId } = useParams();

  // Fetch TODO list based on active tab (Inspection or Incident tasks)
  const fetchTODOList = async () => {
    setLoading(true);

    let response = await dispatch(
      getTODOList({
        task_type: todoTypes[activeTab]?.value,
        status:
          statusFilter?.value === "All"
            ? undefined
            : statusFilter?.value === "completed"
            ? statusFilter?.value
            : "up_coming",
        sort:
          todoTypes[activeTab]?.value !== "incident_task"
            ? "due_date"
            : undefined,
        report__status:
          statusIncidentFilter?.value === "All"
            ? undefined
            : statusIncidentFilter?.value,
        users: empId,
      })
    );
    const { payload } = response;

    if (payload?.status) {
      // setTodos(payload?.data);
      // setToken(payload?.token);

      let tempData = [];
      if (statusFilter?.value === "pending") {
        tempData = payload?.data?.filter((item) =>
          isDueDatePast(item.due_date)
        );
      } else if (statusFilter?.value === "up_coming") {
        tempData = payload?.data?.filter(
          (item) => !isDueDatePast(item.due_date)
        );
      } else {
        tempData = payload?.data;
      }
      setTodos(tempData);

      setToken(payload?.token);
    } else {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTODOList();
  }, [activeTab, statusFilter, statusIncidentFilter]);

  const fetchReportById = async (id) => {
    setLoading(true);

    let response = await dispatch(getTenantReportById({ id: id }));
    const { payload } = response;
    if (payload?.status) {
      setReportData(payload?.data);
      setToken(payload?.token);
      setReportLayout(true);
    }
    setLoading(false);
  };

  const fetchAUDITTemplates = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getAuditTemplates({ type: "audit" }));
      const { payload } = response;
      if (payload?.status) {
        setAuditTemplates(
          payload?.data?.map((item) => ({ label: item.title, value: item.id }))
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchAUDITTemplates();
  }, []);

  const TabPanel = ({ children, value, index, ...other }) => (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );

  // Main Tab Change Handler

  // Sub Tab Change Handler
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setTodos([]);
    setStatusFilter({
      value: "All",
      label: "All",
    });
    setStatusIncidentFilter({
      value: "All",
      label: "All",
    });
  };

  const handleUpdateStatus = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        updateServiceUnitTask({
          data: { status: "completed" },
          id: selectedFormId?.id,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Task Status Updated Successfully.",
          })
        );
        setReviewCompleteOpen(false);
        fetchTODOList();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const isDueDatePast = (dueDate) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
    const due = new Date(dueDate).toISOString().split("T")[0]; // Get due date in YYYY-MM-DD format

    return due < today; // Compare date strings
  };

  const getStausChip = (row) => {
    if (row.status !== "completed" && isDueDatePast(row.due_date)) {
      return (
        <Chip
          label={Status["PENDING"]?.label}
          style={{
            backgroundColor: Status["PENDING"]?.color,
            fontWeight: "bold",
            cursor: "pointer",
          }}
        />
      );
    } else if (row.status) {
      return (
        <Chip
          label={Status[row?.status]?.label}
          style={{
            backgroundColor: Status[row?.status]?.color,
            fontWeight: "bold",
            cursor: "pointer",
          }}
        />
      );
    } else {
      return "-";
    }
  };

  const getIncidentStausChip = (row) => {
    console.log(Status[row?.report?.status]?.label);
    if (row?.report?.status) {
      return (
        <Chip
          label={Status[row?.report?.status]?.label}
          style={{
            backgroundColor: Status[row?.report?.status]?.color,
            fontWeight: "bold",
            cursor: "pointer",
          }}
        />
      );
    } else {
      return "-";
    }
  };
  // TodoList Component
  const TodoList = ({ todos, loading }) => (
    <Box
      sx={{
        height: "calc(100vh - 240px)",
        overflowY: "auto",
        backgroundColor: "white",
      }}
      p={2}
    >
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && todos && todos?.length > 0 && (
        <Grid container spacing={2}>
          {todos.map((todo) => (
            <Grid item xs={12} sm={12} md={12} lg={6} key={todo.id}>
              <Card
                sx={{
                  cursor: "pointer",

                  "&:hover": { boxShadow: 3 },
                }}
                onClick={() => {
                  if (todoTypes[activeTab].value === "incident_task") {
                    fetchReportById(todo?.report?.id);
                  } else if (todoTypes[activeTab].value === "inspection_task") {
                    navigate(`/service-unit/${todo?.service_unit?.id}/view`);
                  } else {
                    navigate(`/reports/${todo?.report?.id}/audits`);
                  }
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    paddingBottom: "16px !important",
                  }}
                >
                  <Grid container spacing={2} alignItems="flex-start">
                    {todoTypes[activeTab].value === "incident_task" && (
                      <Grid item>
                        <Avatar
                          src={
                            todo?.report?.image &&
                            `${todo?.report?.image[0]}?${token}`
                          }
                          alt={todo.title}
                          sx={{ width: 72, height: 72, borderRadius: "10px" }}
                        />
                      </Grid>
                    )}
                    <Grid item xs>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {todo.title || "No Title Available"}
                      </Typography>
                      <Typography variant="label" color="textSecondary">
                        {todo.description?.length > 130
                          ? `${todo.description.slice(0, 130)}...`
                          : todo.description || "No description available"}
                      </Typography>
                    </Grid>
                    {todoTypes[activeTab].value === "audit_task" && (
                      <Grid item xs={2}>
                        <Box className="center" alignItems="center">
                          <Tooltip
                            title={
                              !todo?.has_audit_submission
                                ? "Start Inspection"
                                : "Edit Inspection"
                            }
                          >
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                if (!todo?.has_audit_submission) {
                                  setSelectedTodo(todo);
                                  setIsOpenDrawer(true);
                                } else {
                                  setSelectedTodo(todo);
                                  setIsOpenEditDrawer(true);
                                }
                              }}
                            >
                              {!todo.has_audit_submission ? (
                                <FaPlayCircle size={22} color="#036185" />
                              ) : (
                                <FaRegPlayCircle size={22} color="#036185" />
                              )}
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={"Edit"}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setAuditDrawer(true);
                                setSelectedFormId(todo);
                              }}
                            >
                              <img
                                alt="Edit Details"
                                src={Pencil}
                                width={24}
                                height={24}
                                // style={{ marginRight: 8 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                    )}
                    {todoTypes[activeTab].value === "inspection_task" && (
                      <Grid item xs={1}>
                        <Box className="center" alignItems="center">
                          <Tooltip title={"Edit"}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setTaskDrawer(true);
                                setSelectedFormId(todo);
                              }}
                            >
                              <img
                                alt="Edit Details"
                                src={Pencil}
                                width={24}
                                height={24}
                                // style={{ marginRight: 8 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    container
                    sx={{
                      mt: "20px",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid item>
                      {todoTypes[activeTab]?.value !== "incident_task" && (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ fontSize: "14px" }}
                        >
                          <b>Due Date : </b>{" "}
                          {moment(todo.due_date).format("MM-DD-yyyy")}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {todoTypes[activeTab]?.value !== "incident_task"
                        ? getStausChip(todo)
                        : getIncidentStausChip(todo)}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {!loading && todos && todos?.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="subtitle1">
            No Tasks Currently Assigned
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <div>
      <Box
        sx={{
          mb: 1,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {/* Tabs Section */}
          <Grid item xs={12} sm={12} lg={10}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="TODO tabs"
              className="configure-action-tab"
            >
              {todoTypes?.map((item) => (
                <Tab key={item.value} label={item.label} disabled={loading} />
              ))}
            </Tabs>
          </Grid>

          {/* Dropdown Section */}
          <Box sx={{ width: "200px", marginLeft: "auto" }}>
            <Selectt
              styles={customStyles}
              name="kbs"
              size="small"
              fullWidth
              className="basic-multi-select"
              options={
                todoTypes[activeTab]?.value !== "incident_task"
                  ? statusOptions
                  : ReportStatusOptions
              }
              value={
                todoTypes[activeTab]?.value !== "incident_task"
                  ? statusFilter
                  : statusIncidentFilter
              }
              onChange={(e) => {
                todoTypes[activeTab]?.value !== "incident_task"
                  ? setStatusFilter(e)
                  : setStatusIncidentFilter(e);
              }}
              classNamePrefix="select"
              placeholder={"Select Status..."}
              components={{
                IndicatorSeparator: () => null,
              }}
            ></Selectt>
          </Box>
        </Grid>
      </Box>

      {todoTypes?.map((item, index) => (
        <TabPanel value={activeTab} index={index}>
          <TodoList todos={todos} loading={loading} />
        </TabPanel>
      ))}

      {isOpenDrawer && (
        <CreateFormModal
          isOpenDrawer={isOpenDrawer}
          setIsOpenDrawer={setIsOpenDrawer}
          taskId={selectedTodo?.task?.id}
          templateId={selectedTodo?.task?.template_form.id}
          fetchAllTasks={fetchTODOList}
        />
      )}
      {isOpenEditDrawer && (
        <EditFormModal
          mode="edit"
          isOpenDrawer={isOpenEditDrawer}
          setIsOpenDrawer={setIsOpenEditDrawer}
          taskId={selectedTodo?.task?.id}
          templateId={selectedTodo?.inspection_id}
        />
      )}

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Update Status"}
        open={reviewCompleteOpen}
        handleClose={() => {
          setReviewCompleteOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are You Sure You want to mark the <b>{selectedFormId?.title}</b>{" "}
              as completed?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={handleUpdateStatus}
            >
              {loading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setReviewCompleteOpen(false)}
            >
              {loading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      {taskDrawer && (
        <CreateTasks
          drawerState={"edit"}
          setIsOpenDrawer={setTaskDrawer}
          isOpenDrawer={taskDrawer}
          values={selectedFormId}
          fetchAlltasks={fetchTODOList}
        />
      )}

      {auditDrawer && (
        <CreateAudit
          drawerState={"edit"}
          setIsOpenDrawer={setAuditDrawer}
          isOpenDrawer={auditDrawer}
          values={selectedFormId?.task}
          auditTemplates={auditTemplates}
          getAllTasks={fetchTODOList}
        />
      )}

      {reportLayout && reportData && (
        <CreateReport
          isLayout={reportLayout}
          setLayout={setReportLayout}
          drawerState={"edit"}
          values={reportData}
          fetchAllReport={() => {}}
          imageToken={token}
          // setReportId={setReportId}
        />
      )}
    </div>
  );
};

export default TaskList;

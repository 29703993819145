import React, { useEffect, useState } from "react";
import { Box, Paper, useMediaQuery } from "@mui/material";
import Header from "./header";
import SideMenu from "./sideMenu";
import "../../styles/dashboard.scss";
import { useSelector } from "react-redux";

const Dashboard = ({ children }) => {
  const [isAdmin] = useState(true);
  const [openSideMenu, setOpenSideMenu] = useState(true);
  const isLargeSidebar = useSelector((state) => state.common.isFullSidebar);
  const isMobile = useMediaQuery("(max-width: 601px)");
  return (
    <Box>
      <Header
        setOpenSideMenu={setOpenSideMenu}
        openSideMenu={openSideMenu}
        isAdmin={isAdmin}
      />
      {isAdmin ? (
        <SideMenu
          openSideMenu={openSideMenu}
          setOpenSideMenu={setOpenSideMenu}
        />
      ) : null}
      <main
        className={`main-content ${
          isMobile
            ? openSideMenu
              ? "" // Do nothing if isMobile is true and openSideMenu is true
              : "full-width" // Apply full-width if isMobile is true and openSideMenu is false
            : isLargeSidebar
            ? "large-sidebar-margin" // If isMobile is false, apply large-sidebar-margin if isLargeSidebar is true
            : "small-sidebar-margin" // Otherwise, apply small-sidebar-margin
        }`}
        style={{ height: "calc(100vh - 62px)" }}
      >
        <Paper className="content-wrapper" elevation={0}>
          {children}
        </Paper>
      </main>
    </Box>
  );
};

export default Dashboard;

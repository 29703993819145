import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useDispatch } from "react-redux";
import { bookDemo, setSnackbar } from "../../redux/slices/common.slice";

const dialogStyle = {
  background: "#ffffff", // Set a clean white background for the dialog
  borderRadius: "16px",
  padding: "20px",
  paddingTop: "20px !important",
  color: "#333333", // Dark text for readability
};

// Extend dayjs with timezone support
dayjs.extend(utc);
dayjs.extend(timezone);

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .matches(
      /^(\(?\d{3}\)?[ ]*[\-]?[ ]*\d{3}[ ]*[\-]?[ ]*\d{4})$/,
      "Phone number must be exactly 10 digits"
    )
    .required("Phone is required"),
  businessName: Yup.string().required("Business Name is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string()
    .matches(/^[0-9]{5}$/, "ZIP code must be 5 digits")
    .required("ZIP is required"),
  dateTime: Yup.date().required("Date and time are required"),
});

const timezoneAbbreviations = {
  "India Standard Time": "IST",
  "GMT+5:30": "IST",
  "Eastern Standard Time": "EST",
  "Central Standard Time": "CST",
  "Pacific Standard Time": "PST",
  // Add more mappings as needed
};
function formatUSPhoneNumber(phoneNumber) {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumber;
}
function normalizeUSPhoneNumber(formattedPhoneNumber) {
  const cleaned = ("" + formattedPhoneNumber).replace(/\D/g, "");

  return cleaned;
}

export default function RequestDemoForm({ open, setOpen }) {
  const userTimezone = dayjs.tz.guess();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const getTimezoneAbbreviation = (timezone) => {
    try {
      const date = new Date();
      const fullTimezoneName = dayjs.tz(date, timezone).format("z");
      // Format with 'zzz' for timezone abbreviation
      return timezoneAbbreviations[fullTimezoneName] || fullTimezoneName;
    } catch (error) {
      console.error("Error fetching timezone abbreviation:", error);
      return "Unknown Timezone";
    }
  };
  const formatDateTime = (dateTime) => {
    if (!dateTime) return ""; // Return empty string if no dateTime is provided

    const date = dayjs(dateTime).format("MMM D, YYYY"); // Formats the date
    const time = dayjs(dateTime).format("h:mm A"); // Formats the time in 12-hour format with AM/PM
    const timezoneAbbreviation = dayjs(dateTime)
      .tz(userTimezoneAbbreviation)
      .format("z"); // Get timezone abbreviation (e.g., IST, EST)

    return `${date} ${time} ${
      timezoneAbbreviations[timezoneAbbreviation] || timezoneAbbreviation
    }`;
  };

  const userTimezoneAbbreviation = getTimezoneAbbreviation(userTimezone);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      businessName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      dateTime: dayjs().tz(userTimezone),
    },
    validationSchema,
    onSubmit: async (values) => {
      let finalData = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values?.email,
        business_name: values?.businessName,
        phone: normalizeUSPhoneNumber(values?.phone),
        address: values?.address,
        city: values?.city,
        state: values?.state,
        zip: values?.zip,
        date_time: formatDateTime(values.dateTime),
      };
      setLoading(true);
      try {
        let response = await dispatch(bookDemo(finalData));

        const { payload } = response;
        console.log(payload);
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "Demo request sent successfully.",
            })
          );
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      } finally {
        setLoading(false);
      }
      setOpen(false);
    },
  });
  console.log(formik.values);
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          background:
            "radial-gradient(100.93% 138.49% at -0.26% 7.52%, #64bcd2 0%, #036185 100%);", // Dark blue background for the header
          color: "#ffffff !important", // White text for contrast
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Request a Demo
      </DialogTitle>
      <DialogContent sx={dialogStyle}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            {/* Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            {/* Phone */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={formik.values.phone}
                onChange={(e) => {
                  formik.setFieldValue(
                    "phone",
                    formatUSPhoneNumber(e.target.value)
                  );
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            {/* Business Name */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Business Name"
                name="businessName"
                value={formik.values.businessName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.businessName &&
                  Boolean(formik.errors.businessName)
                }
                helperText={
                  formik.touched.businessName && formik.errors.businessName
                }
              />
            </Grid>
            {/* Address */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            {/* City */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            {/* State */}
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="State"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            </Grid>
            {/* ZIP */}
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="ZIP"
                name="zip"
                value={formik.values.zip}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.zip && Boolean(formik.errors.zip)}
                helperText={formik.touched.zip && formik.errors.zip}
              />
            </Grid>
            {/* Date Time Picker */}
            <Grid item xs={12} sm={9}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Select Date and Time"
                  value={formik.values.dateTime}
                  onChange={(value) => formik.setFieldValue("dateTime", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={
                        formik.touched.dateTime &&
                        Boolean(formik.errors.dateTime)
                      }
                      helperText={
                        formik.touched.dateTime
                          ? formik.errors.dateTime
                          : `Your timezone: ${userTimezoneAbbreviation}`
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Timezone"
                value={userTimezoneAbbreviation}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#036185",
                    fontWeight: "600",
                  },
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions
        sx={{ backgroundColor: "#f4f4f4", justifyContent: "center" }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            borderColor: "#036185",
            color: "#036185",
            "&:hover": { backgroundColor: "#e6f4f8" },
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          sx={{ backgroundColor: "#036185", color: "#ffffff" }}
          disabled={loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

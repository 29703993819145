import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/landing_page/mainlogo.png";
import CallLog from "../../assets/landing_page/call.svg";
import SMSLogo from "../../assets/landing_page/sms.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Stack,
  useMediaQuery,
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import "../../styles/newladingpage.scss";
import { useSelector } from "react-redux";
import RequestDemoForm from "../desktop/bookDemo";

const drawerWidth = 240;

const NewNavbar = (props) => {
  const Navigate = useNavigate();
  const [openBookDemo, setOpenBookDemo] = useState();
  const navIBartems = [
    {
      title: "Home",
      href: "/",
    },
    // {
    //   title: "Pricing",
    //   href: "/pricing",
    // },
    {
      title: "About",
      href: "/",
    },
    {
      title: "Contact",
      href: "",
    },
  ];

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const [isContactHovered, setIsContactHovered] = useState(false);
  const [isContactHoveredSidebar, setIsContactHoveredSidebar] = useState(false);
  const user = useSelector((state) => state.user);
  console.log(user);
  const toggleContactHover = () => {
    setIsContactHovered((prev) => !prev);
  };
  const toggleContactHoverSidebar = () => {
    handleDrawerToggle();
    setIsContactHoveredSidebar((prev) => !prev);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography component="div" sx={{ my: 2 }}>
        <Link to="/" key={"Logo"}>
          <img src={logo} alt="Logo" style={{ maxWidth: "200px" }} />
        </Link>
      </Typography>
      <List className="nav-items">
        {navIBartems?.map((item, i) => (
          <ListItem>
            <Link
              key={i}
              className={`${item.title}`}
              to={item.title === "Contact" ? null : item.href}
              onClick={item.title === "Contact" && toggleContactHoverSidebar}
            >
              <Typography>{item.title}</Typography>
            </Link>
          </ListItem>
        ))}
        <Box
          className={`contact-details ${
            isContactHoveredSidebar ? "show" : "d-done"
          }`}
        >
          <Box
            className="divider"
            sx={{ height: "1px", background: "#0361852b" }}
          ></Box>
          <Toolbar
            className="bottom-nav-toolbar-sidebar"
            sx={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <Box className="phone-talk-sidebar">
              <Typography component="span">
                <img src={CallLog} alt="Logo" />
              </Typography>
              <Typography className="text-content">703-577-2554</Typography>
            </Box>
            <Box className="sms-talk-sidebar">
              <Typography component="span">
                <img src={SMSLogo} alt="Logo" />{" "}
              </Typography>
              <Typography className="text-content">
                support@prismview.ai
              </Typography>
            </Box>
          </Toolbar>
        </Box>
      </List>
    </Box>
  );

  const isMobile = useMediaQuery("(min-width: 401px) and (max-width: 600px)");
  const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 960px)");
  const isLaptop = useMediaQuery("(min-width: 961px) and (max-width: 1280px)");
  const isDesktop = useMediaQuery("(min-width: 1281px)");

  const MaxWidthValue = isDesktop
    ? "lg"
    : isLaptop
    ? "lg"
    : isTablet
    ? "md"
    : isMobile
    ? "sm"
    : "xs";

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Box className="publicNavWrapper">
        <Container maxWidth={MaxWidthValue} sx={{ paddingTop: "15px" }}>
          <CssBaseline />
          <AppBar
            component="nav"
            id="HeaderNavbar"
            sx={{ py: 1 }}
            className="appbar"
          >
            <Toolbar className="top-nav-toolbar">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  flexGrow: 1,
                  justifyContent: "flex-start",
                  mr: 2,
                  display: { md: "none" },
                }}
              >
                <MenuIcon sx={{ fill: "#000000" }} />
              </IconButton>
              <Typography
                component="div"
                className="main-nav-logo"
                sx={{
                  display: {
                    xs: "none !important",
                    sm: "none !important",
                    md: "block !important",
                  },
                }}
              >
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </Typography>
              <Stack
                className="nav-links"
                sx={{
                  display: {
                    xs: "none !important",
                    lg: "block !important",
                  },
                  visibility: {
                    sm: "hidden !important",
                    md: "visible !important",
                    lg: "visible !important",
                  },
                }}
              >
                {navIBartems?.map((item, i) => (
                  <Link
                    key={i}
                    className={`${item.title}`}
                    to={item.href}
                    onMouseEnter={
                      item.title === "Contact" ? toggleContactHover : null
                    }
                    onMouseLeave={
                      item.title === "Contact" ? toggleContactHover : null
                    }
                  >
                    <Typography>{item.title}</Typography>
                  </Link>
                ))}
              </Stack>
              <Stack alignItems="center" className="nav-buttons">
                <>
                  {user.data && user.data?.system_role === "admin" ? (
                    <Button
                      onClick={() => Navigate("/dashboard")}
                      className="getting-start-button"
                    >
                      Dashboard
                    </Button>
                  ) : (
                    <>
                      {" "}
                      <Button
                        className="login-button"
                        onClick={() => Navigate("/login")}
                      >
                        Log in
                      </Button>
                      <Button
                        variant="outlined"
                        className="getting-start-button"
                        // onClick={() => Navigate("/subscription")}
                        onClick={() => setOpenBookDemo(true)}
                      >
                        Get Started
                      </Button>
                    </>
                  )}
                </>
              </Stack>
            </Toolbar>
            <Box
              className={`contact-details ${
                isContactHovered ? "show" : "d-done"
              }`}
            >
              <Box className="divider"></Box>
              <Toolbar className="bottom-nav-toolbar">
                <Box className="phone-talk">
                  <Typography component="span">
                    <img src={CallLog} alt="Logo" />
                  </Typography>
                  <Typography className="text-content">703-577-2554</Typography>
                </Box>
                <Box className="sms-talk">
                  <Typography component="span">
                    <img src={SMSLogo} alt="Logo" />{" "}
                  </Typography>
                  <Typography className="text-content">
                    support@prismview.ai
                  </Typography>
                </Box>
              </Toolbar>
            </Box>
          </AppBar>
        </Container>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { md: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
      {openBookDemo && (
        <RequestDemoForm open={openBookDemo} setOpen={setOpenBookDemo} />
      )}
      {/* </Container> */}
    </>
  );
};
NewNavbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default NewNavbar;

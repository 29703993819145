import { API_URL } from "../../constants/apiUrl.constant";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createEmployees = createAsyncThunk(
  "createEmployeesSchedule",
  async ({ data }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_INSPECTION_SCHEDULE}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateEmployees = createAsyncThunk(
  "updateEmployeesSchedule",
  async ({ data, id }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_INSPECTION_SCHEDULE}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllEmployees = createAsyncThunk(
  "getAllEmployeesSchedule",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_INSPECTION_SCHEDULE}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const deleteEmployee = createAsyncThunk(
  "deleteEmployeesSchedule",
  async ({ id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.SERVICE_UNIT_INSPECTION_SCHEDULE}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDataFromInductionForm = createAsyncThunk(
  "getDataFromInductionForm",
  async ({ id }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.INDUCTION_DATA}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createTraining = createAsyncThunk(
  "createTraining",
  async ({ data }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.TRAINING}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const assignTraining = createAsyncThunk(
  "assignTraining",
  async (data) => {
    console.log(data);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/training/${data?.id}/assign`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateTraining = createAsyncThunk(
  "updateTraining",
  async ({ data, id }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.TRAINING}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateUserTraining = createAsyncThunk(
  "updateUserTraining",
  async ({ data, id }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.USER_TRAINING}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const unAssignUserFromTraining = createAsyncThunk(
  "unAssignUserFromTraining",
  async ({ id }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.USER_TRAINING}/${id}/unassign`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllTraining = createAsyncThunk(
  "getAllTraining",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.TRAINING}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const deleteTraining = createAsyncThunk(
  "deleteTraining",
  async ({ id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.TRAINING}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUsersTraining = createAsyncThunk(
  "getUsersTraining",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.TRAINING_EMPLOYEE}/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload?.params,
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Assistants slice
const EmployeeSlice = createSlice({
  name: "Form",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {},
});

export default EmployeeSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Paper,
  InputBase,
  Stack,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";

import { RiAddCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import Pencil from "../../../assets/icons/pencil.svg";

import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { MdRemoveRedEye } from "react-icons/md";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import DeleteIcon from "../../../assets/icons/deleteRed.svg";
import DeleteIconn from "@mui/icons-material/Delete";
import DataGridTable from "../dataGrid";

import { setSnackbar } from "../../../redux/slices/common.slice";
import CustomizedDialog from "../Dialog";
import {
  documentAnalyse,
  getFormsByType,
} from "../../../redux/slices/forms.slice";
import { PhotoCamera } from "@mui/icons-material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import Selectt from "react-select";
import CreateFormModal from "../forms/createFormModal";

import { deleteUserThunk } from "../../../redux/slices/tenant.slice";
import { getAllUsersThunk } from "../../../redux/slices/common.slice";
import { useSelector } from "react-redux";

import logAnalyticsEvent from "../../../firebase/analyticsLogger";

const customStyles = {
  control: (base) => ({
    ...base,
    border: "1px solid #036185",
    borderRadius: 8,
    padding: 4,
    boxShadow: "none",

    "&:hover": {
      borderColor: "#036185",
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 180,
    overflowY: "auto",
    zIndex: 9999,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};

const customStyles2 = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    padding: "12px",

    pr: 3,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};
export default function EmployeeList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [sortKey, setSortKey] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [loading, setLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [employeeInductionForms, setEmployeeInductionForms] = useState([]);
  const [openTemplateSelection, setOpenTemplateSelection] = useState(false);
  const [error, setError] = useState();
  const [isOpenCreateFormDrawer, setIsOpenCreateFormDrawer] = useState(false);
  const [isOpenEditFormDrawer, setIsOpenEditFormDrawer] = useState(false);
  const [imageData, setImageData] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [token, setToken] = useState();
  const [statusFilter, setStatusFilter] = useState(true);
  const statusOptiones = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Active",
      value: true,
    },
    {
      label: "Inactive",
      value: false,
    },
  ];
  const user = useSelector((state) => state.user);

  const fetchAllEmployees = async () => {
    setIsLoading(true);

    try {
      let response = await dispatch(
        getAllUsersThunk({
          page_number: paginationModel.page + 1,
          page_size: paginationModel.pageSize,
          sort: sortKey,

          status: statusFilter === "all" ? undefined : statusFilter,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setEmployees(payload?.data);
        setTotalRow(payload?.total_object);
        setToken(payload?.token);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllEmployees();
  }, [paginationModel, sortKey, statusFilter]);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        deleteUserThunk({ id: selectedEmployee?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        setOpenDeleteModal(false);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Employee Deleted successfully",
          })
        );
        logAnalyticsEvent("delete_employee", {
          userId: user?.data?.user?.id,
        });
        fetchAllEmployees();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageAnalysis = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      // Append each file to the form data
      for (let i = 0; i < imagePreviews.length; i++) {
        formData.append("images", imagePreviews[i]?.file); // 'images' is the key for the backend
      }

      let response = await dispatch(documentAnalyse(formData));
      const { payload } = response;
      if (payload && payload.status) {
        // navigate(`/forms/${selectedForm?.id}/${selectedFormType}/create`, {
        //   state: {
        //     data: payload.data,
        //   },
        // });
        setImageData(payload?.data);
        setIsOpenCreateFormDrawer(true);
        setOpenImageModal(false);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchEmployeeInductionForms = async () => {
      setIsLoading(true);

      try {
        const response = await dispatch(
          getFormsByType({ formType: "employee_induction" })
        );
        const { payload } = response;
        if (payload?.status) {
          setEmployeeInductionForms(
            payload?.data?.map((item) => ({
              label: item.title,
              value: item.id,
            }))
          );
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload?.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: "Internal server error",
          })
        );
      }

      setIsLoading(false);
    };
    fetchEmployeeInductionForms();
  }, []);

  function DateCell(params) {
    return params.value ? moment(params.value).format("MM-DD-yyyy") : "-";
  }

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        setSortKey(sortKey.substring(1));
      } else {
        setSortKey(`-${key}`);
      }
    }
  };
  function formatUSPhoneNumber(phoneNumber) {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumber;
  }
  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "first_name")}
        >
          First Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "last_name")}
        >
          Last Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "date_of_birth",
      headerName: "Date of Birth",
      minWidth: 130,
      flex: 1,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "date_of_birth")}
        >
          Date of Birth
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "city")}
        >
          City
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "state")}
        >
          State
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "phone_number",
      headerName: "Contact No",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "phone_number")}
        >
          Contact No
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: (params) =>
        !formatUSPhoneNumber(params?.value)
          ? "-"
          : `+1${formatUSPhoneNumber(params?.value)}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0} alignItems="center">
            <Tooltip title="View">
              <IconButton
                // onClick={() => {
                //   setDrawerState("view");
                //   setIsOpenEditFormDrawer(true);
                //   setSelectedRow(params.row);
                // }}
                onClick={() => {
                  navigate(`/employee/${params.row.id}/view`);
                }}
              >
                <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
              </IconButton>
            </Tooltip>

            {/* <Tooltip title="Edit">
              <IconButton
                sx={{ color: "#000000", padding: "0px" }}
                onClick={() => {
                  setDrawerState("edit");
                  setIsOpenEditFormDrawer(true);
                  setSelectedRow(params.row);
                }}
              >
                <img
                  alt="Edit Details"
                  src={Pencil}
                  width={24}
                  height={24}
                  className="actionIcon"
                  cursor={"pointer"}
                />
              </IconButton>
            </Tooltip> */}
            {params?.row?.is_active &&
              user?.data?.user?.id !== params.row?.id && (
                <Tooltip title="Delete">
                  <IconButton
                    sx={{ color: "#000000", padding: 0 }}
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setSelectedEmployee(params.row);
                    }}
                  >
                    <img
                      alt="Delete Details"
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      className="actionIcon"
                      cursor={"pointer"}
                    />
                  </IconButton>
                </Tooltip>
              )}
            {/* <Tooltip title="Forms">
              <IconButton
                onClick={() => {
                  navigate(`/employee/${params.row.id}/view`);
                }}
              >
                <IoMdDocument size={24} cursor={"pointer"} color="#036185" />
              </IconButton>
            </Tooltip> */}
          </Stack>
        );
      },
    },
  ];

  const handleFormChange = (selectedOption) => {
    setSelectedForm(selectedOption);
    setError();
  };

  const statusOptions = [
    { value: "All", label: "All" },
    { value: true, label: "Active" },
    { value: false, label: "In Active" },
  ];

  return (
    <>
      <div className="employee-list">
        <Box className="content-header kb-list-header mb-4">
          <Select
            sx={customStyles2}
            name="status"
            className="basic-multi-select basic-multi-select-status"
            onChange={(event) => {
              setStatusFilter(event.target.value);
            }}
            value={statusFilter}
            classNamePrefix="select"
            components={{
              IndicatorSeparator: () => null,
            }}
          >
            {statusOptiones.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </Select>
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => {
                setOpenTemplateSelection(true);
              }}
            >
              Add Employee
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? employees.filter(
                    (item) =>
                      item.first_name
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                      item.last_name
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase())
                  )
                : employees.slice(
                    paginationModel.page * paginationModel.pageSize,
                    (paginationModel.page + 1) * paginationModel.pageSize
                  )
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are you sure you want to delete{" "}
              <b>
                {selectedEmployee?.first_name} {selectedEmployee?.last_name}?
              </b>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{ mr: 1 }}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="primary-button"
              onClick={handleDelete}
              disabled={isLoading}
            >
              Delete
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Upload Documents (images)"}
        open={openImageModal}
        handleClose={() => setOpenImageModal(false)}
      >
        <DialogContent>
          <Box>
            {/* Display Image Previews */}
            <Grid container spacing={2}>
              {imagePreviews?.length === 0 ? (
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "300px", // Set a height for visual structure
                      border: "2px dashed rgba(0, 0, 0, 0.2)", // Dashed border for empty state
                      borderRadius: "8px",
                      padding: "16px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      textAlign: "center",
                      gap: 2,
                    }}
                  >
                    <InsertPhotoIcon
                      sx={{ fontSize: 48, color: "rgba(0, 0, 0, 0.3)" }}
                    />
                    <Typography variant="h6" color="textSecondary">
                      No images selected
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                imagePreviews.map((preview, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Box
                      sx={{
                        position: "relative",
                        borderRadius: "8px",
                        overflow: "hidden",
                        height: "300px", // Fixed height for consistency
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow effect
                        transition: "box-shadow 0.3s ease-in-out",
                      }}
                    >
                      <img
                        src={preview.url}
                        alt={`screenshot-${index}`}
                        style={{
                          maxWidth: "100%", // Ensures it doesn’t overflow horizontally
                          maxHeight: "100%", // Ensures it doesn’t overflow vertically
                          objectFit: "contain", // Adjust to contain within the box without distortion
                          borderRadius: "8px",
                        }}
                      />

                      {/* Delete Icon */}
                      <Tooltip title="Remove Image">
                        <IconButton
                          size="small"
                          onClick={() => {
                            const updatedPreviews = imagePreviews.filter(
                              (_, i) => i !== index
                            );
                            setImagePreviews(updatedPreviews); // Update state without the removed image
                          }}
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.8)",
                            },
                          }}
                        >
                          <DeleteIconn fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className="modal-footers">
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                component="label"
                className="primary-button"
                disabled={loading}
                startIcon={<PhotoCamera />}
                // onClick={formik.handleSubmit}
              >
                {loading && (
                  <CircularProgress
                    size="1rem"
                    sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                  />
                )}
                Upload Images
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={(event) => {
                    const files = Array.from(event.target.files);

                    const previews = files.map((file) => ({
                      file,
                      url: URL.createObjectURL(file),
                    }));

                    // Update the state with the new image previews
                    setImagePreviews(previews);
                  }}
                />
              </Button>
              {imagePreviews.length > 0 && (
                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={loading}
                  onClick={handleImageAnalysis}
                >
                  Save & Next
                </Button>
              )}

              <Button
                variant="outlined"
                className="secondary-button"
                disabled={loading}
                onClick={() =>
                  //   navigate(
                  //     `/forms/${selectedForm?.id}/${selectedFormType}/create`
                  //   )
                  {
                    setOpenImageModal(false);
                    setIsOpenCreateFormDrawer(true);
                  }
                }
              >
                Skip
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Select Employee Induction Form"}
        height="300px"
        width="600px"
        open={openTemplateSelection}
        handleClose={() => setOpenTemplateSelection(false)}
      >
        <DialogContent>
          <Box p={2}>
            {employeeInductionForms?.length !== 0 && (
              <>
                {error && <div className="form-error-message">{error}</div>}
                <Selectt
                  value={selectedForm}
                  onChange={(e) => handleFormChange(e)}
                  options={employeeInductionForms}
                  styles={customStyles}
                  placeholder="Select Employee Induction Form"
                  defaultMenuIsOpen={true}
                />
              </>
            )}

            {!employeeInductionForms.length && (
              <Box
                sx={{
                  textAlign: "center",
                  padding: 3,

                  marginTop: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, color: "#333" }}
                >
                  No Employee Induction Forms Found
                </Typography>
                <Typography
                  variant="label"
                  sx={{ marginTop: 1, color: "#555" }}
                >
                  Please create a new Employee Induction Form to proceed.
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {!employeeInductionForms?.length ? (
              <Button
                sx={{ marginRight: "15px" }}
                variant="contained"
                className="primary-button"
                onClick={() => navigate("/settings/formbuilder/select")}
              >
                Create Employee Induction Form
              </Button>
            ) : (
              <Button
                sx={{ marginRight: "15px" }}
                variant="contained"
                className="primary-button"
                onClick={() => {
                  if (!selectedForm) {
                    setError("Select Employee Induction Form First");
                    return;
                  }
                  setOpenTemplateSelection(false);
                  setOpenImageModal(true);
                }}
              >
                Create Employee
              </Button>
            )}

            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setOpenTemplateSelection(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      {isOpenCreateFormDrawer && (
        <CreateFormModal
          isOpenDrawer={isOpenCreateFormDrawer}
          setIsOpenDrawer={setIsOpenCreateFormDrawer}
          taskId={undefined}
          templateId={selectedForm?.value}
          fetchAllTasks={fetchAllEmployees}
          prefillData={imageData}
          title="Create User"
        />
      )}

      {/* {isOpenEditFormDrawer && (
        <CreateEmployees
          drawerState={drawerState}
          setIsOpenDrawer={setIsOpenEditFormDrawer}
          isOpenDrawer={isOpenEditFormDrawer}
          fetchAllUsers={fetchAllEmployees}
          selectedRow={selectedRow}
          token={token}
        />
      )} */}
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Box,
  Divider,
  Chip,
  Slider,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import ReactPlayer from "react-player";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";

const mediaData = [
  {
    id: 1,
    title: "Introduction to React",
    thumbnail: "https://via.placeholder.com/100",
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
    transcription: [
      {
        text: "So why do we need such a code sandbox environment or such a local project created with extra tools like Wheat?",
        timeframes: [
          { start: 0, end: 10 },
          { start: 30, end: 40 },
        ],
      },
      {
        text: "If we simply want to write some React code, why can't we just create an HTML file and a JavaScript file and reference the script file from inside the HTML file and then put our React code into that script file?",
        timeframes: [
          { start: 5, end: 15 },
          { start: 120, end: 125 },
        ],
      },
      {
        text: "Why is that not really an option?",
        timeframes: [
          { start: 20, end: 30 },
          { start: 40, end: 50 },
        ],
      },
      {
        text: "This syntax is called JSX and you will learn more about it throughout this course.",
        timeframes: [
          { start: 60, end: 75 },
          { start: 90, end: 100 },
        ],
      },
    ],
    keywords: [
      { keyword: "React", timeframes: [{ start: 0, end: 10 }] },
      { keyword: "Advantages", timeframes: [{ start: 11, end: 20 }] },
      { keyword: "Setup", timeframes: [{ start: 21, end: 30 }] },
    ],
    topics: [
      {
        topic: "React Basics",
        timeframes: [
          { start: 0, end: 10 },
          { start: 45, end: 55 },
        ],
      },
      {
        topic: "JavaScript Fundamentals",
        timeframes: [{ start: 11, end: 30 }],
      },
      { topic: "React Components", timeframes: [{ start: 31, end: 60 }] },
    ],
    branches: [
      {
        branch: "Reactjs",
        timeframes: [
          { start: 0, end: 10 },
          { start: 20, end: 60 },
        ],
      },
      { branch: "Udemy", timeframes: [{ start: 5, end: 20 }] },
      {
        branch: "Env",
        timeframes: [{ start: 20, end: 50 }],
      },
    ],
  },
  {
    id: 2,
    title: "Understanding JavaScript",
    thumbnail: "https://via.placeholder.com/100",
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
    transcription: [
      {
        text: "Introduction to JavaScript",
        timeframes: [
          { start: 0, end: 10 },
          { start: 30, end: 40 },
        ],
      },
      {
        text: "Variables and Data Types",
        timeframes: [
          { start: 5, end: 15 },
          { start: 50, end: 60 },
        ],
      },
      {
        text: "Functions in JavaScript",
        timeframes: [
          { start: 20, end: 30 },
          { start: 40, end: 50 },
        ],
      },
      {
        text: "JavaScript in the Browser",
        timeframes: [
          { start: 60, end: 75 },
          { start: 90, end: 100 },
        ],
      },
    ],
    keywords: [
      {
        keyword: "JavaScript",
        timeframes: [
          { start: 0, end: 15 },
          { start: 19, end: 21 },
          { start: 120, end: 123 },
        ],
      },
      { keyword: "Functions", timeframes: [{ start: 16, end: 30 }] },
      { keyword: "Browser", timeframes: [{ start: 31, end: 45 }] },
    ],
    topics: [
      {
        topic: "Introduction to JavaScript",
        timeframes: [{ start: 0, end: 10 }],
      },
      { topic: "Variables & Data Types", timeframes: [{ start: 5, end: 20 }] },
      {
        topic: "Functions in JavaScript",
        timeframes: [{ start: 20, end: 50 }],
      },
      {
        topic: "JavaScript in the Browser",
        timeframes: [{ start: 60, end: 100 }],
      },
    ],
    branches: [
      {
        branch: "Nodejs",
        timeframes: [
          { start: 0, end: 10 },
          { start: 20, end: 60 },
        ],
      },
      { branch: "Udemy", timeframes: [{ start: 5, end: 20 }] },
      {
        branch: "Env",
        timeframes: [{ start: 20, end: 50 }],
      },
    ],
  },
];

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && (
      <Box
        sx={{
          padding: "4px 12px",
          height: "calc(100vh - 236px)",
          overflowY: "auto",
        }}
      >
        {children}
      </Box>
    )}
  </div>
);

export default function VideoAudioAnalysis() {
  const [selectedMedia, setSelectedMedia] = useState(mediaData[0]);
  const [player, setPlayer] = useState(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(true);
  const [selectedKeyword, setSelectedKeyword] = useState();
  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedBranch, setSelectedBranch] = useState();

  const [videoDuration, setVideoDuration] = useState(0);
  const [sliderValue, setSliderValue] = useState(0); // for current slider value
  const [sliderWidth, setSliderWidth] = useState(0); // to get width of the slider for calculating strip width
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleMediaSelect = (media) => {
    setSelectedMedia(media);
  };

  const handleSeek = (time) => {
    if (player) player.seekTo(time, "seconds");
  };

  const toggleGallery = () => {
    setIsGalleryOpen(!isGalleryOpen);
  };

  const handleKeywordClick = (keyword) => {
    setSelectedKeyword(keyword);
  };
  const handleEntityClick = (keyword) => {
    setSelectedBranch(keyword);
  };

  const handleDuration = (duration) => {
    setVideoDuration(duration);
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    handleSeek(newValue);
  };

  // Convert time in seconds to mm:ss format
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSliderRef = (slider) => {
    if (slider) {
      const width = slider.offsetWidth;

      setSliderWidth(width);
    }
  };
  const handleSkipTotranscription = (transcription) => {
    console.log(transcription);
    if (transcription?.timeframes && transcription?.timeframes?.length > 0) {
      let startTime = transcription?.timeframes[0]?.start;
      console.log(startTime);
      handleSeek(startTime);
    }
  };

  const calculateTrackColorPosition = (timeframe) => {
    if (!sliderWidth || !videoDuration) {
      console.error("Slider width or video duration not set");
      return { startPos: 0, endPos: 0 };
    }

    const startPos = (timeframe.start / videoDuration) * sliderWidth;
    const endPos = (timeframe.end / videoDuration) * sliderWidth;

    // Ensure positions are within slider bounds
    return {
      startPos: Math.min(Math.max(startPos, 0), sliderWidth),
      endPos: Math.min(Math.max(endPos, 0), sliderWidth),
    };
  };

  const [currentTime, setCurrentTime] = useState(0); // State to store current time
  // This function will be triggered on every progress update of the video
  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds); // Current time in seconds
  };

  const TimeLine = (item) => {
    return (
      <Box sx={{ position: "relative" }} mt={2}>
        {/* Slider */}
        <Slider
          value={sliderValue}
          min={0}
          max={videoDuration}
          step={0.1}
          onChange={handleSliderChange}
          sx={{
            width: "100%",
            color: "#ddd",
            height: 15,
            padding: "0 !important",

            "& .MuiSlider-thumb": {
              display: "block", // Make the thumb visible

              zIndex: 3, // Ensure it's above the highlighted section
            },

            "& .MuiSlider-track": {
              zIndex: 1, // Below the highlighted section
            },

            "& .MuiSlider-rail": {
              zIndex: 1, // Below the highlighted section
            },
          }}
          ref={handleSliderRef}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => formatTime(value)}
        />
        {/* Mark start and end times, and color in-between */}
        {item?.timeframes.map((timeframe, index) => {
          const { startPos, endPos } = calculateTrackColorPosition(timeframe);

          const handleBoxClick = (e) => {
            const offsetX = e.nativeEvent.offsetX; // Get the clicked position relative to the box
            const boxStartPos = e.target.offsetLeft; // Get the position of the box relative to the entire slider

            // Calculate the new value of the slider based on the click position
            const newValue =
              ((offsetX + boxStartPos) / sliderWidth) * videoDuration;

            handleSliderChange(null, newValue);
          };

          return (
            <Box
              key={index}
              sx={{
                position: "absolute",
                left: startPos,
                width: endPos - startPos,
                height: 16,
                top: 0,
                backgroundColor: "#036185", // Color for the highlighted section
                zIndex: 2, // Above the slider track but below the thumb
                pointerEvents: "auto",
              }}
              onClick={handleBoxClick} // Trigger onClick event when clicked
            />
          );
        })}
      </Box>
    );
  };

  const isInTimeframe = (timeframes, currentTime) => {
    console.log(timeframes, currentTime);
    return timeframes?.some(
      (frame) => currentTime >= frame.start && currentTime <= frame.end
    );
  };
  console.log(selectedKeyword?.keyword);
  return (
    <Box className="box-container">
      <Grid container spacing={2}>
        {/* Left Side: Media List */}
        {isGalleryOpen && (
          <Grid item xs={12} md={4} sx={{ transition: "width 0.3s ease" }}>
            <Typography
              variant="h3"
              sx={{ fontSize: "18px", marginBottom: "12px" }}
            >
              <FaCircleChevronLeft
                onClick={toggleGallery}
                style={{ marginRight: "8px" }}
              />
              Media Library
            </Typography>
            <List>
              {mediaData.map((media) => (
                <ListItem
                  key={media.id}
                  button
                  onClick={() => handleMediaSelect(media)}
                  sx={{
                    marginBottom: 1,
                    backgroundColor:
                      selectedMedia.id === media.id ? "#e0f7fa" : "#fff",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar src={media.thumbnail} variant="rounded" />
                  </ListItemAvatar>
                  <ListItemText primary={media.title} />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}

        {/* Right Side: Player and Details */}
        <Grid item xs={12} md={isGalleryOpen ? 7.8 : 12}>
          <Typography
            variant="h3"
            sx={{ fontSize: "18px", marginBottom: "18px" }}
          >
            {!isGalleryOpen && (
              <FaCircleChevronRight
                onClick={toggleGallery}
                style={{ marginRight: "8px" }}
              />
            )}
            {selectedMedia?.title}
          </Typography>
          <Box sx={{ height: "calc(100vh - 180px)", overflowY: "auto" }}>
            <Grid container spacing={2}>
              {/* Video Player */}
              <Grid item xs={!isGalleryOpen ? 7 : 12}>
                <ReactPlayer
                  url={selectedMedia.url}
                  controls
                  width="100%"
                  height={isGalleryOpen ? "400px" : "100%"}
                  ref={(playerInstance) => setPlayer(playerInstance)}
                  onDuration={handleDuration}
                  onProgress={handleProgress}
                />
              </Grid>

              {/* Transcription and Keywords */}
              <Grid item xs={!isGalleryOpen ? 5 : 12}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label="TODO tabs"
                  className="configure-action-tab"
                >
                  <Tab label={"Transcriptions"} />
                  <Tab label={"Insights"} />
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                  <List>
                    {selectedMedia.transcription.map((segment, index) => {
                      // If the transcription is within the current time range, display it
                      return (
                        <ListItem
                          key={index}
                          button
                          sx={{
                            marginBottom: 1,
                            backgroundColor: isInTimeframe(
                              segment.timeframes,
                              currentTime
                            )
                              ? "#64bcd2"
                              : "#f1f1f1",
                            color: isInTimeframe(
                              segment.timeframes,
                              currentTime
                            )
                              ? "white"
                              : "black",
                            transition: "all 0.3s ease", // Smooth transition for hover effect
                            "&:hover": {
                              backgroundColor: isInTimeframe(
                                segment.timeframes,
                                currentTime
                              )
                                ? "#4ca3ba" // Slightly darker shade for active item hover
                                : "#e0e0e0", // Slightly darker shade for inactive item hover
                              color: isInTimeframe(
                                segment.timeframes,
                                currentTime
                              )
                                ? "white"
                                : "black", // Ensure text remains visible
                            },
                          }}
                          onClick={() => handleSkipTotranscription(segment)}
                        >
                          <PlayCircleIcon
                            sx={{ marginRight: 1, color: "#036185" }}
                          />
                          <Box>
                            <Typography
                              variant="caption"
                              sx={{
                                display: "block",
                                color: isInTimeframe(
                                  segment.timeframes,
                                  currentTime
                                )
                                  ? "white"
                                  : "#757575", // Subtle color for time
                                fontSize: "0.8rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {formatTime(segment.timeframes[0].start)} -{" "}
                              {formatTime(segment.timeframes[0].end)}
                            </Typography>
                            <ListItemText primary={segment.text} />
                          </Box>
                        </ListItem>
                      );
                    })}
                  </List>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      sx={{ marginBottom: 1, fontWeight: 500 }}
                    >
                      Topics
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                      {selectedMedia?.topics?.map((topic, index) => (
                        <Chip
                          key={index}
                          label={topic.topic}
                          sx={{
                            cursor: "pointer",
                            fontSize: "14px !important",

                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "#b6877a",
                            },
                          }}
                          style={{
                            backgroundColor:
                              topic.topic === selectedTopic?.topic
                                ? "#64bcd2"
                                : "",
                          }}
                          onClick={() => setSelectedTopic(topic)}
                        />
                      ))}
                    </Box>
                    {TimeLine(selectedTopic)}
                  </Grid>
                  <Grid xs={12}>
                    <Divider sx={{ margin: "8px 0" }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      sx={{ marginBottom: 1, fontWeight: 500 }}
                    >
                      Keywords
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                      {selectedMedia?.keywords?.map((keyword, index) => {
                        console.log(
                          keyword.keyword === selectedKeyword?.keyword
                        );
                        return (
                          <Chip
                            key={index}
                            label={keyword.keyword}
                            sx={{
                              cursor: "pointer",
                              fontSize: "14px !important",
                              padding: "0 !important",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#b6877a",
                              },
                            }}
                            style={{
                              backgroundColor:
                                keyword.keyword === selectedKeyword?.keyword
                                  ? "#64bcd2"
                                  : "",
                            }}
                            onClick={() => handleKeywordClick(keyword)}
                          />
                        );
                      })}
                    </Box>
                    {TimeLine(selectedKeyword)}
                  </Grid>
                  <Grid xs={12}>
                    <Divider sx={{ margin: "8px 0" }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      sx={{ marginBottom: 1, fontWeight: 500 }}
                    >
                      Named Entities
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                      {selectedMedia?.branches?.map((branch, index) => (
                        <Chip
                          key={index}
                          label={branch.branch}
                          sx={{
                            cursor: "pointer",
                            fontSize: "14px !important",
                            padding: "0 !important",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "#b6877a",
                            },
                          }}
                          style={{
                            backgroundColor:
                              branch.branch === selectedBranch?.branch
                                ? "#64bcd2"
                                : "",
                          }}
                          onClick={() => handleEntityClick(branch)}
                        />
                      ))}
                    </Box>
                    {TimeLine(selectedBranch)}
                  </Grid>
                </TabPanel>
              </Grid>

              {/* Keywords */}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

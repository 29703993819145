import React, { useState } from "react";
import { Box, Button, Typography, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Cancel";
const FileUpload = ({ name, required, onFilesChange, type = "image" }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = [...selectedFiles, ...files];
    setSelectedFiles(newFiles);
    onFilesChange(newFiles); // Notify parent component
  };

  const removeFile = (fileToRemove) => {
    const newFiles = selectedFiles.filter(
      (file) => file.name !== fileToRemove.name
    );
    setSelectedFiles(newFiles);
    onFilesChange(newFiles); // Notify parent component
  };

  return (
    <Box>
      <Paper
        sx={{
          padding: "20px",
          border: "2px dashed #ddd",
          textAlign: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <input
          type="file"
          name={name}
          multiple
          accept={type === "video" ? "video/*" : "*"}
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="file-upload"
        />
        <label htmlFor="file-upload">
          <Typography variant="label" sx={{ margin: "auto" }}>
            Click To Upload {type === "video" ? "Video" : "Image"}
          </Typography>
        </label>
      </Paper>

      <Box sx={{ mt: 2 }}>
        {selectedFiles.map((file, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #ddd",
              borderRadius: "4px",
              padding: "8px",
              mb: 1,
            }}
          >
            <Typography variant="label">{file.name}</Typography>

            <Button
              onClick={() => removeFile(file)}
              variant="standard"
              color="error"
            >
              <CloseIcon sx={{ fontSize: "28px" }} />
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FileUpload;

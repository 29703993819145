import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/apiUrl.constant";
import axios from "axios";

export const setSnackbar = createAsyncThunk("setSnackbar", async (data) => {
  return data;
});
export const setNotificationReportId = createAsyncThunk(
  "setNotificationReportId",
  async (data) => {
    return data;
  }
);
export const setHeaderTitle = createAsyncThunk(
  "setHeaderTitle",
  async (data) => {
    return data;
  }
);
export const setAllUsers = createAsyncThunk("setAllUsers", async (data) => {
  return data;
});
export const toggleSidebar = createAsyncThunk("toggleSidebar", async () => {
  return true;
});

export const bookDemo = createAsyncThunk("bookDemo", async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}${API_URL.BOOK_DEMO}`,
      data
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const userActivityThunk = createAsyncThunk(
  "userActivity",
  async (page) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.USER_ACTIVITY}`,
        page,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllUsersThunk = createAsyncThunk(
  "getAllUsers",
  async (payload, status) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_USERS}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// User slice
const commonSlice = createSlice({
  name: "user",
  initialState: {
    snackbar: {
      open: false,
      severity: "success",
      message: "",
    },
    reportId: null,
    headerTitle: null,
    users: [],
    isFullSidebar: false,
  },
  extraReducers: (builder) => {
    builder.addCase(setSnackbar.fulfilled, (state, action) => {
      state.snackbar.open = action.payload.open;
      state.snackbar.message = action.payload.message;
      state.snackbar.severity = action.payload.severity;
    });
    builder.addCase(setNotificationReportId.fulfilled, (state, action) => {
      state.reportId = action.payload;
    });
    builder.addCase(setHeaderTitle.fulfilled, (state, action) => {
      state.headerTitle = action.payload;
    });
    builder.addCase(setAllUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(getAllUsersThunk.fulfilled, (state, action) => {
      state.users = action.payload?.data?.map((item) => ({
        label: `${item.first_name} ${item.last_name}`,
        value: item.id,
      }));
    });
    builder.addCase(toggleSidebar.fulfilled, (state, action) => {
      state.isFullSidebar = !state.isFullSidebar;
    });
  },
});

export default commonSlice.reducer;

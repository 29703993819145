import React, { useState, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import "../../../../styles/dashboard.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import Selectt from "react-select";
import CustomizedDialog from "../../Dialog";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles
import {
  createProjectMessage,
  getAllProject,
  updateProjectMessage,
} from "../../../../redux/slices/tenant.slice";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "../../../../styles/project.scss";
import { modules } from "../../../../utils/icon/CommonFunctions";
const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};
const typeOptions = [
  { value: "on_demand", label: "On Demand Message" },
  { value: "scheduled", label: "Schedule Message" },
];
const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};
const frequencyOptions = [
  { label: "None", value: "NONE" },
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];

const dayOptions = Array.from({ length: 31 }, (_, i) => ({
  value: i + 1,
  label: (i + 1).toString(),
}));

const dayOfWeekOptions = [
  { value: 0, label: "Monday" },
  { value: 1, label: "Tuesday" },
  { value: 2, label: "Wednesday" },
  { value: 3, label: "Thursday" },
  { value: 4, label: "Friday" },
  { value: 5, label: "Saturday" },
  { value: 6, label: "Sunday" },
];

const CreateMessages = (
  { isOpenDrawer, setIsOpenDrawer, drawerState, values, fetchAlltasks },
  props
) => {
  const [initialValues, setInitialValues] = useState({
    type: { value: "on_demand", label: "On Demand Message" },

    title: "",
    message: "",
    project: null,

    start_date: null,
    end_date: null,
    frequency: { label: "None", value: "NONE" },

    weekday: null,
    month_day: null,
  });

  const validationSchema = yup.object({
    // Title is required
    title: yup.string().required("Title is required"),
    message: yup
      .string()
      .test("not-empty-or-br", "Please, Enter Content", (value) => {
        const trimmedValue = value?.trim();
        return trimmedValue && trimmedValue !== "<p><br></p>";
      })
      .required("Content is required"),
    project: yup
      .object()
      .shape({
        value: yup.string().required("Project is required"),
        label: yup.string(),
      })
      .nullable()
      .required("Project is required"),

    start_date: yup
      .date()
      .nullable()
      .when("type.value", {
        is: "scheduled",
        then: (schema) => schema.required("Schedule Start Date is required"),
        otherwise: (schema) => schema.notRequired(),
      }),

    end_date: yup
      .date()
      .nullable()
      .when("type.value", {
        is: "scheduled",
        then: (schema) => schema.required("Schedule End Date is required"),
        otherwise: (schema) => schema.notRequired(),
      }),

    weekday: yup
      .array()
      .nullable()
      .when(["frequency.value", "type.value"], {
        is: (frequencyValue, type) =>
          frequencyValue === "WEEKLY" && type === "scheduled",

        then: (schema) => schema.min(1, "Weekday is required"),
        otherwise: (schema) => schema.notRequired(),
      }),

    month_day: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .when(["frequency.value", "type.value"], {
        is: (frequencyValue, type) =>
          frequencyValue === "MONTHLY" && type === "scheduled",
        then: (schema) => schema.required("Month day is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    frequency: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .when("type.value", {
        is: "scheduled",
        then: (schema) => schema.required("Repeat Type is required!"),
        otherwise: (schema) => schema.notRequired(),
      }),
  });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const { projectId } = useParams();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (valuess) => {
      setIsLoading(true);

      try {
        let response = await dispatch(
          drawerState === "edit"
            ? updateProjectMessage({
                ...valuess,
                type: valuess.type.value,
                project: valuess.project?.value,
                frequency: valuess.frequency?.value,
                month_day: valuess.month_day?.value,
                weekday: valuess?.weekday?.map((item) => item.value),
                id: values?.id,
              })
            : createProjectMessage({
                ...valuess,
                type: valuess.type.value,
                project: valuess.project?.value,
                frequency: valuess.frequency?.value,
              })
        );
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message:
                drawerState === "edit"
                  ? "Message Updated Succesfully."
                  : "Message Created Succesfully.",
            })
          );
          setIsOpenDrawer(false);
          fetchAlltasks();
          formik.resetForm();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });

  const handleCloseDrawer = () => {
    formik.resetForm();
    setIsOpenDrawer(false);
  };

  const fetchAllProjects = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllProject({
        page_number: 1,
        page_size: 1000000,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let temp = payload?.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setProjects(temp);

      formik.setFieldValue(
        "project",
        temp?.find((item) => +item.value === +projectId)
      );
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  const setTaskValues = () => {
    console.log(
      projects,
      values,
      projects?.find((item, value) => +item.value === +values?.project?.id)
    );
    let data = {
      title: values?.title,
      type: typeOptions?.find((item, value) => item.value === values?.type),
      message: values?.message,
      project: projects?.find(
        (item, value) => +item.value === +values?.project?.id
      ),

      frequency: frequencyOptions?.find(
        (item, value) => item.value === values?.frequency
      ),
      month_day: values?.month_day
        ? dayOptions?.find((item, value) => item.value === +values?.month_day)
        : null,

      weekday: values.weekday
        ? dayOfWeekOptions.filter((item) => values.weekday.includes(item.value))
        : [],
      start_date: values.start_date
        ? new Date(values.start_date).toISOString().split("T")[0]
        : "",
      end_date: values.end_date
        ? new Date(values.end_date).toISOString().split("T")[0]
        : "",
    };

    setInitialValues(data);
    formik.setValues(data);
  };

  useEffect(() => {
    if (drawerState !== "create") setTaskValues();
  }, [values, projects]);

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // slots={{ backdrop: Backdrop }}
        title={
          drawerState === "view"
            ? "Message Detail"
            : drawerState === "create"
            ? "Create Message"
            : "Edit Message"
        }
        open={isOpenDrawer}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent>
          <form className={drawerState === "view" && "form-disabled"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Type
                  </Typography>
                  <Selectt
                    options={typeOptions}
                    value={formik?.values?.type}
                    onChange={(e) => {
                      formik.setFieldValue("type", e);
                    }}
                    isDisabled={drawerState === "edit"}
                    styles={customStyles}
                    placeholder="Select Type"
                    menuPosition="fixed"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    classNamePrefix="select"
                  />
                  {formik.touched.type && formik.errors.type && (
                    <div className="form-error-message">
                      {formik.errors.type}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Title
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Title"
                    size="small"
                    name="title"
                    {...formik.getFieldProps("title")}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="form-error-message">
                      {formik.errors.title}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Select Site
                  </Typography>
                  <Selectt
                    styles={customStyles}
                    size="small"
                    fullWidth
                    options={projects}
                    value={formik?.values?.project}
                    onChange={(e) => {
                      formik.setFieldValue("project", e);
                    }}
                    classNamePrefix="select"
                    placeholder={"Select Project..."}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isLoading={projects}
                    menuPosition="fixed"
                  ></Selectt>
                  {formik.touched.project && formik.errors.project && (
                    <div className="form-error-message">
                      {formik.errors.project}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Content
                  </Typography>

                  <Box
                    className={
                      drawerState === "view"
                        ? "rich-editor view"
                        : "rich-editor "
                    }
                  >
                    <ReactQuill
                      theme="snow"
                      value={formik.values.message}
                      modules={
                        drawerState === "view" ? { toolbar: false } : modules
                      }
                      onChange={(value) =>
                        formik.setFieldValue("message", value)
                      }
                      readOnly={drawerState === "view"}
                    />
                  </Box>

                  {formik.touched.message && formik.errors.message && (
                    <div className="form-error-message">
                      {formik.errors.message}
                    </div>
                  )}
                </Box>
              </Grid>
              {formik.values.type?.value === "scheduled" && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                    <Box variant="div" component="div" sx={BootstrapInput}>
                      <Typography variant="body1" component="label">
                        Schedule Start Date
                      </Typography>
                      <TextField
                        disabled={drawerState === "view"}
                        fullWidth
                        type="date"
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Select Schedule Start Date"
                        size="small"
                        name="Schedule Start Date"
                        InputProps={{
                          inputProps: {
                            min: new Date().toISOString().split("T")[0],
                          }, // Restrict past dates
                        }}
                        {...formik.getFieldProps("start_date")}
                      />
                      {formik.touched.start_date &&
                        formik.errors.start_date && (
                          <div className="form-error-message">
                            {formik.errors.start_date}
                          </div>
                        )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                    <Box variant="div" component="div" sx={BootstrapInput}>
                      <Typography variant="body1" component="label">
                        Schedule End Date
                      </Typography>
                      <TextField
                        disabled={drawerState === "view"}
                        fullWidth
                        type="date"
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Select Scehdule End Date"
                        size="small"
                        name="Schedule End Date"
                        InputProps={{
                          inputProps: {
                            min: formik.values.start_date
                              ? new Date(formik.values.start_date)
                                  .toISOString()
                                  .split("T")[0]
                              : undefined, // Fallback to undefined if start_date is not set
                          },
                        }}
                        {...formik.getFieldProps("end_date")}
                      />
                      {formik.touched.end_date && formik.errors.end_date && (
                        <div className="form-error-message">
                          {formik.errors.end_date}
                        </div>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box variant="div" component="div" sx={BootstrapInput}>
                      <Typography variant="body1" component="label">
                        Repeat Type
                      </Typography>
                      <Selectt
                        options={frequencyOptions}
                        value={formik?.values?.frequency}
                        onChange={(e) => {
                          formik.setFieldValue("frequency", e);
                        }}
                        styles={customStyles}
                        placeholder="Select Repeat Type"
                        menuPosition="fixed"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        classNamePrefix="select"
                      />
                      {formik.touched.frequency && formik.errors.frequency && (
                        <div className="form-error-message">
                          {formik.errors.frequency}
                        </div>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                    {formik?.values?.frequency?.value === "WEEKLY" && (
                      <>
                        <Typography variant="body1" component="label">
                          Select a day of the week
                        </Typography>

                        <Selectt
                          styles={customStyles}
                          isMulti
                          size="small"
                          fullWidth
                          options={dayOfWeekOptions}
                          value={formik?.values?.weekday}
                          onChange={(e) => {
                            formik.setFieldValue("weekday", e);
                          }}
                          classNamePrefix="select"
                          placeholder={"Select days of the week"}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          menuPosition="fixed"
                          menuPlacement="top"
                        ></Selectt>
                        {formik.touched.weekday && formik.errors.weekday && (
                          <div className="form-error-message">
                            {formik.errors.weekday}
                          </div>
                        )}
                      </>
                    )}
                    {formik?.values?.frequency?.value === "MONTHLY" && (
                      <>
                        <Typography variant="body1" component="label">
                          Select a day
                        </Typography>
                        <Selectt
                          options={dayOptions}
                          value={formik?.values?.month_day}
                          onChange={(e) => {
                            formik.setFieldValue("month_day", e);
                          }}
                          styles={customStyles}
                          placeholder="Select day of Month"
                          menuPosition="fixed"
                          menuPlacement="top"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          classNamePrefix="select"
                        />
                        {formik.touched.month_day &&
                          formik.errors.month_day && (
                            <div className="form-error-message">
                              {formik.errors.month_day}
                            </div>
                          )}
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </DialogContent>
        {drawerState !== "view" && (
          <DialogActions>
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Save
                </Button>

                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </DialogActions>
        )}
      </CustomizedDialog>
    </>
  );
};

export default CreateMessages;

import React, { useEffect, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  InputBase,
  IconButton,
  Checkbox,
  Tab,
  Tabs,
  Box,
  Drawer,
} from "@mui/material";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { useTreeItem2Utils } from "@mui/x-tree-view/hooks";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import "../../../../styles/externalAssistant.scss";
import { getAllKBFilesThunk } from "../../../../redux/slices/assistants.slice";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { CleaningServices } from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SelecteKnowledgeBase = ({ id, formik, disabled, docs }) => {
  const [value, setValue] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [kbFiles, setKbFile] = useState([]);
  const [filterdkbFiles, setFilterdKbFile] = useState([]);
  const [selectedKbFiles, setSelectedKbFile] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  // formik.values.project_safety_docs = {
  //   459: [
  //     {
  //       fileName: "Path-to-the-next-normal-collection.pdf",
  //       fileurl:
  //         "https://pri42291sahndev.blob.core.windows.net/tenant-151/KB-459/f3496923-9d14-497a-a8fc-952a10bf0694.pdf",
  //     },
  //     {
  //       filename: "the-state-of-organizations-2023.pdf",
  //       fileurl:
  //         "https://pri42291sahndev.blob.core.windows.net/tenant-151/KB-459/1ebe808e-3cd9-4ae8-bb2e-abdc8934802a.pdf",
  //     },
  //   ],
  // };
  // useEffect(() => {
  //   if (disabled) setValue(1);
  // }, [disabled]);

  const changeSelectedFile = (kbs) => {
    if (id) {
      let value = [];
      if (docs) {
        Object.keys(docs).length &&
          Object.keys(docs).forEach((item) => {
            const findedKB = kbs?.find((data) => data.id === Number(item));
            docs[item]?.length !== findedKB?.children?.length
              ? docs[item].forEach((ele) => {
                  const fileData = findedKB?.children?.filter(
                    (data) => data.id === ele.fileurl
                  );
                  if (
                    value.find((valueData) => valueData.id === Number(item))
                  ) {
                    value = value.map((valueEle) => {
                      return {
                        ...valueEle,
                        children: [
                          ...valueEle.children,
                          ...(Number(item) === valueEle.id ? fileData : []),
                        ],
                      };
                    });
                  } else {
                    value.push({
                      ...findedKB,
                      children: [...(fileData ?? [])],
                    });
                  }
                })
              : value.push(findedKB);
          });
      }

      setSelectedKbFile(value);

      setSelectedItems([
        ...value.map((datas) => datas.id),
        ...value
          .map((datas) => datas?.children)
          .flat()
          .map((datas) => datas.id),
      ]);
    }
  };

  useEffect(() => {
    changeSelectedFile(kbFiles);

    // eslint-disable-next-line
  }, [id]);

  const dispatch = useDispatch();

  function searchItems(data, searchText) {
    const results = [];

    data.forEach((item) => {
      if (item.label.toLowerCase().includes(searchText.toLowerCase())) {
        results.push(item);
      } else if (item.children && item.children.length > 0) {
        const childResults = searchItems(item.children, searchText);
        if (childResults.length > 0) {
          results.push({
            ...item,
            children: childResults,
          });
        }
      }
    });

    return results;
  }

  function handleSearchClick(searchValue) {
    value === 1 && setValue(0);
    setFilterdKbFile(searchValue ? searchItems(kbFiles, searchValue) : kbFiles);
  }

  const fetchAllAssistants = async () => {
    let response = await dispatch(getAllKBFilesThunk());

    if (response?.payload?.status) {
      const kbs = (response?.payload?.data || []).map((item) => ({
        id: item.kbId,
        label: item.kbName,
        children: item?.kbfiles?.files
          ? item?.kbfiles?.files?.map((ele) => ({
              id: ele.fileurl,
              label: ele.filename,
              parentId: item.kbId,
            }))
          : [],
      }));
      setKbFile(kbs);
      changeSelectedFile(kbs);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response?.payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchAllAssistants();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let kbData = {};
    selectedKbFiles.length &&
      selectedKbFiles?.map((item) => {
        return (kbData = {
          ...kbData,
          [item.id]: item?.children?.map((ele) => ({
            filename: ele.label,
            fileurl: ele.id,
          })),
        });
      });
    formik.setFieldValue("project_safety_docs", kbData);
    // eslint-disable-next-line
  }, [selectedKbFiles]);

  const handleChange = (event, newValue) => {
    setSearchTerm("");

    setValue(newValue);
  };

  const handleAllItemsChange = (event, ids) => {
    const kbData = kbFiles.find((item) => item.id === ids);
    let kbfilesList = kbData?.children?.map((ele) => ele.id);
    setSelectedItems((pre) => {
      if (pre.find((item) => item === ids)) {
        const filteredData = pre.filter((item) => item !== ids);
        return kbfilesList?.length
          ? [...filteredData.filter((item) => !kbfilesList.includes(item))]
          : [...filteredData];
      } else {
        if (kbData) {
          return [...pre, ids, ...kbfilesList];
        } else {
          const data = kbFiles.find((item) =>
            item.children.find((child) => child.id === String(ids))
          );
          return [...pre, ids, data.id];
        }
      }
    });

    setSelectedKbFile((pre) => {
      let selectedDoc = [...pre];
      if (pre.find((item) => item.id === ids)) {
        return [...pre.filter((item, i) => item.id !== ids)];
      } else if (
        pre.some((item) =>
          item.children.find((child) => child.id === String(ids))
        )
      ) {
        return [
          ...[
            ...pre.map((item) => ({
              ...item,
              children: item.children.filter((ele) => ele.id !== ids),
            })),
          ].filter((item) => (item.children.length ? true : false)),
        ];
      } else {
        kbFiles.find((item) => item.id === ids)
          ? selectedDoc.push(kbFiles.find((item) => item.id === ids))
          : kbFiles.forEach((item) => {
              if (selectedDoc.find((ele) => ele.id === item.id)) {
                selectedDoc = selectedDoc.map((ele) => ({
                  ...ele,
                  children: [
                    ...ele.children,
                    ...(item.id === ele.id
                      ? item.children.filter((child) => child.id === ids)
                      : []),
                  ],
                }));
              } else {
                item.children.forEach(
                  (ele) =>
                    ele.id === ids &&
                    selectedDoc.push({
                      ...item,
                      children: item.children.filter((ele) => ele.id === ids),
                    })
                );
              }
            });
      }
      return selectedDoc;
    });
  };

  /* eslint-disable eqeqeq */
  const handleSelectedItemsChange = (event, ids) => {
    const customId = ids.substring(0, ids.length - 6);
    setSelectedItems((pre) => {
      if (pre.find((item) => item == customId)) {
        const kbData = kbFiles.find((item) => item.id === Number(customId));
        let kbfilesList = kbData?.children?.map((ele) => ele.id);
        return kbfilesList?.length
          ? [
              ...pre
                .filter((item, i) => item != customId)
                .filter((item) => !kbfilesList.includes(item)),
            ]
          : [...pre.filter((item, i) => item != customId)];
      } else {
        return [...pre];
      }
    });

    setSelectedKbFile((pre) => {
      if (pre.find((item) => item.id == customId)) {
        return [...pre.filter((item, i) => item.id != customId)];
      } else if (
        pre.some((item) => item.children.find((child) => child.id === customId))
      ) {
        return [
          ...[
            ...pre.map((item) => ({
              ...item,
              children: item.children.filter((ele) => ele.id !== customId),
            })),
          ].filter((item) => (item.children.length ? true : false)),
        ];
      } else {
        return pre;
      }
    });
  };
  /* eslint-enable eqeqeq */

  const SelectedCustomTreeItem = forwardRef(function MyTreeItem(props, ref) {
    const { interactions } = useTreeItem2Utils({
      itemId: props.itemId,
      children: props.children,
    });

    const handleContentClick = (event) => {
      event.defaultMuiPrevented = true;
      interactions.handleSelection(event);
    };

    const handleIconContainerClick = (event) => {
      event.stopPropagation();
      interactions.handleExpansion(event);
    };

    return (
      <TreeItem2
        {...props}
        ref={ref}
        slotProps={{
          content: { onClick: handleContentClick },
          iconContainer: { onClick: handleIconContainerClick },
        }}
      />
    );
  });

  const CustomTreeItem = forwardRef(function MyTreeItem(props, ref) {
    const { interactions } = useTreeItem2Utils({
      itemId: props.itemId,
      children: props.children,
    });

    const handleContentClick = (event) => {
      event.defaultMuiPrevented = true;
      interactions.handleSelection(event);
    };

    const handleIconContainerClick = (event) => {
      event.stopPropagation();
      interactions.handleExpansion(event);
    };
    const checked = props?.children?.length
      ? selectedKbFiles.find((item) => item.id === props.itemId) ||
        selectedKbFiles.filter((item) => item.id === props.itemId).length ===
          props?.children?.length
      : selectedKbFiles.find((item) => item.id === props.itemId) ||
        selectedKbFiles.some((item) =>
          item.children.find((child) => child.id === String(props.itemId))
        );

    const indeterminate = selectedKbFiles.filter(
      (item) =>
        item?.id === props.itemId &&
        item?.children?.length !== props?.children?.length
    );

    return (
      <TreeItem2
        {...props}
        label={
          <>
            <Checkbox
              checked={checked}
              indeterminate={
                props?.children && indeterminate.length
                  ? indeterminate.length !== props?.children?.length
                  : false
              }
            />{" "}
            {props.label}
          </>
        }
        ref={ref}
        slotProps={{
          content: { onClick: handleContentClick },
          iconContainer: { onClick: handleIconContainerClick },
        }}
      />
    );
  });

  const checkValidKbFiles = () => {
    selectedKbFiles.forEach((item) => {
      if (!item.label) {
        return false;
      }
    });
    return true;
  };
  return (
    <>
      {/* {!disabled && ( */}
      <>
        <Paper
          elevation={0}
          sx={{
            p: "2px 4px",
            display: "flex",
            maxHeight: "44px",
            alignItems: "center",
            width: "auto",
            borderRadius: "8px",
            border: 1,
            borderColor: "rgb(208, 204, 204)",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search your knowledge base..."
            inputProps={{ "aria-label": "search" }}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearchClick(e.target.value);
            }}
          />
          {searchTerm.length > 0 && (
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => {
                setSearchTerm("");
              }}
            >
              <CancelIcon />
            </IconButton>
          )}
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </>
      {/* )} */}
      <Box sx={{ width: "100%" }} className="KbTreeView">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            className="active_tab"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* {!disabled && ( */}
            <Tab className="KB_Tab" label="All Files" {...a11yProps(0)} />
            {/* )} */}
            <Tab
              className="KB_Tab"
              label="Selected Files"
              {...a11yProps()}
              // disabled={disabled}
            />
          </Tabs>
        </Box>
        {/* {!disabled && ( */}
        <CustomTabPanel className="all-files" value={value} index={0}>
          {(searchTerm ? filterdkbFiles : kbFiles).length ? (
            <Box sx={{ height: 220 }}>
              <RichTreeView
                aria-label="icon expansion"
                slots={{ item: CustomTreeItem }}
                items={searchTerm ? filterdkbFiles : kbFiles}
                selectedItems={selectedItems}
                onSelectedItemsChange={handleAllItemsChange}
                disableSelection={disabled}
              />
            </Box>
          ) : (
            <Box
              className="center"
              sx={{
                textAlign: "center",
                minHeight: "150px",
                Color: "lightGrey",
                fontSize: "16px",
              }}
            >
              No items found using that search term.
            </Box>
          )}
        </CustomTabPanel>
        {/* )} */}
        <CustomTabPanel className="all-files" value={value} index={1}>
          {selectedItems.length && checkValidKbFiles() ? (
            <RichTreeView
              aria-label="icon expansion"
              slots={{ item: SelectedCustomTreeItem }}
              items={selectedKbFiles?.map((item) => ({
                ...item,
                id: item.id + "secret",
                children: item?.children?.map((ele) => ({
                  ...ele,
                  id: ele.id + "secret",
                })),
              }))}
              selectedItems={selectedItems?.map((item) => item + "secret")}
              onSelectedItemsChange={handleSelectedItemsChange}
              disableSelection={disabled}
            />
          ) : (
            <Box
              className="center"
              sx={{
                textAlign: "center",
                minHeight: "150px",
                Color: "lightGrey",
                fontSize: "16px",
              }}
            >
              No items have been selected.
            </Box>
          )}
        </CustomTabPanel>
      </Box>
      <Drawer
        anchor={"right"}
        className="side-drawer"
        open={openDrawer}
        // onClose={() => {
        //   setSelectedPreview();
        // }}
      >
        <iframe
          src={`https://pri42291sahndev.blob.core.windows.net/tenant-151/KB-459/f3496923-9d14-497a-a8fc-952a10bf0694.pdf`}
          title={"temp"}
          height="100%"
          width="100%"
        />
      </Drawer>
    </>
  );
};

export default SelecteKnowledgeBase;

import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  InputBase,
  Paper,
  Stack,
  Chip,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  Menu,
  MenuItem,
  Grid,
  Card,
  CardContent,
} from "@mui/material";

import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";

import DataGridTable from "../dataGrid";
import moment from "moment";

import FilterDrawer from "../../layout/filterDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { MdOutlineWatchLater, MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../assets/icons/pencil.svg";

import DeleteIcon from "../../../assets/icons/deleteRed.svg";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { ReportType, Status } from "../../../utils/icon/CommonFunctions";
import CustomizedDialog from "../Dialog";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Selectt from "react-select";

import {
  deleteServiceUnitTask,
  updateServiceUnitTask,
} from "../../../redux/slices/serviceUnit.slice";

import { FaPlayCircle, FaRegPlayCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import CreateAudit from "./createAudit";
import {
  getAllAudits,
  getAuditTemplates,
  getTenantReportById,
} from "../../../redux/slices/tenant.slice";
import CreateFormModal from "../forms/createFormModal";
import EditFormModal from "../forms/editFormModal";

import CreateReport from "../createReport";

const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    // padding: "14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

const severityOptions = [
  { value: "All", label: "All" },
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "high", label: "High" },
];
const statusOptions = [
  { value: "All", label: "All" },
  { value: "completed", label: "Completed" },

  { value: "pending", label: "Pending" },
  { value: "up_coming", label: "Upcoming" },
];

export default function AuditList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [taskData, setTaskData] = useState([]);

  const [totalRow, setTotalRow] = useState(0);
  const { id } = useParams();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("due_date");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [selectedFormId, setSelectedFormId] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenCreateDrawer, setIsOpenCreateDrawer] = useState(false);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");
  const [reviewCompleteOpen, setReviewCompleteOpen] = useState(false);
  const [auditTemplates, setAuditTemplates] = useState([]);
  const [selectedTodo, setSelectedTodo] = useState();
  const [reportLayout, setReportLayout] = useState(false);
  const [reportData, setReportData] = useState();
  const [imageToken, setImageToken] = useState("");
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [statusFilter, setStatusFilter] = useState({
    value: "All",
    label: "All",
  });
  const [severityFilter, setSeverityFilter] = useState({
    value: "All",
    label: "All",
  });
  const getAllTasks = async () => {
    setLoading(true);
    let custom_filter = {};

    if (severityFilter.length > 0) {
      custom_filter.severity__in = severityFilter.map((item) => item.value);
    }

    let response = await dispatch(
      getAllAudits({
        page_number: paginationModel?.page + 1,
        page_size: paginationModel?.pageSize,

        sort: sortKey,
        report: id,
        status:
          statusFilter?.value === "All"
            ? undefined
            : statusFilter?.value === "completed"
            ? statusFilter?.value
            : "up_coming",

        severity:
          severityFilter?.value === "All" ? undefined : severityFilter?.value,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let tempData = [];
      if (statusFilter?.value === "pending") {
        tempData = payload?.data?.filter((item) =>
          isDueDatePast(item.due_date)
        );
      } else if (statusFilter?.value === "up_coming") {
        tempData = payload?.data?.filter(
          (item) => !isDueDatePast(item.due_date)
        );
      } else {
        tempData = payload?.data;
      }
      setTaskData(tempData);

      setTotalRow(tempData?.length);
    }
    setLoading(false);
  };
  const fetchReportById = async () => {
    setLoading(true);

    let response = await dispatch(getTenantReportById({ id: id }));
    const { payload } = response;
    if (payload?.status) {
      setReportData(payload?.data);
      setImageToken(payload?.token);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchReportById();
  }, []);

  const deleteForm = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        deleteServiceUnitTask({ id: selectedFormId?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "form deleted succesfully",
          })
        );
        getAllTasks();
        setOpenDeleteModal(false);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  const fetchAUDITTemplates = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getAuditTemplates({ type: "audit" }));
      const { payload } = response;
      if (payload?.status) {
        setAuditTemplates(
          payload?.data?.map((item) => ({ label: item.title, value: item.id }))
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    getAllTasks();
  }, [paginationModel, sortKey, severityFilter, statusFilter]);

  useEffect(() => {
    fetchAUDITTemplates();
  }, []);

  const handleUpdateStatus = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        updateServiceUnitTask({
          data: { status: "completed" },
          id: selectedFormId?.id,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Task Status Updated Successfully.",
          })
        );
        setReviewCompleteOpen(false);
        getAllTasks();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event, row) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedRow(null);
  };

  function ActionCell(params) {
    return (
      <Stack direction="row" spacing={0} alignItems="center">
        {/* Three Dot Menu */}

        {/* Start Inspection Button */}
        <Tooltip
          title={
            !params?.row?.has_audit_submission
              ? "Start Inspection"
              : "Edit Inspection"
          }
        >
          <IconButton
            onClick={() => {
              if (!params?.row?.has_audit_submission) {
                // navigate(
                //   `/reports/${id}/audit/${params.row.id}/inspection/create`,
                //   {
                //     state: {
                //       templateId: params.row.audit_form.id,
                //     },
                //   }
                // );
                setIsOpenCreateDrawer(true);
                setSelectedTodo(params?.row);
              } else {
                // navigate(
                //   `/reports/${id}/audit/${params.row.id}/inspection/${params.row.inspection_id}/edit`
                // );
                setIsOpenEditDrawer(true);
                setSelectedTodo(params?.row);
              }
            }}
          >
            {!params?.row?.has_audit_submission ? (
              <FaPlayCircle size={22} color="#036185" />
            ) : (
              <FaRegPlayCircle size={22} color="#036185" />
            )}
          </IconButton>
        </Tooltip>

        {/* Mark as Complete or Completed Button */}
        {params.row.status === "completed" ? (
          <Tooltip title="Completed">
            <IconButton sx={{ color: "#000000" }} disabled>
              <IoIosCheckmarkCircle size={24} color="green" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Mark as Complete">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                setReviewCompleteOpen(true);

                setSelectedFormId(params?.row);
              }}
            >
              <MdOutlineWatchLater size={24} color="orange" />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Options">
          <IconButton onClick={(event) => handleMenuOpen(event, params.row)}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          {/* View Option */}
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setDrawerState("view");
              setIsOpenDrawer(true);

              setSelectedFormId(selectedRow);
            }}
          >
            <MdRemoveRedEye
              size={20}
              style={{ marginRight: 8, color: "#036185" }}
            />
            View
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleMenuClose();
              setDrawerState("edit");
              setIsOpenDrawer(true);

              setSelectedFormId(selectedRow);
            }}
            disabled={selectedRow?.status === "completed"}
          >
            <img
              alt="Edit Details"
              src={Pencil}
              width={20}
              height={20}
              style={{ marginRight: 8 }}
            />
            Edit
          </MenuItem>

          {/* Delete Option */}
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setOpenDeleteModal(true);
              setSelectedFormId(selectedRow);
            }}
          >
            <img
              alt="Delete Details"
              src={DeleteIcon}
              width={20}
              height={20}
              style={{ marginRight: 8 }}
            />
            Delete
          </MenuItem>
        </Menu>
      </Stack>
    );
  }

  const isDueDatePast = (dueDate) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
    const due = new Date(dueDate).toISOString().split("T")[0]; // Get due date in YYYY-MM-DD format

    return due < today; // Compare date strings
  };

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "title")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "severity")}
        >
          Severity
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: 1, // Flex value of 2 (20% of the space if the total flex is 10)
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "due_date")}
        >
          Due Date
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      // Flex value of 1 (10% of the space if the total flex is 10)

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "status")}
        >
          Status
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: ({ row }) => {
        if (row.status) {
          if (row.status !== "completed" && isDueDatePast(row.due_date)) {
            return (
              <Chip
                label={Status["PENDING"]?.label}
                style={{
                  backgroundColor: Status["PENDING"]?.color,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              />
            );
          } else if (row.status) {
            return (
              <Chip
                label={Status[row?.status]?.label}
                style={{
                  backgroundColor: Status[row?.status]?.color,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              />
            );
          } else {
            return "-";
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      // Flex value of 1 (10% of the space if the total flex is 10)

      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
    },
  ];

  function ReportDetails() {
    return (
      <Card variant="outlined" sx={{ padding: 1, margin: "auto" }}>
        <CardContent>
          <Grid container spacing={2}>
            {/* Incident Title */}
            <Grid item xs={12} sm={4}>
              <Typography variant="body1" component="label">
                Incident Title:
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "16px !important" }}>
                {reportData?.title || "-"}
              </Typography>
            </Grid>

            {/* Project Title */}
            <Grid item xs={12} sm={4}>
              <Typography variant="body1" component="label">
                Site Title:
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "16px !important" }}>
                {reportData?.project?.name || "-"}
              </Typography>
            </Grid>

            {/* Description */}
            <Grid item xs={12} sm={4}>
              <Typography vvariant="body1" component="label">
                Description:
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "16px !important" }}>
                {reportData?.description || "-"}
              </Typography>
            </Grid>

            {/* Type of Incident */}
            <Grid item xs={12} sm={4}>
              <Typography variant="body1" component="label">
                Type of Incident:
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "16px !important" }}>
                {reportData?.type ? ReportType[reportData?.type] : "-"}
              </Typography>
            </Grid>

            {/* Assigned To */}
            <Grid item xs={12} sm={4}>
              <Typography variant="body1" component="label">
                Assigned To:
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "16px !important" }}>
                {reportData?.assigned_to
                  ? `${reportData?.assigned_to?.first_name} ${reportData?.assigned_to?.last_name}`
                  : "-"}
              </Typography>
            </Grid>

            {/* Reported By */}
            <Grid item xs={12} sm={4}>
              <Typography variant="body1" component="label">
                Reported By:
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "16px !important" }}>
                {reportData?.user
                  ? `${reportData?.user?.first_name} ${reportData?.user?.last_name}`
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  // useEffect(() => {
  //   if (reportData?.title)
  //     dispatch(setHeaderTitle(reportData?.title + " - Audits"));
  //   else {
  //     dispatch(setHeaderTitle(""));
  //   }
  // }, [reportData]);

  return (
    <>
      <div className="history">
        <Box className="content-header kb-list-header mb-2">
          <Button
            variant="contained"
            size="large"
            className="primary-button"
            startIcon={<VisibilityIcon />}
            disableRipple
            onClick={() => setReportLayout(true)}
            style={{ height: "44px" }}
          >
            Incident Details
          </Button>
          <Box
            variant="div"
            component="div"
            className="content-header-right"
            sx={{ marginLeft: "auto" }}
          >
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => {
                setDrawerState("create");
                setIsOpenDrawer(true);
              }}
              disabled={auditTemplates.length === 0}
            >
              Intiate Audit
            </Button>

            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={() => setOpenFilterDrawer(true)}
              style={{ height: "44px" }}
            >
              Filters
            </Button>
          </Box>
        </Box>
        <ReportDetails />
        <Box sx={{ flexGrow: 1 }} mt={1}>
          <DataGridTable
            data={
              searchTerm
                ? taskData.filter((item) =>
                    item.title
                      ?.toLowerCase()
                      .includes(searchTerm?.toLowerCase())
                  )
                : taskData
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={loading}
            paginationModel={paginationModel}
            totalRow={totalRow}
            height="calc(100vh - 450px)"
          />
        </Box>
      </div>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete {selectedFormId?.title} ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={deleteForm}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Update Status"}
        open={reviewCompleteOpen}
        handleClose={() => {
          setReviewCompleteOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are You Sure You want to mark the <b>{selectedFormId?.title}</b>{" "}
              as completed?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={handleUpdateStatus}
            >
              {loading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setReviewCompleteOpen(false)}
            >
              {loading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      {isOpenDrawer && (
        <CreateAudit
          drawerState={drawerState}
          setIsOpenDrawer={setIsOpenDrawer}
          isOpenDrawer={isOpenDrawer}
          values={selectedFormId}
          setAuditTemplates={setAuditTemplates}
          auditTemplates={auditTemplates}
          getAllTasks={getAllTasks}
        />
      )}

      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box>
          <Typography variant="body1" component="label">
            Severity
          </Typography>

          <Selectt
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={severityOptions}
            value={severityFilter}
            onChange={(e) => {
              setSeverityFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Severity..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Audit Status
          </Typography>

          <Selectt
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={statusOptions}
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Status..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
      </FilterDrawer>

      {isOpenCreateDrawer && (
        <CreateFormModal
          isOpenDrawer={isOpenCreateDrawer}
          setIsOpenDrawer={setIsOpenCreateDrawer}
          taskId={selectedTodo?.id}
          templateId={selectedTodo?.template_form.id}
          fetchAllTasks={getAllTasks}
        />
      )}
      {isOpenEditDrawer && (
        <EditFormModal
          mode="edit"
          isOpenDrawer={isOpenEditDrawer}
          setIsOpenDrawer={setIsOpenEditDrawer}
          templateId={selectedTodo?.inspection_id}
        />
      )}

      {reportLayout && (
        <CreateReport
          isLayout={reportLayout}
          setLayout={setReportLayout}
          values={reportData}
          drawerState={"view"}
          imageToken={imageToken}
          // setReportId={setReportId}
        />
      )}
    </>
  );
}

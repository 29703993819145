import { API_URL } from "../../constants/apiUrl.constant";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const updateNotification = createAsyncThunk(
  "updateNotification",
  async ({ data, notificationId }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.NOTIFICATION}/${notificationId}/update`,

        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllNotification = createAsyncThunk(
  "AllNotification",
  async (payload) => {
    console.log(payload);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_NOTIFICATION}?page_number=${payload.page}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getAllFeedbacks = createAsyncThunk(
  "AllFeedbacks",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.FEEDBACK}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const sendFeedback = createAsyncThunk(
  "sendFeedback",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.FEEDBACK}`,

        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateFeedback = createAsyncThunk(
  "updateFeedback",
  async ({ data, id }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.FEEDBACK}/${id}`,

        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDashboardMessages = createAsyncThunk(
  "getAllMessages",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.MESSAGES}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// Assistants slice
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {},
});

export default dashboardSlice.reducer;

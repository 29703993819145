import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useTable,
  useRowSelect,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  TextField,
  Box,
  Button,
  CircularProgress,
  TablePagination,
  Grid,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getAllTenantReport } from "../../../redux/slices/tenant.slice";
import { setSnackbar } from "../../../redux/slices/common.slice";
import moment from "moment";
import SaveIcon from "@mui/icons-material/Save";
import { UploadFile } from "@mui/icons-material";
import "../../../styles/form300.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import {
  createForm300,
  getForm300ById,
  updateForm300,
} from "../../../redux/slices/form300.slice";
import Loader from "../Loader";
import logAnalyticsEvent from "../../../firebase/analyticsLogger";
import { useSelector } from "react-redux";
// const initialData = [
//   {
//     caseNumber: "001",
//     employeeName: "John Doe",
//     jobTitle: "Worker",
//     dateOfInjury: "2024-01-10",
//     whereEventOccur: "basement",
//     injuryDescription: "Slipped on wet floor",
//     death: false,
//     daysAwayFromWork: false,
//     jobTransfer: false,
//     otherRecordable: false,
//     awayFromWorkDays: "10",
//     onJobTransferDays: "5",
//     injury: true,
//     skinDisorder: false,
//     respiratoryCondition: false,
//     poisoning: false,
//     hearingLoss: false,
//     allOtherIllness: false,
//   },
//   {
//     caseNumber: "002",
//     employeeName: "Jane Smith",
//     jobTitle: "Engineer",
//     dateOfInjury: "2024-02-15",
//     whereEventOccur: "2nd floor storeroom",
//     injuryDescription: "Hit by falling object",
//     death: false,
//     daysAwayFromWork: true,
//     jobTransfer: false,
//     otherRecordable: false,
//     awayFromWorkDays: "3",
//     onJobTransferDays: "2",
//     injury: false,
//     skinDisorder: true,
//     respiratoryCondition: false,
//     poisoning: false,
//     hearingLoss: false,
//     allOtherIllness: false,
//   },
//   // Add more dummy data as needed
// ];

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  if (
    ["death", "daysAwayFromWork", "jobTransfer", "otherRecordable"].includes(id)
  ) {
    return (
      <Box display="flex" justifyContent="center">
        <Radio
          checked={initialValue}
          onChange={(e) => updateMyData(index, id, e.target.checked)}
          size="small"
          style={{ padding: "4px" }}
        />
      </Box>
    );
  }

  if (
    [
      "injury",
      "skinDisorder",
      "respiratoryCondition",
      "poisoning",
      "hearingLoss",
      "allOtherIllness",
    ].includes(id)
  ) {
    return (
      <Box display="flex" justifyContent="center">
        <Radio
          checked={initialValue}
          onChange={(e) => updateMyData(index, id, e.target.checked)}
          size="small"
          style={{ padding: "4px" }}
        />
      </Box>
    );
  }

  if (["awayFromWorkDays", "onJobTransferDays"].includes(id)) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <TextField
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          variant="standard"
          InputProps={{
            disableUnderline: false,
            style: { borderBottom: "1px solid black", padding: "4px 0" },
          }}
          style={{
            width: "50%",
            textAlign: "center",
            backgroundColor: "#e7f5ff",
          }}
        />
        <span style={{ marginLeft: "4px" }}>days</span>
      </Box>
    );
  }

  return (
    <TextField
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      fullWidth
      variant="standard"
      InputProps={{
        disableUnderline: false,
        style: { borderBottom: "1px solid black", padding: "4px" },
      }}
      style={{ width: "100%", backgroundColor: "#e7f5ff" }}
    />
  );
};
const OSHAForm300Table = ({ mode }) => {
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [url, setUrl] = useState("");
  const { form300Id } = useParams();
  const user = useSelector((state) => state.user);

  const fetchReport = async () => {
    if (mode === "create") {
      setIsLoading(true);
      const selectedIds = JSON.parse(localStorage.getItem("selectedReports"));
      let send_payload = {
        page_number: page + 1,
        page_size: rowsPerPage,
        type: undefined,
        user_id: undefined,
        status: undefined,
        custom_filter: JSON.stringify({ id__in: selectedIds }),
      };

      let response = await dispatch(getAllTenantReport(send_payload));
      const { payload } = response;
      if (payload?.status) {
        let caseNo = 1;
        const newData = payload?.data?.flatMap((item, index) => {
          // Check if employees array exists
          if (!item.employees || !Array.isArray(item.employees)) {
            return []; // return empty if no employees
          }

          // Map over each employee in the employees array
          return item.employees.map((employee, empIndex) => {
            let tempData = {
              report_id: item?.id,
              caseNumber: caseNo++, // Adjust caseNumber to account for multiple employees
              employeeName: employee?.first_name + " " + employee?.last_name, // Use the employee's name from the array
              jobTitle: item.user.user_role, // Assuming jobTitle is specific to the employee
              dateOfInjury: moment(item.report_date).format("DD-MM-YYYY"),
              whereEventOccur: item.event_occurred,
              injuryDescription: item.injury_description,
              death: item.classify_of_case === "death",
              daysAwayFromWork: item.classify_of_case === "days away from work",
              jobTransfer:
                item.classify_of_case === "job transfer or restriction",
              otherRecordable:
                item.classify_of_case === "other recordable case",
              awayFromWorkDays: item.number_of_days_injured.away_work_days,
              onJobTransferDays: item.number_of_days_injured.job_transfer_days,
              injury: item.illness === "injury",
              skinDisorder: item.illness === "skin_disorder",
              respiratoryCondition: item.illness === "respiratory_condition",
              poisoning: item.illness === "poisoning",
              hearingLoss: item.illness === "hearing_loss",
              allOtherIllness: item.illness === "all_other_illness",
            };

            return tempData;
          });
        });
        setTotalRow(newData?.length);
        setData(newData); // Spread operator to create a new array reference
        setInitialData(newData);
      } else
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      setIsLoading(false);
    } else {
      setIsLoading(true);
      let response = await dispatch(
        getForm300ById({
          page_number: page,
          page_sze: rowsPerPage,
          form300Id: form300Id,
        })
      );
      const { payload } = response;

      if (payload?.status) {
        const newData = payload?.data?.form300_json;

        // Map over each employee in the employees array
        setDescription(payload?.data?.description);
        setTitle(payload?.data?.form_name);
        setTotalRow(newData?.length);
        setUrl(payload?.data?.form_link + "?" + payload?.data?.token);
        setData([...newData]); // Spread operator to create a new array reference
        setInitialData(JSON.parse(JSON.stringify(newData)));
      } else
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchReport();
  }, [page, rowsPerPage]);
  const updateMyData = (rowIndex, columnId, value) => {
    const injuryColumns = [
      "injury",
      "skinDisorder",
      "respiratoryCondition",
      "poisoning",
      "hearingLoss",
      "allOtherIllness",
    ];

    const recordableColumns = [
      "death",
      "daysAwayFromWork",
      "jobTransfer",
      "otherRecordable",
    ];

    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          // Initialize the new row object
          let updatedRow = { ...row, [columnId]: value };

          // Check if the columnId is in the recordable group
          if (recordableColumns.includes(columnId)) {
            // Set all recordable columns to false
            recordableColumns.forEach((col) => {
              updatedRow[col] = false;
            });
            updatedRow[columnId] = value; // Set the actual updated column to the provided value
          }

          // Check if the columnId is in the injury group
          if (injuryColumns.includes(columnId)) {
            // Set all injury columns to false
            injuryColumns.forEach((col) => {
              updatedRow[col] = false;
            });
            updatedRow[columnId] = value; // Set the actual updated column to the provided value
          }

          return updatedRow;
        }
        return row;
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const resetRow = useCallback(
    (rowIndex) => {
      setData((old) => {
        const newData = old.map((row, index) => {
          if (index === rowIndex) {
            return { ...initialData[index] }; // Create a shallow copy of the row
          }
          return { ...row }; // Ensuring new object reference for each row
        });

        return newData;
      });
    },
    [initialData]
  );

  function compareObjects(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return true; // Length mismatch, so there is a difference
    }

    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      // Check if both objects have the same keys and values
      for (let key in obj1) {
        if (obj1[key] !== obj2[key]) {
          return true; // Value mismatch found, they are different
        }
      }
    }
    return false; // No differences found
  }
  const handleSubmit = async (key) => {
    if (!title) {
      setTitleError("Title is Required");
      return;
    }
    if (!description) {
      setDescriptionError("Description is Required");
      return;
    }

    if (mode === "create") {
      setIsPDFLoading(true);

      try {
        let response = await dispatch(
          createForm300({ data, title, description, key })
        );
        const { payload } = response;
        if (payload && payload.status) {
          if (key === true) {
            await dispatch(
              setSnackbar({
                open: true,
                severity: "success",
                message: "Form300 Downloaded Succesfully.",
              })
            );

            const link = document.createElement("a");

            link.href = `${payload.data.form_link}?${payload.data.sas_token}`;
            link.target = "_blank";
            link.download = title; // Set the filename (you might want to get this from the backend)
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode.removeChild(link);
            logAnalyticsEvent("download_osha-form300", {
              user_id: user?.data?.user?.id,
            });
            navigate("/reports/form300");
          } else {
            await dispatch(
              setSnackbar({
                open: true,
                severity: "success",
                message: "Form300 created Succesfully.",
              })
            );
            navigate("/reports/form300");
            logAnalyticsEvent("create_osha-form300", {
              user_id: user?.data?.user?.id,
            });
          }
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsPDFLoading(false);
    } else {
      setIsPDFLoading(true);

      const isFormChanged = compareObjects(data, initialData);

      try {
        let response = await dispatch(
          updateForm300({
            data,
            title,
            description,
            form300Id,
            isFormChanged,
          })
        );
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "Form300 updated Succesfully.",
            })
          );
          logAnalyticsEvent("update_osha-form300", {
            user_id: user?.data?.user?.id,
          });
          if (key === true) {
            //for pdf update
            const link = document.createElement("a");

            link.href = payload.data.form_link + "?" + payload.data.token;
            link.target = "_blank";
            link.setAttribute("download", "abc.pdf");
            // link.download = "file.pdf"; // Set the filename (you might want to get this from the backend)
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode.removeChild(link);
          }
          logAnalyticsEvent("download_osha-form300", {
            user_id: user?.data?.user?.id,
          });

          navigate("/reports/form300");
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }

      setIsPDFLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Identify the Person",
        columns: [
          {
            Header: "Case No.",
            accessor: "caseNumber",
            id: "caseNumber",
            Cell: EditableCell,
            width: 50,
            minWidth: 50,
          },
          {
            Header: "Employee Name",
            accessor: "employeeName",
            id: "employeeName",
            Cell: EditableCell,
            width: 150,
            minWidth: 150,
          },
          {
            Header: "Job Title",
            accessor: "jobTitle",
            id: "jobTitle",
            Cell: EditableCell,
            width: 250,
            minWidth: 250,
          },
        ],
      },
      {
        Header: "Describe the Case",
        columns: [
          {
            Header: "Date of Injury",
            accessor: "dateOfInjury",
            id: "dateOfInjury",
            Cell: EditableCell,
            width: 130,
            minWidth: 130,
          },
          {
            Header: "Where the Event Occurred",
            accessor: "whereEventOccur",
            id: "whereEventOccur",
            Cell: EditableCell,
            width: 250,
            minWidth: 250,
          },
          {
            Header: "Injury Description",
            accessor: "injuryDescription",
            id: "injuryDescription",
            Cell: EditableCell,
            width: 300,
            minWidth: 250,
          },
        ],
      },
      {
        Header: "Classify the Case",
        columns: [
          {
            Header: "Death",
            accessor: "death",
            id: "death",
            Cell: EditableCell,
            width: 50,
            minWidth: 50,
            style: { backgroundColor: "#f0f8ff" },
          },
          {
            Header: "Days Away from Work",
            accessor: "daysAwayFromWork",
            id: "daysAwayFromWork",
            Cell: EditableCell,
            width: 100,
            minWidth: 100,
            style: { backgroundColor: "#f0f8ff" },
          },
          {
            Header: "Job Transfer",
            accessor: "jobTransfer",
            id: "jobTransfer",
            Cell: EditableCell,
            width: 80,
            minWidth: 80,
          },
          {
            Header: "Other Recordable Cases",
            accessor: "otherRecordable",
            id: "otherRecordable",
            Cell: EditableCell,
            width: 100,
            minWidth: 100,
          },
        ],
      },
      {
        Header: "No of Days Injured",
        columns: [
          {
            Header: "Away from Work",
            accessor: "awayFromWorkDays",
            id: "awayFromWorkDays",
            Cell: EditableCell,
            width: 120,
            minWidth: 120,
          },
          {
            Header: "On Job Transfer",
            accessor: "onJobTransferDays",
            id: "onJobTransferDays",
            Cell: EditableCell,
            width: 120,
            minWidth: 120,
          },
        ],
      },
      {
        Header: "Illness Type",
        columns: [
          {
            Header: "Injury",
            accessor: "injury",
            id: "injury",
            Cell: EditableCell,
            width: 50,
            minWidth: 50,
          },
          {
            Header: "Skin Disorder",
            accessor: "skinDisorder",
            id: "skinDisorder",
            Cell: EditableCell,
            width: 80,
            minWidth: 80,
          },
          {
            Header: "Respiratory Condition",
            accessor: "respiratoryCondition",
            id: "respiratoryCondition",
            Cell: EditableCell,
            width: 80,
            minWidth: 80,
          },
          {
            Header: "Poisoning",
            accessor: "poisoning",
            id: "poisoning",
            Cell: EditableCell,
            width: 90,
            minWidth: 90,
          },
          {
            Header: "Hearing Loss",
            accessor: "hearingLoss",
            id: "hearingLoss",
            Cell: EditableCell,
            width: 80,
            minWidth: 80,
          },
          {
            Header: "All Other Illness",
            accessor: "allOtherIllness",
            id: "allOtherIllness",
            Cell: EditableCell,
            width: 80,
            minWidth: 80,
          },
        ],
      },
    ],
    [resetRow]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        updateMyData,
        autoResetPage: false,
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect
    );

  return (
    <>
      {isPDFLoading && <Loader />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="action-btns"
        mb={2}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            navigate(-1);
          }}
          startIcon={<ArrowBackIcon />}
          className="primary-button back-button"
        >
          Back
        </Button>
        <Box
          sx={{ display: "flex", gap: "20px", justifyContent: "flex-end" }}
          className="inner-action-btns"
        >
          {mode !== "view" && (
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<SaveIcon />}
              disableRipple
              onClick={() => handleSubmit(false)}
            >
              Save
            </Button>
          )}

          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<UploadFile />}
            disableRipple
            onClick={() => handleSubmit(true)}
          >
            Export PDF
          </Button>
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
            <TextField
              label="Title"
              variant="outlined"
              required
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleError("");
              }}
              disabled={mode === "view"}
              sx={{
                borderColor: "#64bcd2",
                backgroundColor: "white",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#64bcd2" },
                },
              }}
            />
            {titleError && <div className="error-msg">{titleError}</div>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
            <TextField
              label="Description"
              variant="outlined"
              required
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setDescriptionError("");
              }}
              disabled={mode === "view"}
              sx={{
                borderColor: "#64bcd2",
                backgroundColor: "white",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#64bcd2" },
                },
              }}
            />
            {descriptionError && (
              <div className="error-msg">{descriptionError}</div>
            )}
          </Grid>
        </Grid>
      </Box>
      <TableContainer
        className="table-container"
        component={Paper}
        sx={{ maxWidth: "100%", overflowX: "auto", maxHeight: "440px" }}
      >
        <Table
          {...getTableProps()}
          stickyHeader
          className={mode === "view" ? "table-disabled" : ""}
        >
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, idx) => {
                  const isCommonHeading =
                    column.Header === "Identify the Person" ||
                    column.Header === "Describe the Case" ||
                    column.Header === "Classify the Case" ||
                    column.Header === "No of Days Injured" ||
                    column.Header === "Illness Type";
                  return (
                    <TableCell
                      {...column.getHeaderProps()}
                      style={{
                        width: column.width,
                        minWidth: column.minWidth,
                        borderRight: "1px solid #ccc",
                        backgroundColor: isCommonHeading ? "#64BCD2" : "#fff",
                        color: isCommonHeading ? "#fff" : "#000",
                        textAlign: "center",
                        padding: "8px",
                        whiteSpace: "normal", // Allow wrapping
                        overflow: "visible", // Ensure overflow is visible
                        textOverflow: "clip", // Ensure text is not clipped
                        verticalAlign: "top",
                        wordWrap: "break-word", // Break words if necessary
                      }}
                      key={idx + index}
                      colSpan={isCommonHeading ? column.columns.length : 1}
                    >
                      {column.render("Header")}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {isLoading ? (
              <>
                <TableRow
                  style={{ paddingTop: "12px !important", paddingBottom: 12 }}
                >
                  <TableCell
                    style={{ borderBottom: 0 }}
                    colSpan={10}
                    align="center"
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell, idx) => {
                        const isRadioButtonColumn =
                          [
                            "death",
                            "daysAwayFromWork",
                            "jobTransfer",
                            "otherRecordable",
                          ].includes(cell.column.id) ||
                          [
                            "injury",
                            "skinDisorder",
                            "respiratoryCondition",
                            "poisoning",
                            "hearingLoss",
                            "allOtherIllness",
                          ].includes(cell.column.id);

                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            key={idx}
                            style={{
                              borderRight: "1px solid #ccc",
                              padding: "8px",
                              textAlign: "center",
                              verticalAlign: "middle",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              backgroundColor: isRadioButtonColumn
                                ? "#e0e0e0"
                                : "transparent",
                            }}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && (
        <TablePagination
          style={{ backgroundColor: "white", position: "sticky", bottom: 0 }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRow}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default OSHAForm300Table;
